import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MinusIcon from "../../assests/images/minus.svg";
import GenderIcon from "../../assests/images/icon-gender.png";
import TrashIcon from "../../assests/images/trash-2.svg";
import BlankState from "./BlankState";
import PlusIcon from "../../assests/images/plus.svg";
import MoveIcon from "../../assests/images/move.svg";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

const MainContainer = styled.section`
  width: 38%;
  border: 1px solid #f0f0f0;
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};

  margin: 20px 0 0 0;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  /* -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3); */
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size:17px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.expand ? "70px" : "70px")};
  padding-left: 16px;
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const GenderDescContainer = styled.div`
    // border:1px solid red;
`;

const MiddleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  // border:1px solid red;

`;

const PercentageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // border:1px solid red;

`;

const PercentageContainerTitle = styled.div`
  font-size: 16px;
`;

const PercentageHolder = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const DetailsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const Icons = styled.div`
  width: 30%;
  padding-left: 30px;
`;

const DetailsDesc = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size:15px;
`;

const DescContainer = styled.div`
  width: 50%;
  display: flex;

  align-items: center;
  /* justify-content: space-between; */
`;

const YearTitle = styled.div`
  font-size: 15px;
`;

const DataDesc = styled.div`
  font-size: 32px;
  font-weight: bold;
  
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 150px;

  background: linear-gradient(
    rgba(254, 254, 254, 1) 15%,
    rgba(204, 204, 209, 1) 60%,
    rgba(254, 254, 254, 1) 85%
  );
`;

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
  font-size:17px;
`;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    padding: "none",
    margin: "none",
    marginTop: "2px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

function GeneralDisSummary(props) {
  const [blankState, setBlankState] = useState(false);
  const [expand, setExpand] = useState(true);
  const [xpos, setXpos] = useState(0);
  const [year, setYear] = React.useState('2022');

  const [generalDis_in_Local, setgeneralDisInlocal] = useState(false);
// const addToDashBoard=true;
const { addToDashBoard } = props;
const [final_AddToDashBoard,setfinal_AddToDashBoard]=useState(addToDashBoard);

  
    // const {addToDashBoard}=useState(props_addToDashBoard);
  // console.log("add to dashboard status is " + final_AddToDashBoard);

  const generalDis = localStorage.getItem("generalDis");

  const [getgender_distribution_summary, setgender_distribution_summary] =
    useState([]);
  const [getgender_distribution_year_wise, setgender_distribution_year_wise] =
    useState([]);

  useEffect((year) => {
    fetchData(year);
  }, []);
  const OrgId = localStorage.getItem("organization_id");
 //const OrgId=5184;
    
  const fetchData = (year) => {

    if(localStorage.getItem("generalDis")!==null){
      let bool=localStorage.getItem("generalDis")
      if(bool=="true"){
        setgeneralDisInlocal(true)
      }else{
        setgeneralDisInlocal(false)
      }
    }



    if (year == undefined) {
      year = "2022";
    } else {
      year = year;
    }

    axios
      .get(API_URL + `gender_distribution_summary?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}`)
      .then((response) => {
        setgender_distribution_summary(
          response.data.data[0].gender_distribution_summary
        );
        setgender_distribution_year_wise(
          response.data.data[1].gender_distribution_year_wise
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const classes = useStyles(props);
  const handleChange = (event) => {
    setYear(event.target.value);
    var options = event.target.value;

    axios
      .get(API_URL + `gender_distribution_summary?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${options}`)
      .then((response) => {
        setgender_distribution_summary(
          response.data.data[0].gender_distribution_summary
        );
        setgender_distribution_year_wise(
          response.data.data[1].gender_distribution_year_wise
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleExpand = (e) => setExpand(!expand);
  const handleBlankState = (e) => {
    setBlankState(true);
    localStorage.removeItem("generalDis");
    // localStorage.setItem("generalDis", false);
    setfinal_AddToDashBoard(true);
    
  };

  function handleDrag(e, data) {
    if (data.x < -100) {
      props.setDirection(false);
    } else if (data.x > 100) {
      props.setDirection(true);
    } else {
      setXpos(0);
    }
  }


  // const openSuccessToaster = () => {
  //   toast.success("Added dashboard successfully");
  // };
  function generalDisSum() {
   
    localStorage.setItem("generalDis", true);
    // window.location.href = "/chart";
    setfinal_AddToDashBoard(false);

    toast.success("Added dashlets to the dashboard successfully" ,{autoClose:1000});

    setTimeout(() => {
      if(localStorage.getItem("generalDis")!==null){
        let bool=localStorage.getItem("generalDis")
        if(bool=="true"){
          setgeneralDisInlocal(true);
          window.location.reload();
        }else{
          setgeneralDisInlocal(false)
        }
      }
    }, 1000);
   
  }

  return (
    <Draggable
      handle="#drag"
      axis="x"
      bounds="parent"
      onDrag={handleDrag}
      allowAnyClick={false}
      position={{ x: xpos, y: 0 }}
    >
      <MainContainer expand={expand} blankState={blankState}>
        {blankState ? (
          <BlankState />
        ) : (
          <>
            <HeadContainer expand={expand}>
              <Title>
                Gender Distribution Summary - Year
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.root}
                  style={{ marginLeft: "7px" }}
                >
                  <Select
                    value={year}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className={classes.root}
                    style={{width:'65px',fontSize:"17px" , color:" #675757"}}
                  >
                     <MenuItem value="2022">2022</MenuItem>
                    <MenuItem value="2021">2021</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2018">2018</MenuItem>
                  </Select>
                </FormControl>
              </Title>
              {final_AddToDashBoard ? (

generalDis_in_Local == true ? (
                  <>
                     {" "}
                    <AddToDashBoard > + Added</AddToDashBoard>
                  </>
                ) : (
                  <>
                  {" "}
                    <AddToDashBoard onClick={generalDisSum}>
                      + Add to Dashboard
                    </AddToDashBoard>
                  
                  </>
                )
              ) : (
                <ActionIconContainer>
                  <ActionIcon
                    src={expand ? MinusIcon : PlusIcon}
                    onClick={handleExpand}
                  />
                  <ActionIcon src={MoveIcon} id="drag" />
                  <ActionIcon src={TrashIcon} onClick={handleBlankState} />
                </ActionIconContainer>
              )}
            </HeadContainer>
            {expand && (
              <GenderDescContainer>
                <MiddleContainer>
                  {getgender_distribution_summary
                    ? getgender_distribution_summary.map((val) => (
                        <PercentageContainer>
                          <PercentageContainerTitle>
                            {val.name.toUpperCase()}
                          </PercentageContainerTitle>
                          {/* <PercentageHolder>{parseInt(val.val) }% </PercentageHolder> */}

                          <PercentageHolder>
                            {val.val ? parseInt(val.val) : 0}%{" "}
                          </PercentageHolder>
                        </PercentageContainer>
                      ))
                    : ""}
                </MiddleContainer>
                <DetailsContainer>
                  <Icons>
                    <img src={GenderIcon} alt="gendericon" />
                  </Icons>

                  {getgender_distribution_year_wise
                    ? getgender_distribution_year_wise.map(
                        (data, index, arr) => (
                          <DescContainer>
                            <DetailsDesc index={index}>
                              {data.name == 0}
                              {
                                <YearTitle>
                                  {" "}
                                  {arr.length - 1 == index
                                    ? "THIS YEAR (" + year + ")"
                                    : "LAST YEAR (" + parseInt(year - 1) + ")"}
                                </YearTitle>
                              }

                              {/* <YearTitle>{data.name}</YearTitle> */}
                              <DataDesc>
                                {data.val == null ? 0 : data.val}
                              </DataDesc>
                            </DetailsDesc>
                            {arr.length - 1 == index ? (
                              ""
                            ) : (
                              <VerticalLine></VerticalLine>
                            )}

                            {/* <VerticalLine></VerticalLine> */}
                            {/* 
                          <DetailsDesc index = {index}style={{
                           alignItems: "flex-end",
                         }}>
                         <YearTitle>{data.name}</YearTitle>
                         <DataDesc>{data.val}</DataDesc>
                       </DetailsDesc> */}

                            {/*                          
                         <DetailsDesc
                         style={{
                           alignItems: "flex-end",
                         }}
                       >
                         <YearTitle>{data.name}</YearTitle>
                         <DataDesc>{data.val}</DataDesc>
                       </DetailsDesc>
                       */}
                          </DescContainer>
                        )
                      )
                    : ""}
                </DetailsContainer>
              </GenderDescContainer>
            )}
          </>
        )}
      </MainContainer>
    </Draggable>
  );
}

export default GeneralDisSummary;
