import $ from 'jquery';
import React, { Component } from 'react';
import ReactToPrint from "react-to-print";
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { AppTopNav } from './Topnav';
// import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
// require('../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css');
import DataTable, { defaultThemes } from 'react-data-table-component';
import { can_add } from '../ReusableComponents/urlGenerator';
import '../global.js';
import { encryptStorage } from './LocalStorageInterceptor';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import HOC from '../HOC.js';
import Loader from './Loader.js';
import { showSuccessToast } from './toastService.js';


let order = 'desc';

var context;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderRightStyle: 'solid',
      borderRightWidth: '10px',
      borderRightColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        // borderStyle: 'solid',
        // borderWidth: '1px',
        // borderColor: defaultThemes.default.divider.default,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};

class ConsentContent extends Component {


  render() {
    var show_other_text = '';
    if (localStorage.getItem('selected_organization') === 'Resource Recovery Center') {
      show_other_text = localStorage.getItem('selected_organization') +
        " is a program of the Crisis Center, a United Way agency. " + localStorage.getItem('selected_organization') + " is a collaborative initiative dedicated to simplifying the substance abuse treatment process.";
    }

    return (


      <div>
        <div >
          <strong>Services and Fees</strong><br />
          <div>
            All services are free. {show_other_text} {localStorage.getItem('selected_organization')} aims to provide clients the resources needed to help pursue lasting recovery from mood-altering substances, as well as educate the greater community on substance use prevention, treatment, and recovery. Through appropriate assessments, {localStorage.getItem('selected_organization')} provides referrals based on a client’s individual needs, including factors such as insurance (or lack of insurance), gender, veteran status, place of residence, and drug of choice.
          </div><br />
          <strong>Client Rights and Confidentiality</strong><br />
          <div>
            The client retains all basic civil rights in the care of {localStorage.getItem('selected_organization')} employees. The client will not be discriminated against in the provision of services based on age, race, creed, sex, ethnicity, color, national origin, marital status, sexual orientation, gender identity or expression, physical or mental ability, culture, language ability, class, economic status, education or HIV status.
            <br /><br />
            All information shared in intake, assessment, and peer support sessions are strictly confidential and released only with your prior written permission. The exception is in situations when there is clear and immediate danger to you, other individuals, or society, at which time the intake coordinator, assessor or peer support specialist may access other helping professionals or emergency personnel. Clients under 18 have the same consideration for confidentiality except when parent/guardian exercises right to information. This agreement assumes that a confidential and anonymous professional consultation with a clinical expert is okay with you when needed, as is the custom within the helping and medical professions. Non-identifying information may be given to grant reporting entities that help fund {localStorage.getItem('selected_organization')}. Our services are offered in a setting in which staff, volunteers, clients, and other individuals may be present in the building.  This, too, may create some unavoidable compromise of your privacy. Please note that in the event we cannot contact clients, {localStorage.getItem('selected_organization')} staff may contact that client’s collateral or emergency contact. Signing this document gives permission for this to occur. Client files are kept for a period of three years, after which time all records are destroyed. The client does have a right to their information at any time during that period. The client retains the right to appeal a change, adjustment, or termination of services. Please see attached Policy for Privacy document for further information.
          </div><br />
          <strong>Appointments</strong><br />
          <div>
            {localStorage.getItem('selected_organization')} is a walk-in center; however, peer support specialists are available to meet with clients and can work with the client to find specific times to meet or talk if needed.
          </div><br />
          <strong>What to Expect</strong><br />
          <div>
            The process with {localStorage.getItem('selected_organization')} will involve meeting with several staff members to complete intake forms and assessments. Upon completion of these initial forms, the client will meet with the intake coordinator to begin the intake and suicide assessment. The client will then continue with a {localStorage.getItem('selected_organization')} staff member to complete the state assessment or be referred to community assessor, who will provide the assessment and recommendations. A community assessor is an individual from one of the community partners affiliated with the formation of {localStorage.getItem('selected_organization')} including Beacon Addiction Treatment Center, UAB’s Addiction Recovery Program, Fellowship House, and Northwest Alabama Treatment Center. The client will meet with a peer support specialist who will explain releases of information, as well as the referral process moving forward. Upon leaving {localStorage.getItem('selected_organization')}, the client will be contacted by the peer support specialist, who will also coordinate with the treatment centers, to ensure proper placement has been achieved. The client has a right to discontinue this contact at any time. Please note that in the event we cannot contact clients, {localStorage.getItem('selected_organization')} staff may contact that client’s collateral or emergency contact. Signing this document gives permission for this to occur.
          </div><br />
          <strong>Referrals</strong><br />
          <div>
            Based on intake, assessments, and staff recommendations, the client will be linked to various resources. These referrals may include treatment centers, recovery or therapy groups, counselors, and agencies.
          </div><br />
          <strong>Phone Contacts and Emergencies</strong><br />
          <div>
            You may reach a staff member of {localStorage.getItem('selected_organization')} by calling {localStorage.getItem('org_phone_number') != "null" ? localStorage.getItem('org_phone_number') : ''}.  Emergencies can be handled by calling the {localStorage.getItem('selected_organization')} at {localStorage.getItem('org_emergency_phone_number') != "null" ? localStorage.getItem('org_emergency_phone_number') : ''} or by going to your nearby emergency room.
          </div><br />
        </div>

        <br />




      </div>


    );
  }
}

class ConsentForm extends Component {
  render() {
    return (
      <div>
        <div className="card ">
          <div className="card-body row">
            <div className="col-md-12" style={{ marginBottom: '10px' }}>
              <div className="float-left"><input type="checkbox" id="checkboxPrimary3" disabled={true} style={{ marginRight: '5px' }}></input><strong>I (we) have read and understand the above statements.</strong><br /></div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="form-group">
                <label>Client Signature</label>
                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Client Signature" disabled={true} />
              </div>
            </div>

            <div className="col-lg-6 col-md-4">
              <div className="form-group">
                <label>Date:</label>

                <div className="input-group date">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>
                    </span>
                  </div>
                  <input type="text" placeholder="Date" className="form-control pull-right" id="datepicker" disabled={true} />
                </div>


              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="form-group">
                <label>Parent/Guardian signature (if necessary)</label><br />
                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Parent/Guardian signature (if necessary)" disabled={true} />
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="form-group">
                <label>Date:</label>

                <div className="input-group date">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>
                    </span>
                  </div>
                  <input type="text" placeholder="Date" className="form-control pull-right" id="datepicker" disabled={true} />
                </div>


              </div>
            </div>
            <div className="col-lg-6 col-md-8">
              <div className="form-group">
                <label>Staff Member of {localStorage.getItem('selected_organization')}</label><br />
                <input type="text" className="form-control" id="exampleInputEmail1" placeholder={`Staff Member of ` + localStorage.getItem('selected_organization')} disabled={true} />
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="form-group">
                <label>Date:</label>

                <div className="input-group date">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="far fa-calendar-alt"></i>
                    </span>
                  </div>
                  <input type="text" placeholder="Date" className="form-control pull-right" id="datepicker" disabled={true} />
                </div>


              </div>
            </div>

          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  I, <b>{encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}</b>, have received a copy of {localStorage.getItem('selected_organization')}&#39;s Notice of Privacy Practices.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>Client Signature</label><br />
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Client Signature" disabled={true} />
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="form-group">
                  <label>Date:</label>

                  <div className="input-group date">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt"></i>
                      </span>
                    </div>
                    <input type="text" placeholder="Date" className="form-control pull-right" id="datepicker" value="" disabled={true} />
                  </div>


                </div>
              </div>

              <div className="col-lg-4 col-md-4">
                <div className="form-group">
                  <label>Witness</label><br />
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Witness" disabled={true} />
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="form-group">
                  <label>Date:</label>

                  <div className="input-group date">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="far fa-calendar-alt"></i>
                      </span>
                    </div>
                    <input type="text" placeholder="Date" className="form-control pull-right" id="datepicker" disabled={true} />
                  </div>


                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

class ConsentToPrint extends Component {
  render() {
    return (
      <div>
        <div className="card-header">
          <p className="lead float-left"><b className="text-green">Consent to Services</b></p>
        </div>
        <div className="card-body" >
          <ConsentContent />
          <ConsentForm />
        </div>
      </div>
    )
  }
}

class ConsentToDisplay extends Component {

  constructor(props) {
    super(props);

  }

  render() {

    return (
      <div>
        <div className="card-header">
          <p className="lead float-left"><b className="text-green">Consent to Services</b></p>
          <div className="float-sm-right">

            <button type="submit" className="btn btn-primary float-right" onClick={this.props.onSaveHandler} value="proceed"><i className="fa fa-chevron-circle-right" aria-hidden="true" value="save" ></i>&nbsp;Proceed </button>
            <ReactToPrint
              trigger={() => <button type="button" className="btn btn-success float-right btnmargin"><i className="fa fa-print" aria-hidden="true"></i>&nbsp;Print</button>}
              content={() => this.componentRef}
            />
          </div>
          <div style={{ display: "none" }}> <ConsentToPrint ref={el => (this.componentRef = el)} /> </div>
        </div>

        <div className="card-body" >
          <div class="row">
            <div className="col-md-12" style={{ marginBottom: '10px' }}>
              <input className="" type="checkbox" checked={this.props.consent_received} name="consent_received" onClick={this.props.HandleConsentAcknowledgement} />
              <label className="form-check-label" style={{ marginLeft: '5px' }}> Consent received</label>
            </div>
          </div>
          <div className="consentscroll" >
            <ConsentContent />
          </div>
          <ConsentForm />
        </div>
      </div>
    )
  }
}

class Consent extends Component {
  constructor() {
    super();

    context = this;

    var now = new Date();
    this.state = {
      data: [],
      selectedFile: null,
      consent_received: false,
      consent_acknowledgement_updated: false,
      columns: [
        {
          name: 'Uploaded By',
          selector: (row) => row.userModificationName,
          sortable: true,
          style: {
          },

        },
        {
          name: 'Date',
          selector: (row) => row.upload_date,
          sortable: true,
          cell: (row) => (
            <div>
              {row.modificationDate.replace(/\//g, "-").slice(0, 10)}
            </div>
          )
        },
        {
          name: 'View',
          selector: (row) => row.consent_path,
          sortable: false,
          cell: (row) => (
            <div>
              <NavLink to={row.consent_path} target="_blank"><i id={row.consent_path} className="fas fa-arrow-right" aria-hidden="true" data-toggle="tooltip" title="view" ></i></NavLink>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ],
      showLoader: false
    };

    this.fileUpload = React.createRef();
  }

  HandleConsentAcknowledgement = (e) => {
    console.log(e.target.checked);

    localStorage.setItem('consent_received', e.target.checked);
    this.setState({ consent_received: e.target.checked, consent_acknowledgement_updated: true });
  }

  handleButtonView(event) {
    console.log(event.target);
  }

  handleChange = date => {

    console.log("Selected date is : " + date);

    this.setState({
      startDateintakedate: date
    });
  };
  handlemonth1followupdate = date => {

    console.log("Selected date is : " + date);

    this.setState({
      month1followupdate: date
    });
  };

  handleChangesixmonthfollow = date => {

    console.log("Selected date is : " + date);

    this.setState({
      sixmonthfollow: date
    });
  };
  handleChangedob = date => {

    console.log("Selected date is : " + date);

    this.setState({
      dob: date
    });
  };

  getConsentList() {
    var prevExist = false;
    localStorage.setItem('ObectID', '');

    this.HandleConsentAcknowledgement = this.HandleConsentAcknowledgement.bind(this);

    if (localStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + localStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ConsentAcknowledgement&q={"client_id":"' + localStorage.getItem('clientId') + '"}')
        .then(res => {

          const responce = res.data.data; // get the data array instead of object
          if (responce.length > 0 && (responce !== undefined || responce.id !== '')) {
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce.id + '?apikey=' + localStorage.getItem('__TOKEN__'))
              .then(res => {

                localStorage.setItem('consent_acknowledge_id', res.data.data.id);
                const responce = res.data.data.elements;

                console.log("Obj list : ", responce);
                $.each(responce, function (index, value) {
                  //console.log(value.name+"==="+value.value)
                  if (value.name === 'consent_received') {
                    localStorage.setItem('consent_received', value.value)


                  }
                });
                if (localStorage.getItem('consent_received') === "true") {
                  this.setState({ consent_received: true });
                } else {
                  this.setState({ consent_received: false });
                }


              });
          } else {
            localStorage.removeItem('consent_acknowledge_id',);
          }
        });

      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + localStorage.getItem('__TOKEN__') + '&limit=1&objectClass=ConsentDetails&q={"client_id":"' + localStorage.getItem('clientId') + '"}')
        .then(res => {

          const response = res.data; // get the data array instead of object

          if (response.success) {
            console.log("object fetcher : ", response);

            if (response.data.length !== 0) {
              // console.log("sal",JSON.stringify(response.data))
              prevExist = true;
              console.log("prevExist : ", prevExist);
              localStorage.setItem('ObectID', response.data.id);

              let getObjVersionBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                class_name: "ConsentDetails",
                obj_id: response.data.id
              };

              axios.post(global.restApiURL + 'customRest/getObjVersions', JSON.stringify(getObjVersionBody))
                .then(res => {

                  if (res.data.success) {
                    // const response = res.data.data.elements;
                    console.log("Obj data : ", res);

                    this.setState({
                      data: res.data.data
                    })

                    // this.overallResponseCalculator();    

                    // console.log("obj fet data : " , this.state.phq9Data)
                  }
                  else if ("error_code" in res) {
                    alert(res.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                  }
                  else {
                    alert(res.msg);
                  }


                })
                .catch(err => { // log request error and prevent access to undefined state
                  this.setState({ loading: false, error: true });
                  console.error(err);
                })
            } else {
              prevExist = false;
            }
          }
          else if ("error_code" in response) {
            alert(response.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(response.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }

  }

  componentDidMount() {
    this.getConsentList()
  }

  onChangeHandler = event => {

    let file_size = event.target.files[0].size;

    //or if you like to have name and type
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
    //do whatever operation you want to do here
    console.log("file size : ", file_size, " file type : ", file_type);

    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    })

    setTimeout(() => this.onClickHandler(), 500);
  }

  onSaveHandler = (event) => {

    let buttonType = event.target.value;

    let today = new Date();

    var date = today.getFullYear() + '-' + ((today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) + '-' + (today.getDate() < 10 ? '0' + today.getDate() : today.getDate());

    var jsonData = '';
    var saveConsentUrl = global.restApiURL + 'webservice/rest/object?apikey=' + localStorage.getItem('__TOKEN__');
    if (localStorage.getItem('consent_acknowledge_id')) {

      jsonData = { 'className': 'ConsentAcknowledgement', 'key': localStorage.getItem('clientId'), 'id': localStorage.getItem('consent_acknowledge_id'), 'published': true };
    } else {
      jsonData = { 'className': 'ConsentAcknowledgement', 'key': localStorage.getItem('clientId'), 'published': true };
    }

    jsonData.elements = [];

    let data = {};
    data['acknowledge_by'] = localStorage.getItem("loggedinUserID");
    data['acknowledge_date'] = date;
    data['consent_received'] = (localStorage.getItem('consent_received') === 'true') ? true : false;
    data['client_id'] = localStorage.getItem('clientId');
    for (var n in data) {
      jsonData.elements.push({ name: n, value: data[n] });
    }

    var json = JSON.stringify(jsonData);

    console.log(json);
    if (this.state.consent_acknowledgement_updated && buttonType === 'proceed') {
      axios.post(saveConsentUrl, json)
        .then(res => {
          // console.log(res) ;
          // alert(res.data.msg);
          let response = res.data;
          if (response.success) {
            localStorage.setItem('success_msg', "Consent acknowledged successfully.");
            if (buttonType === 'proceed') {
              const route = `${global.traversArray["consent"]}`;
              this.props.navigate(route);
              // window.location.href = global.domain + global.traversArray['consent'];
            }
            else {
              this.getConsentList()
            }
          }
          else if ("error_code" in response) {
            alert(response.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(response.msg);
          }
        });
    } else {
      const route = `${global.traversArray["consent"]}`;
      this.props.navigate(route);
      console.log("xxxxxx", route)
      // window.location.href = global.domain + global.traversArray['consent'];
    }
  }

  onClickHandler = () => {

    let buttonType = 'save';//event.target.value ;
    let today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    console.log("date : ", date);
    if (this.vlidateFileSelection()) {
      console.log("date : ", date);
      const data = new FormData()
      data.append('consent', this.state.selectedFile)
      data.append('uploaded_by', localStorage.getItem("loggedinUserID"))
      data.append('date', date)
      data.append('client_id', localStorage.getItem('clientId'))
      data.append('apikey', localStorage.getItem('__TOKEN__'))
      data.append('version', "1")
      this.setState({ showLoader: true });

      axios.post(global.restApiURL + "customRest/uploadConsent", data)
        .then(res => {
          // console.log(res) ;
          // alert(res.data.msg);
          let response = res.data;
          if (response.success) {
            localStorage.setItem('success_msg', "Consent uploaded successfully.");
            if (buttonType === 'proceed') {
              window.location.href = global.domain + global.traversArray['consent'];
            }
            else {
              this.getConsentList();
              this.setState({ showLoader: false });
              showSuccessToast("Consent uploaded successfully.");


            }
          }
          else if ("error_code" in response) {
            alert(response.msg);
            window.location.href = global.domain + global.traversArray['out'];
            this.setState({ showLoader: false });

          }
          else {
            alert(response.msg);
            this.setState({ showLoader: false });

          }
        })
    }

  }

  vlidateFileSelection() {
    if (this.state.selectedFile === null) {
      alert("Please select a file to upload !");
      return false;
    }
    else if (this.state.selectedFile.size > 20e6) {
      alert("File size too large !");
      return false;
    }
    else {
      return true;
    }
  }

  uploadImage = (e) => {
    let file = e.target.files[0];
    if (file && !file.name) {
      window.alert("Please select a file");
      return false;
    }
    if (file.size > 10e6) {
      window.alert("Please upload a file smaller than 10 MB");
      return false;
    }
  }

  Filevalidation = () => {
    const fi = document.getElementById('file');
    // Check if any file is selected. 
    if (fi.files.length > 0) {
      for (var i = 0; i <= fi.files.length - 1; i++) {

        const fsize = fi.files.item(i).size;
        const file = Math.round((fsize / 1024));
        // The size of the file. 
        if (file >= 4096) {
          alert(
            "File too Big, please select a file less than 4mb");
        } else if (file < 2048) {
          alert(
            "File too small, please select a file greater than 2mb");
        } else {
          document.getElementById('size').innerHTML = '<b>'
            + file + '</b> KB';
        }
      }
    }
  }

  render() {
    localStorage.setItem('formname', 'Consent')
    //console.log(this.state.consentdata.agency_name+"----");

    $(document).ready(function () {


      if (document.getElementById('upload')) {
        document.getElementById('upload').onclick = function () {
          document.getElementById('file_upload').click();
        }
      }

    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <AppTopNav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={localStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={localStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">History</h3>
                      <div className="float-sm-right">
                        {can_add('ConsentDetails') ? <a id="upload" className="btn btn-primary form-group btnmargin" >
                          <span className="uplaod">
                            <i className="fa fa-upload " aria-hidden="true" style={{ marginRight: '3px' }}></i>
                            Upload
                          </span>
                          <input type="file" hidden="" style={{ display: "none" }} id="file_upload" accept=".pdf,.png, .jpg, .jpeg" onChange={this.onChangeHandler} />
                        </a> : ""}
                      </div>
                    </div>
                    <div className="card-body">
                      <div id="jsGrid2"></div>
                      <DataTable
                        className='table_Data'
                        columns={this.state.columns}
                        data={this.state.data}
                        // onRowClicked={this.handleRowClicked}
                        customStyles={global.customTableStyles}
                        striped={true}
                        pagination={this.state.data ? true : false}
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                        paginationPerPage={5}
                      />
                    </div>
                  </div>
                  <div className="card">
                    <ConsentToDisplay consent_received={this.state.consent_received} HandleConsentAcknowledgement={this.HandleConsentAcknowledgement} onSaveHandler={this.onSaveHandler} />
                    <div className="card-footer float-right" >
                      <div className="float-right">
                        <button type="submit" className="btn btn-primary float-right" onClick={this.onSaveHandler} value="proceed"><i className="fa fa-chevron-circle-right" aria-hidden="true" value="save" ></i>&nbsp;Proceed </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {this.state.showLoader ? <Loader /> : ""}


            {/* </form> */}
          </div>
          <Footer />
        </div>
      </div>
    )

  }
}
export default HOC(Consent);
