
import {convertDate} from './dataFormater';
export function date_validate(d, n){

  var date_check = new Date(d)//moment(localStorage.getItem('intake_date')).format('MM/DD/YYYY');
  var check_90days=new Date(date_check.getFullYear(), date_check.getMonth(), date_check.getDate() + parseInt(n)) 
  var day = check_90days.getDay();
  var date_ = ""
  if (day === 6 || day === 0) {
    if (day === 6) {
      date_ = new Date(check_90days.getFullYear(), check_90days.getMonth(), check_90days.getDate() + 2) 
    }
    else if (day === 0) {
      date_ = new Date(check_90days.getFullYear(), check_90days.getMonth(), check_90days.getDate() + 1)

    }
  }
    else{
      date_ = new Date(check_90days.getFullYear(), check_90days.getMonth(), check_90days.getDate())

    }

  

  console.log('date__', date_, convertDate(date_, 'MM/DD/YYYY'))

  return convertDate(date_, 'MM/DD/YYYY') !== undefined ? convertDate(date_, 'MM/DD/YYYY') : '';
}