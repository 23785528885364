import axios from 'axios';
import React, { useEffect, useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactTooltip from "react-tooltip";

export default function AdultPlacementAssesment(props) {
  const [isSaveAndComplete,setisSaveAndComplete]=useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isRiskRatingMandatory, setisRiskRatingMandatory] = useState(false);
  const [adultAssesmentData, setadultAssesmentData] = useState({
    form_type: props.form_type,
    previous_effort_to_control_aod: "",
    previous_effort_to_cut_down_explain: "",
    spend_great_deal_of_time: "",
    spend_great_deal_of_time_explain: "",
    strong_desire_to_use_aod: "",
    strong_desire_to_use_aod_explain: "",
    neglected_important_social_obligations: "",
    neglected_important_social_obligations_explain: "",
    continue_to_use_despite_negative_consequences: "",
    continue_to_use_despite_negative_consequences_explain: "",
    affected_you_socially: "",
    affected_you_socially_explain: "",
    give_up_on_participate: "",
    give_up_on_participate_explain: "",
    placing_yourself_in_danger: "",
    placing_yourself_in_danger_explain: "",
    contribute_to_physical_problem: "",
    contribute_to_physical_problem_explain: "",
    feel_same_effect: "",
    feel_same_effect_explain: "",
    acute_risk_rating : "",
    acute_risk_rating_information: "",
    acute_comments: "",
    acute_risk_rating_summury:"",
  });
  // const[issummurymandatory,setissummurymandatory]=useState(false)
  const [isloading,setisloading]=useState(false)
  const acute_risk_rating0=`Fully functioning, no signs of intoxication or withdrawal present.`;
  const acute_risk_rating1=`Mild to moderate intoxication interferes with daily functioning, but does not pose a danger to self or others. Minimal risk of severe withdrawal.`;
  const acute_risk_rating2=`Intoxication may be severe but responds to support; not posing a danger to self or others. Moderate risk of severe withdrawal`;
  const acute_risk_rating3=`Severe signs & symptoms of intoxication indicates an imminent danger to self or others. Risk of severe but manageable withdrawal or withdrawal is worsening.`;
  const acute_risk_rating4=`Incapacitated, with severe signs and symptoms. Severe withdrawal presents danger, as of seizures. Continued use poses an imminent threat to life (e.g., liver failure, GI bleed, or fetal death).`;

  const MultiSelectOption = [
    { value: "agitated", label: "Agitated (fidget, pace, etc.)" },
    { value: "anxiety", label: "Anxiety" },
    { value: "diarrhea", label: "Diarrhea" },
    { value: "fatigue", label: "Fatigue" },
    { value: "feeling_sad", label: "Feeling sad, tense, or angry" },
    { value: "fever", label: "Fever" },
    { value: "hand_tremors", label: "Hand Tremors" },
    { value: "increased_appetite", label: "Increased appetite" },
    { value: "insomnia", label: "Insomnia or Hypersomnia" },
    { value: "memory_loss", label: "Memory Loss" },
    { value: "move_and_talk", label: "Move and talk slower than usual" },
    { value: "muscle_aches", label: "Muscle aches" },
    { value: "nausea", label: "Nausea/Vomiting" },
    { value: "runny_nose", label: "Runny nose/watery eyes" },
    { value: "see_feel", label: "See, feel, hear things that aren't there" },
    { value: "seizures", label: "Seizures" },
    { value: "sweating", label: "Sweating or heart racing" },
    { value: "vivid", label: "Vivid, unpleasant dreams" },
    { value: "yawning", label: "Yawning" },
  ];

  useEffect(() => { 
    loadData();
  }, []);

  useEffect(()=>{
  
    props.childToParent(adultAssesmentData);
  },[adultAssesmentData])
  // =====================================---LoadData----Data---start---==========================

  const loadData = async () => {
    // debugger
    setisloading(false)
    if (!props.isSaveAndComplete) 
    {
        let getFollowupListBody = {
          apikey: localStorage.getItem("__TOKEN__"),
          client_id: localStorage.getItem("clientId"),
          form_type: props.form_type,

        };
        let response = await axios.post(
          global.restApiURL + "customRest/getAcuteIntoxication",
          JSON.stringify(getFollowupListBody)
        );
        if (response.data.length != 0) {
          setadultAssesmentData(response.data.data.adultAssesmentData);
          props.childToParent(response.data.data.adultAssesmentData);
        }
    }else{
      let final_data = { dataListBody: dataListBody  }
      // let final_data = {  apikey: localStorage.getItem("__TOKEN__"),
      //                     client_id: localStorage.getItem("clientId"),
      //                     form_type: props.form_type,  
      //                   }
      let response = await axios.post(
        global.restApiURL + "customRest/getAcuteIntoxicationTemp",
        JSON.stringify(dataListBody)
      );
      if (response.data.length != 0) {
        setisSaveAndComplete(true)
        setadultAssesmentData(response.data.data.adultAssesmentData);
      }
    }
  };

  // =====================================---LoadData----Data---End---============================
  // ##############################################################################################


  const dataListBody = {
    apikey: localStorage.getItem("__TOKEN__"),
    client_id: localStorage.getItem("clientId"),
    form_type: props.form_type,
    uploaded_by:localStorage.getItem("username"),
  };

  const handleOnChange = (e) => {
    // debugger
    setIsSaved(false);
    props.saveAndExit("", handleSubmit, "", "", "", "", "", "", "", "", "", "", "");
    props.childToParent(adultAssesmentData);
    props.tabEditOrNot(true);
    setadultAssesmentData({ ...adultAssesmentData, [e.target.name]: e.target.value, });
    if (e.target.name == "previous_effort_to_control_aod" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["previous_effort_to_cut_down_explain"]: "", ["previous_effort_to_control_aod"]: e.target.value, });
    } else if (e.target.name == "spend_great_deal_of_time" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["spend_great_deal_of_time_explain"]: "", ["spend_great_deal_of_time"]: e.target.value, });
    } else if (e.target.name == "strong_desire_to_use_aod" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["strong_desire_to_use_aod_explain"]: "", ["strong_desire_to_use_aod"]: e.target.value, });
    } else if (e.target.name == "neglected_important_social_obligations" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["neglected_important_social_obligations_explain"]: "", ["neglected_important_social_obligations"]: e.target.value, });
    } else if (e.target.name == "continue_to_use_despite_negative_consequences" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["continue_to_use_despite_negative_consequences_explain"]: "", ["continue_to_use_despite_negative_consequences"]: e.target.value, });
    } else if (e.target.name == "affected_you_socially" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["affected_you_socially_explain"]: "", ["affected_you_socially"]: e.target.value, });
    } else if (e.target.name == "give_up_on_participate" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["give_up_on_participate_explain"]: "", ["give_up_on_participate"]: e.target.value, });
    } else if (e.target.name == "placing_yourself_in_danger" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["placing_yourself_in_danger_explain"]: "", ["placing_yourself_in_danger"]: e.target.value, });
    } else if (e.target.name == "contribute_to_physical_problem" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["contribute_to_physical_problem_explain"]: "", ["contribute_to_physical_problem"]: e.target.value, });
    } else if (e.target.name == "feel_same_effect" && e.target.value == "No") {
      setadultAssesmentData({ ...adultAssesmentData, ["feel_same_effect_explain"]: "", ["feel_same_effect"]: e.target.value, });
    } else if(e.target.name == "acute_risk_rating"){
      let val=e.target.value
      switch(val)
      {
        case "0" :  setadultAssesmentData({ ...adultAssesmentData, ["acute_risk_rating_information"]: acute_risk_rating0 , ["acute_risk_rating"]: "0" });
                  break;
        case "1" :  setadultAssesmentData({ ...adultAssesmentData, ["acute_risk_rating_information"]: acute_risk_rating1 , ["acute_risk_rating"]: "1" });
                  break;  
        case "2" :  setadultAssesmentData({ ...adultAssesmentData, ["acute_risk_rating_information"]: acute_risk_rating2 , ["acute_risk_rating"]: "2" });
                  break;         
        case "3" :  setadultAssesmentData({ ...adultAssesmentData, ["acute_risk_rating_information"]: acute_risk_rating3 , ["acute_risk_rating"]: "3"});
                  break;
        case "4" :  setadultAssesmentData({ ...adultAssesmentData, ["acute_risk_rating_information"]: acute_risk_rating4 , ["acute_risk_rating"]: "4"});
                  break;
      }
      props.riskRatingDecisionWithValue(e.target.value);
      if(e.target.value >= 2 && adultAssesmentData.acute_risk_rating_summury.length < 1){
        setisRiskRatingMandatory(true);
      }else{
        setisRiskRatingMandatory(false);
      }
    }else if(e.target.name == "acute_risk_rating_summury"){
      if(e.target.value.length < 1 && adultAssesmentData.acute_risk_rating >= 2){
        setisRiskRatingMandatory(true);
      }else{
        setisRiskRatingMandatory(false);
      }
      props.riskRatingDecisionWithComment(e.target.value);
      props.riskRatingDecisionWithValue(adultAssesmentData.acute_risk_rating);
    } 
    props.childToParent(adultAssesmentData);
  };

  const handleSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    setisloading(true);
    props.tabEditOrNot(false);
    if(adultAssesmentData.acute_risk_rating >= 2 && adultAssesmentData.acute_risk_rating_summury.length < 1){
      alert("Risk rating summary field is mandatory.");
    }else{
      let final_data = {
        adultAssesmentData,
        dataListBody,
      };
      let res = await axios.post(
        global.restApiURL + "customRest/saveAcuteIntoxication",
        JSON.stringify(final_data)
      );
      if (res.data.success == true && shouldToasterShow == undefined) {
        setIsSaved(true);
        loadData();
        toast.success('Data added successfully!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_adultPlacementAssesment',
          theme:'colored'
        });
      }else if(res.data.success == true && shouldToasterShow == 'saveNexit'){
        // msg should be show on parent componnt;
      } else{
        setisloading(false)
        toast.warning('Something going wrong... Please try again!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme:"colored"
        });
      }
    }
    props.tabEditOrNot(false);
  };

  return (
    <div className="hold-transition sidebar-mini text-sm">
      <div className="wrapper">
        <div className="">
          <section className="content-body">
            <div className="row">
              <div className="col-md-12">
                <form>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card shadow-none">
                        <div className="">
                          <p
                            className="lead float-left"
                            style={{ marginBottom: "1rem" }}
                          >
                            <b className="text-green">
                              Acute intoxication/withdrawal potential
                            </b>
                          </p>
                          <input type="hidden" id="clicked" value="" readOnly />
                          <input
                            type="hidden"
                            id="client_id"
                            name="client_id"
                            defaultValue={localStorage.getItem("clientId")}
                            disabled={isSaveAndComplete}
                          />
                        </div>
                        <div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Do you spend a great deal of time in activities
                                to obtain AOD and/or feeling its effects?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="spend_great_deal_of_time"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={adultAssesmentData.spend_great_deal_of_time == "Yes" ? true : false}
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={adultAssesmentData.spend_great_deal_of_time == "No" ? true : false}
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.spend_great_deal_of_time ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.spend_great_deal_of_time_explain?.length/47) + (adultAssesmentData.spend_great_deal_of_time_explain?.split(/\r\n|\r|\n/).length)}
                                      className="form-control"
                                      value={
                                        adultAssesmentData.spend_great_deal_of_time_explain
                                      }
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="spend_great_deal_of_time_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you had cravings or a strong desire to use
                                AOD?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="strong_desire_to_use_aod"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.strong_desire_to_use_aod == "Yes" ? true : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={adultAssesmentData.strong_desire_to_use_aod == "No" ? true : false}
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.strong_desire_to_use_aod ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      rows={1}
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.strong_desire_to_use_aod_explain?.length/47) + adultAssesmentData.strong_desire_to_use_aod_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={
                                        adultAssesmentData.strong_desire_to_use_aod_explain
                                      }
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="strong_desire_to_use_aod_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you used AOD to the point that you have
                                neglected important social obligations (work,
                                school, home)?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="neglected_important_social_obligations"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.neglected_important_social_obligations == "Yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={
                                    adultAssesmentData.neglected_important_social_obligations == "No"
                                      ? true
                                      : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.neglected_important_social_obligations ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.neglected_important_social_obligations_explain?.length/47) + adultAssesmentData.neglected_important_social_obligations_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={
                                        adultAssesmentData.neglected_important_social_obligations_explain
                                      }
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="neglected_important_social_obligations_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you continued to use despite the negative
                                consequences (at work, school, home)?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="continue_to_use_despite_negative_consequences"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.continue_to_use_despite_negative_consequences == "Yes" ? true : false
                                  }
                                >
                                  Yes
                                </option>

                                <option
                                  value="No"
                                  selected={
                                    adultAssesmentData.continue_to_use_despite_negative_consequences == "No" ? true : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.continue_to_use_despite_negative_consequences ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      rows={1}
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.continue_to_use_despite_negative_consequences_explain?.length/47) + adultAssesmentData.continue_to_use_despite_negative_consequences_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={
                                        adultAssesmentData.continue_to_use_despite_negative_consequences_explain
                                      }
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="continue_to_use_despite_negative_consequences_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you continued using AOD even though your
                                use affected you socially (fights, problems in
                                relationships, etc.)?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="affected_you_socially"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.affected_you_socially == "Yes" ? true : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={
                                    adultAssesmentData.affected_you_socially == "No" ? true : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>

                            {adultAssesmentData.affected_you_socially ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.affected_you_socially_explain?.length/47) + adultAssesmentData.affected_you_socially_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={adultAssesmentData.affected_you_socially_explain}
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="affected_you_socially_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Has your AOD use caused you to give up or not
                                participate in social, occupational or
                                recreational activities that you once enjoyed?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="give_up_on_participate"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.give_up_on_participate == "Yes" ? true : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={adultAssesmentData.give_up_on_participate == "No" ? true : false}
                                >
                                  No
                                </option>
                              </select>

                            </div>
                            {adultAssesmentData.give_up_on_participate ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.give_up_on_participate_explain?.length/47) + adultAssesmentData.give_up_on_participate_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={adultAssesmentData.give_up_on_participate_explain}
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="give_up_on_participate_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you continued to use despite placing
                                yourself and/or others in dangerous or unsafe
                                situations?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="placing_yourself_in_danger"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.placing_yourself_in_danger == "Yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={
                                    adultAssesmentData.placing_yourself_in_danger == "No"
                                      ? true
                                      : false
                                  }
                                >
                                  No
                                </option>
                              </select>

                            </div>
                            {adultAssesmentData.placing_yourself_in_danger ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.placing_yourself_in_danger_explain?.length/47) + adultAssesmentData.placing_yourself_in_danger_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={adultAssesmentData.placing_yourself_in_danger_explain}
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="placing_yourself_in_danger_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Have you continued to use AOD after knowing it
                                caused or contributed to physical and/or
                                psychological problems?
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="contribute_to_physical_problem"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={
                                    adultAssesmentData.contribute_to_physical_problem == "Yes"
                                      ? true
                                      : false
                                  }
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={
                                    adultAssesmentData.contribute_to_physical_problem == "No" ? true : false
                                  }
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.contribute_to_physical_problem ==
                              "Yes" && (
                                <>
                                  <div className="col-md-12 col-lg-6 mb-2">
                                    <TextareaAutosize
                                      style={{overflow:"hidden"}}
                                      minRows={(adultAssesmentData.contribute_to_physical_problem_explain?.length/47) + adultAssesmentData.contribute_to_physical_problem_explain?.split(/\r\n|\r|\n/).length}
                                      className="form-control"
                                      value={adultAssesmentData.contribute_to_physical_problem_explain}
                                      onChange={(e) => handleOnChange(e)}
                                      placeholder="Please explain"
                                      name="contribute_to_physical_problem_explain"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </>
                              )}
                          </div>
                          <div className="row marginBottom1">
                            <div className="col-md-8 col-lg-4">
                              <label>
                                Do you need more AOD to get the same high or
                                feel the same effect?{" "}
                              </label>
                            </div>
                            <div className="col-md-4 col-lg-2">
                              <select
                                className="form-control"
                                name="feel_same_effect"
                                onChange={(e) => handleOnChange(e)}
                                disabled={isSaveAndComplete}
                              >
                                <option value="">Select</option>
                                <option
                                  value="Yes"
                                  selected={adultAssesmentData.feel_same_effect == "Yes" ? true : false}
                                >
                                  Yes
                                </option>
                                <option
                                  value="No"
                                  selected={adultAssesmentData.feel_same_effect == "No" ? true : false}
                                >
                                  No
                                </option>
                              </select>
                            </div>
                            {adultAssesmentData.feel_same_effect == "Yes" && (
                              <>
                                <div className="col-md-12 col-lg-6 mb-2">
                                  <TextareaAutosize
                                    style={{overflow:"hidden"}}
                                    minRows={(adultAssesmentData.feel_same_effect_explain?.length/47) + adultAssesmentData.feel_same_effect_explain?.split(/\r\n|\r|\n/).length}
                                    className="form-control"
                                    value={adultAssesmentData.feel_same_effect_explain}
                                    onChange={(e) => handleOnChange(e)}
                                    placeholder="Please explain"
                                    name="feel_same_effect_explain"
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          <div className="col-md-12">
                              <div className="form-group">
                                <label>Notes or comments</label>
                                <TextareaAutosize
                                  style={{overflow:"hidden"}}
                                  className="form-control"
                                  minRows={(adultAssesmentData.acute_comments?.length/110) + adultAssesmentData.acute_comments?.split(/\r\n|\r|\n/).length}
                                  value={adultAssesmentData.acute_comments}
                                  name="acute_comments"
                                  onChange={(e) => {handleOnChange(e)}}
                                  disabled={isSaveAndComplete}
                                />
                              </div>
                          </div>

                          <div className="row marginBottom1">
                            <div className="col-md-12">
                              <div className="green_band">
                                <div className="row">
                                  <div className="col-md-4 col-lg-5">
                                    <p className="lead sa_risk">
                                    <b>Substance Use Risk Rating </b>
                                      <span className="acute_risk_rating0"></span>
                                    </p>
                                  </div>
                                  <div className="col-md-4 col-lg-5">
                                    <div className="form-group ml-0 pl-0">
                                      <input className="autoMargin" disabled={isSaveAndComplete} data-tip data-for="acute_risk_rating0" type="radio"  name="acute_risk_rating"  value="0" onChange={(e) => handleOnChange(e)} checked={adultAssesmentData.acute_risk_rating=="0"?true:false} /> &nbsp;
                                      <label for="acute_risk_rating0" className="labelMargin"> 0 </label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="acute_risk_rating1" name="acute_risk_rating" value="1" onChange={(e) => handleOnChange(e)} checked={adultAssesmentData.acute_risk_rating==1?true:false} /> &nbsp;
                                      <label for="acute_risk_rating1" className="labelMargin"> 1 </label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="acute_risk_rating2" name="acute_risk_rating"  value="2" onChange={(e) => handleOnChange(e)} checked={adultAssesmentData.acute_risk_rating==2?true:false}/>{" "} &nbsp;
                                      <label for="acute_risk_rating2" className="labelMargin"> 2 </label>
                                      <input className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="acute_risk_rating3"  name="acute_risk_rating" value="3" onChange={(e) => handleOnChange(e)} checked={adultAssesmentData.acute_risk_rating==3?true:false}/> &nbsp;
                                      <label for="acute_risk_rating3" className="labelMargin"> 3 </label>
                                      <input  className="autoMargin" disabled={isSaveAndComplete} type="radio" data-tip data-for="acute_risk_rating4" name="acute_risk_rating"  value="4" onChange={(e) => handleOnChange(e)} checked={adultAssesmentData.acute_risk_rating  == 4 ? true:false} /> &nbsp;
                                      <label for="acute_risk_rating4" className="labelMargin">  4 </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <ReactTooltip id="acute_risk_rating0" place="bottom" effect="solid" className="toolTipLabel0">{acute_risk_rating0}</ReactTooltip>
                          <ReactTooltip id="acute_risk_rating1" place="bottom" effect="solid" className="toolTipLabel1">{acute_risk_rating1}</ReactTooltip>
                          <ReactTooltip id="acute_risk_rating2" place="bottom" effect="solid" className="toolTipLabel2">{acute_risk_rating2}</ReactTooltip>
                          <ReactTooltip id="acute_risk_rating3" place="bottom" effect="solid" className="toolTipLabel3" >{acute_risk_rating3}</ReactTooltip>
                          <ReactTooltip id="acute_risk_rating4" place="bottom" effect="solid" className="toolTipLabel4" >{acute_risk_rating4}</ReactTooltip>
                          <div className="row">
                            <div className="col-md-12">
                              Diagnostic summary for this dimension
                            </div>
                           <hr/>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>Analysis</label>
                                <TextareaAutosize
                                  minRows={(adultAssesmentData.acute_risk_rating == 0 || adultAssesmentData.acute_risk_rating == "") ? 1 : 2}
                                  className="form-control"
                                  value={adultAssesmentData.acute_risk_rating_information}
                                  name="acute_risk_rating_information"
                                  readOnly
                                  style={{ backgroundColor: "#FEE5A1", overflow:"hidden" }}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className='form-group'>
                                <label>Risk Rating Summary</label>
                                <TextareaAutosize 
                                  className="form-control" 
                                  style={{overflow:"hidden"}} 
                                  minRows={(adultAssesmentData.acute_risk_rating_summury?.length/110) + adultAssesmentData.acute_risk_rating_summury?.split(/\r\n|\r|\n/).length}  
                                  disabled={isSaveAndComplete} 
                                  value={adultAssesmentData.acute_risk_rating_summury} 
                                  name="acute_risk_rating_summury"   
                                  onChange={(e) => handleOnChange(e)}
                                />
                                {isRiskRatingMandatory ? <> <span style={{color:"red"}}>This field is mandatory.*</span></> : ""}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <button
                              disabled={isSaved}
                                type="submit"
                                data-save="save"
                                value="save"
                                className="btn btn-success float-right btnmargin"
                                onClick={handleSubmit}
                              >
                                {" "}
                                <i className="fas fa-save"></i> Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
