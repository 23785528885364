import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MinusIcon from "../../assests/images/minus.svg";
import EmptyStateIcon from "../../assests/images/empty-fullrow.png";
import TrashIcon from "../../assests/images/trash-2.svg";
import PlusIcon from "../../assests/images/plus.svg";
import MoveIcon from "../../assests/images/move.svg";

import GDByRace from "./GDByRace";

import GdByRacePieChart from "./GdByRacePieChart";
import BlankState from "./BlankState";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import { toast } from "react-toastify";
// import OrgId from "./ReuseableComponents/ResubaleComponents";

const ThirdContainer = styled.section`
  display: flex;

  justify-content: space-between;
  width: 100%;
//  border: 1px solid black; 
`;

const MainContainer = styled.div`
  width: 97.5%;
  margin: 0 auto;
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};

  border: 1px solid #f0f0f0;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);


`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size:16px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.expand ? "fit-content" : "70px")};

  padding-left: 16px;
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
`;

const MainView = styled.div``;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    marginTop: "2px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

function GeneralDisByRace(props) {

  const { addToDashBoard } = props;
  const [final_AddToDashBoard, setfinal_AddToDashBoard] = useState(addToDashBoard);
  const [getGenDisByRaceInlocal, setGenDisByRaceInlocal] = useState(false);


  const [blankState, setBlankState] = React.useState(false);
  const [expand, setExpand] = React.useState(true);

  const [year, setYear] = React.useState('2022');

  const [getGdByRacePieChart, setGdByRacePieChart] = useState([]);
  const [getChart, setChart] = useState([]);

  const [getCategories, setCategories] = useState([]);
  const [getGdByRacedata, setgetGdByRacedata] = useState([]);
   const OrgId = localStorage.getItem("organization_id");
  // const OrgId=5030;
  useEffect((year) => {
    fetchgetGdByRacePieChart(year);
    if(localStorage.getItem("GenDisByRace")!==null){
      let bool=localStorage.getItem("GenDisByRace")
      if(bool=="true"){
        setGenDisByRaceInlocal(true)
      }else{
        setGenDisByRaceInlocal(false)
      }
    }
    
  }, []);

  const fetchgetGdByRacePieChart = (year) => {
	  

    const GenDisByRace_Local = localStorage.getItem("GenDisByRace");
    setGenDisByRaceInlocal(GenDisByRace_Local);

    if (year == undefined) {
      year = "2022";
    } else {
      year = year;
    }
    axios
        .get(API_URL + `gender_distribution_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}`)
      //.get(`https://chipssapi.tactionsoft.com/api/gender_distribution_by_race?OrgId=${OrgId}&year=${year}`)
      .then((response) => {
        setGdByRacePieChart(response.data.data.gd_by_race_pie_chart);
        setChart(response.data.data.pie_data);
        // localStorage.setItem("GenDisByRace", true);
        setCategories(response.data.data.gd_by_race_pie_chart);
        setgetGdByRacedata(response.data.data.gd_by_race);
        // console.log("getChart data");
        // console.log(getChart);
      })
      .catch((error) => {
        console.log(error);
      });
  };

 

  const classes = useStyles(props);


  const handleChange = (event) => {
   

    setYear(event.target.value);
    var options = event.target.value;
    axios
    //.get(`https://chipssapi.tactionsoft.com/api/gender_distribution_by_race?OrgId=${OrgId}&year=${event.target.value}`)
     .get(API_URL + `gender_distribution_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${event.target.value}`)
    .then((response) => {
      setGdByRacePieChart(response.data.data.gd_by_race_pie_chart);
      setChart(response.data.data.pie_data);
      // localStorage.setItem("GenDisByRace", true);
      setCategories(response.data.data.gd_by_race_pie_chart);
      setgetGdByRacedata(response.data.data.gd_by_race);
      // console.log("getChart data");
      // console.log(getChart);
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleBlankState = (e) => {
    setBlankState(true);
    localStorage.removeItem("GenDisByRace");
    setfinal_AddToDashBoard(true);
  };
  const handleExpand = (e) => setExpand(!expand);

  function GenDisByRace() {
    // setfinal_AddToDashBoard(false);
    // // localStorage.setItem("GenDisByRace", true);
    // // window.location.href = "/chart";
    // toast.success("Added dashlets to the dashboard successfully" ,{autoClose:1000});

    localStorage.setItem("GenDisByRace", true);
    // window.location.href = "/chart";
    toast.success("Added dashlets to the dashboard successfully",{autoClose:1000});
    setfinal_AddToDashBoard(false);
   
    setTimeout(() => {
      if(localStorage.getItem("GenDisByRace")!==null){
        let bool=localStorage.getItem("GenDisByRace")
        if(bool=="true"){
          setGenDisByRaceInlocal(true);
          window.location.reload()
        }else{
          setGenDisByRaceInlocal(false)
        }
      }
    }, 1000);

  }

//   console.log("Hello Ashraf: ");
// console.log(final_AddToDashBoard);
const catArray=[];
if(getCategories.length>0)
{
  for(let i=0;i<getCategories.length;i++)
  {
    catArray.push(getCategories[i].country);
    // console.log(getCategories[i].country);
  }
}

  return (
    <MainContainer expand={expand} blankState={blankState}>
      {blankState ? (
        <BlankState emptyIcon={EmptyStateIcon} />
      ) : (
        <MainView style={{paddingLeft:'30px'}}>
          <HeadContainer>
            <Title>
            Gender Distribution by Race - Year
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                className={classes.root}
                style={{ marginLeft: "5px" }}
              >
                <Select
                  value={year}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.root}
                  style={{  color:" #675757" }}
                >
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                </Select>
              </FormControl>
            </Title>
            {final_AddToDashBoard ? (
              getGenDisByRaceInlocal == true ? (
                <>
                   {" "}
                  <AddToDashBoard > + Added</AddToDashBoard>
                </>
              ) : (
                <>
                {" "}
                  <AddToDashBoard onClick={GenDisByRace}>
                    + Add to Dashboard
                  </AddToDashBoard>
                
                </>
              )
            ) : (
              <ActionIconContainer>
                <ActionIcon
                  src={expand ? MinusIcon : PlusIcon}
                  onClick={handleExpand}
                />
                <ActionIcon src={MoveIcon} />
                <ActionIcon src={TrashIcon} onClick={handleBlankState} />
              </ActionIconContainer>
            )}
          </HeadContainer>
          {expand && (
            <ThirdContainer>
              <GDByRace 
                categories={catArray}
                seriesBar={getGdByRacedata}
              />
              <GdByRacePieChart
                gdPiaChart={getGdByRacePieChart}
                gdchart={getChart}
              />
            </ThirdContainer>
          )}
        </MainView>
      )}
    </MainContainer>
  );
}

export default GeneralDisByRace;
