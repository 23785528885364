import axios from 'axios';
import React, { Component } from 'react'
import TextareaAutosize from 'react-textarea-autosize';

export default class Printasias extends Component {
    constructor(props) {
        super(props);
        this.state = {
            asiasData: {
                asais_id: "",
                status: "",
                center_case_no: "",
                provider_person: "",
                date_screen: "",
                entered_by: "",
                last_assess_date: "",
                intake_date: "",//Clent start date
                request_type: "",
                discharge_date: "",
                last_name: "",
                first_name: "",
                middle_initial: "",
                dob: "",
                social_security: "",
                address_lns: "",
                city_lns: "",
                state_lns: "",
                zipcode_lns: "",
                county_lns: "",
                phone_primary: "",
                gender: "",
                race: [],
                ethnicity: "",
                uncope_score: "",
                craftQuestions: [
                    {
                        question:
                            "1. Have you ever ridden in a car driven by someone (including yourself) who was high or had been using alcohol or drugs?",
                        answer: "",
                    },
                    {
                        question:
                            "2. Do you ever use alcohol or drugs to relax, feel better about yourself, or fit in?",
                        answer: "",
                    },
                    {
                        question:
                            "3. Do you ever use alcohol or drugs while you are by yourself or alone?",
                        answer: "",
                    },
                    {
                        question:
                            "4. Do you ever forget things you did while using alcohol or drugs?",
                        answer: "",
                    },
                    {
                        question:
                            "5. Do your family or friends ever tell you that you should cut down on your drinking or drug use? ",
                        answer: "",
                    },
                    {
                        question:
                            "6. Have you ever gotten into trouble while you were using alcohol or drugs?",
                        answer: "",
                    },
                ],
            },
            dataListBody: {
                apikey: localStorage.getItem("__TOKEN__"),
                client_id: localStorage.getItem("clientId"),
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        let res = await axios.post(global.restApiURL + "customRest/getASISTempPdf", JSON.stringify(this.state.dataListBody))
        let asiasData = this.state.asiasData;
        asiasData.asais_id = res.data.data.asais_id;
        asiasData.status = res.data.data.status;
        asiasData.center_case_no = res.data.data.center_case_no;
        asiasData.provider_person = res.data.data.provider_person;
        asiasData.date_screen = res.data.data.date_screen;
        asiasData.entered_by = res.data.data.entered_by;
        asiasData.last_assess_date = res.data.data.last_assess_date;
        asiasData.intake_date = res.data.data.intake_date;
        asiasData.request_type = res.data.data.request_type;
        asiasData.discharge_date = res.data.data.discharge_date;
        asiasData.last_name = res.data.data.last_name;
        asiasData.first_name = res.data.data.first_name;
        asiasData.middle_initial = res.data.data.middle_initial;
        asiasData.dob = res.data.data.dob;
        asiasData.social_security = res.data.data.social_security;
        asiasData.address_lns = res.data.data.address_lns;
        asiasData.city_lns = res.data.data.city_lns;
        asiasData.state_lns = res.data.data.state_lns;
        asiasData.zipcode_lns = res.data.data.zipcode_lns;
        asiasData.county_lns = res.data.data.county_lns;
        asiasData.phone_primary = res.data.data.phone_primary;
        asiasData.gender = res.data.data.gender;
        asiasData.race = res.data.data.race.length > 0 ? JSON.parse(res.data.data.race).map((value) => { return ({ value: value.value, label: value.label }) }) : [];
        asiasData.ethnicity = res.data.data.ethnicity;
        asiasData.uncope_score = res.data.data.uncope_score;
        asiasData.craftQuestions = res.data.data.questions.length > 0 ? JSON.parse(res.data.data.questions).map((val) => {
            return ({
                question: val.question,
                answer: val.answer,
            })
        }) : [
            {
                question:
                    "1. Have you ever ridden in a car driven by someone (including yourself) who was high or had been using alcohol or drugs?",
                answer: "",
            },
            {
                question:
                    "2. Do you ever use alcohol or drugs to relax, feel better about yourself, or fit in?",
                answer: "",
            },
            {
                question:
                    "3. Do you ever use alcohol or drugs while you are by yourself or alone?",
                answer: "",
            },
            {
                question:
                    "4. Do you ever forget things you did while using alcohol or drugs?",
                answer: "",
            },
            {
                question:
                    "5. Do your family or friends ever tell you that you should cut down on your drinking or drug use? ",
                answer: "",
            },
            {
                question:
                    "6. Have you ever gotten into trouble while you were using alcohol or drugs?",
                answer: "",
            },
        ]
            ;

        this.setState({ asiasData: asiasData })
    }




    render() {
        return (
            <>
                
                <div style={{ padding: "0px 30px" }} >
                    <div className="row mx-auto" >
                        <div className="col-12" style={{ paddingBottom: "40px" }}>
                            <h2 className="text-green text-center">ASAIS DATA</h2>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-12" >
                            <div>
                                <>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>ASAIS ID:</label>
                                                <input type="text" disabled name="asias_id" value={this.state.asiasData.asais_id} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Status:</label>
                                                <input type="text" disabled name="status" value={this.state.asiasData.status} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Center Case No:</label>
                                                <input type="text" disabled name="center_case_no" value={this.state.asiasData.center_case_no} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Provider:</label>
                                                <input type="text" disabled name="provider_person" value={this.state.asiasData.provider_person} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Date of Screen:</label>
                                                <input type="text" disabled name="date_screen" value={this.state.asiasData.date_screen} className="form-control" />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Entered By: </label>
                                                <input type="text" disabled name="entered_by" value={this.state.asiasData.entered_by} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Date of Assessment:</label>
                                                <input type="text" disabled id="intake_date" name="last_assess_date " className="form-control" value={this.state.asiasData.last_assess_date} />

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Client Start Date:</label>
                                                <input type="text" disabled name="intake_date" value={this.state.asiasData.intake_date} className="form-control" />

                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Request Type:</label>
                                                <input type="text" disabled name="request_type" className="form-control" value={this.state.asiasData.request_type} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Client Discharge Date:</label>
                                                <input type="text" disabled name="discharge_date" className="form-control" value={this.state.asiasData.discharge_date} />
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Last Name:</label>
                                                <input type="text" name="last_name" disabled className="form-control" value={this.state.asiasData.last_name} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>First Name:</label>
                                                <input type="text" name="first_name" disabled className="form-control" value={this.state.asiasData.first_name} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Middle Name:</label>
                                                <input type="text" name="middle_initial" disabled className="form-control" value={this.state.asiasData.middle_initial} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>DOB:</label>
                                                <input type="text" name="dob" disabled className="form-control" value={this.state.asiasData.dob} />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label>Social Security No.:</label>
                                                <input type="text" name="social_security" disabled className="form-control" value={this.state.asiasData.social_security} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                            <div className="marginBottom1">
                                <div className="row">
                                    <div className="col-12">
                                        Street Address:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    <div className="form-control heightRemove PdfBackgroundColor"  name="address_lns" style={{backgroundColor:"#e9ecef"}}>{this.state.asiasData.address_lns}</div>
                                        {/* <textarea rows={2} cols={9} name="address_lns" className="form-control" disabled value={this.state.asiasData.address_lns} /> */}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>City:</label>
                                            <input type="text" name="city_lns" disabled className="form-control" value={this.state.asiasData.city_lns} />
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>State:</label>
                                            <input type="text" name="state_lns" disabled className="form-control" value={this.state.asiasData.state_lns} />
                                        </div>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-group">
                                            <label>Zipcode:</label>
                                            <input type="text" name="zipcode_lns" disabled className="form-control" value={this.state.asiasData.zipcode_lns} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>County:</label>
                                            <input type="text" name="county_lns" disabled className="form-control" value={this.state.asiasData.county_lns} />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Phone:</label>
                                            <input type="text" name="phone_primary" disabled className="form-control" value={this.state.asiasData.phone_primary} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Gender:</label>
                                            <input type="text" name="gender" disabled value={this.state.asiasData.gender} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Race </label>
                                            <div className="form-control heightRemove PdfBackgroundColor" name="race" style={{ backgroundColor: "#e9ecef" }}>{this.state.asiasData.race.map((label) => label.label).join(", ")}</div>
                                            {/* <TextareaAutosize rows={3} name="race" className="form-control" disabled value={this.state.asiasData.race.map((label) => label.label).join(", ")}
                                                style={{ overflow: "hidden" }}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Ethnicity:</label>
                                            <select className="form-control" name="ethnicity" value={this.state.asiasData.ethnicity} disabled >
                                                <option value=""></option>
                                                <option value="cuban">Cuban</option>
                                                <option value="hispanic_specific_Origin_not_specified">Hispanic-Specific Origin not specified</option>
                                                <option value="mexican">Mexican</option>
                                                <option value="not_of_hispanic_orgin">Not of Hispanic Origin</option>
                                                <option value="other_specific_hispanic">Other Specific Hispanic</option>
                                                <option value="puerto_rican">Puerto Rican</option>
                                                <option value="unknown">Unknown</option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-12">
                                    {/* {form_type=="uncopeScreening"?"UNCOPE Screening":""} */}
                                    {this.state.asiasData.form_type == "uncopeScreening" ? (
                                        <strong style={{ fontSize: "1rem" }}>
                                            UNCOPE - Adult
                                        </strong>
                                    ) : (
                                        <strong style={{ fontSize: "1rem" }}>
                                            CRAFFT - Adolescent
                                        </strong>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div className="marginBottom1">
                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-bordered table-striped table-center">
                                            <thead>
                                                <th></th>
                                                <th>Yes</th>
                                                <th>No</th>
                                            </thead>
                                            <tbody>
                                                {this.state.asiasData.form_type == "uncopeScreening"
                                                    ? this.state.asiasData.uncopeQuestions.map(
                                                        (question, index) => (
                                                            <tr>
                                                                <td>{question.question}</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        {question.answer == "yes" ? <>

                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={"checkbox" + index}
                                                                                value="yes"
                                                                                checked="true"
                                                                                disabled
                                                                            />
                                                                        </> : <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={"checkbox" + index}
                                                                                value="yes"
                                                                                disabled
                                                                            />
                                                                        </>}

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        {question.answer == "no" ? <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="no"
                                                                                name={"checkbox" + index}
                                                                                checked="true"
                                                                            />
                                                                        </> : <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="no"
                                                                                name={"checkbox" + index}
                                                                            /></>}

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )
                                                    : this.state.asiasData.craftQuestions.map(
                                                        (question, index) => (
                                                            <tr>
                                                                <td>{question.question}</td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        {question.answer == "yes" ? <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={"checkbox" + index}
                                                                                value="yes"
                                                                                checked="true"
                                                                                disabled
                                                                            />
                                                                        </> : <>

                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={"checkbox" + index}
                                                                                value="yes"
                                                                                disabled
                                                                            />
                                                                        </>}

                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="form-check">
                                                                        {question.answer == "no" ? <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="no"
                                                                                name={"checkbox" + index}
                                                                                checked="true"
                                                                                disabled

                                                                            />
                                                                        </> : <>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                value="no"
                                                                                name={"checkbox" + index}
                                                                                disabled
                                                                            />
                                                                        </>}

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                <tr>
                                                    <td>
                                                        <strong className="float-right"> CRAFFT Score</strong>
                                                    </td>
                                                    <td colSpan={2}>{this.state.asiasData.uncope_score}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div
                                    className="col-md-12"
                                    style={{
                                        border: "2px solid lightgray",
                                        background: "rgb(242, 242, 242)",
                                        fontSize: "12px",
                                        marginTop: "2rem"
                                    }}
                                >
                                    <label>
                                        <b>Source:</b> Knight JR; Shrier LA;
                                        Bravender TD; Farrell M; Vander Bilt J;
                                        Shaffer HJ (1999) A new brief screen for
                                        adolescent substance use. Archives of
                                        Pediatrics and Adolescent Medicine Jun;
                                        153(6). 591-6.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

