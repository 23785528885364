import React from 'react';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export function Alert(type, message){
    switch (type) {
        case 'warning':
            return toast.warning(<div> {message}</div>, {toastId : "warning" , theme:'colored',icon:false} )
        case 'error':
            return toast.error(<div>  {message}</div>  , {toastId : "error" , theme:'colored',icon:false}) 
        case 'success':
            return toast.success(<div ><i class="fa fa-check" aria-hidden="true"></i>  {message}</div>, {toastId : "success" , theme:'colored' , icon:false})
        case 'info':
            return toast.info(message , { theme:'colored'})
        case 'dark':
            return toast.dark(message, { theme:'colored'})
        default:
            return toast(message, { theme:'colored'})
    }
}