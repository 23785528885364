import $ from 'jquery';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { LabelField } from '../ReusableComponents/LabelnInput';
import { convertDate, getFormattedDate } from '../ReusableComponents/dataFormater';
import { formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { validationParser } from '../ReusableComponents/validationParser2';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import Topnav, { AppTopNav } from './Topnav';
import { toast } from "react-toastify";
import Select from 'react-select';

import ReactToPrint from "react-to-print";
import { multiSelectOptionGenerator, selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports

import moment from 'moment-timezone';
import DataTable, { defaultThemes } from 'react-data-table-component';
import DateTime from 'react-datetime';
import ReactDropzone from 'react-dropzone';
import ReactTooltip from "react-tooltip";
import '../../node_modules/react-datetime/css/react-datetime.css';
import { can_add, can_edit } from '../ReusableComponents/urlGenerator';
import '../global.js';
import { encryptStorage } from './LocalStorageInterceptor';
import { Button } from './Modals/Buttons/index';
import Modal from './Modals/Modal/index';
import HOC from '../HOC.js';
import { BottomAlert } from './toastify.js';
import { showSuccessToast , showErrorToast} from './toastService.js';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';



var validationObj = {};
var context;


const axios = require('axios').default;

const customStyles = {
    table: {
        style: {
            color: defaultThemes.default.text.primary,
            backgroundColor: defaultThemes.default.background.default,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#F9F9F9"
        },
    },
    header: {
        style: {
            minHeight: '1px',
        },
    },
    headRow: {
        style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: defaultThemes.default.divider.default,
            backgroundColor: "#F9F9F9",
            minHeight: "40px"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                fontWeight: "bold",
                fontSize: "0.91rem"
            },
        },
    },
    row: {
        style: {
            minHeight: '20px',
            borderRightStyle: 'solid',
            borderRightWidth: '10px',
            borderRightColor: defaultThemes.default.divider.default,
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                fontSize: '0.9rem'
            },
        },
    },
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "green"
                    : isFocused
                        ? "#0074F0"
                        : null,
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? "white"
                    : isSelected
                        ? "white"
                        : "black",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
            },
        };
    },
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: "#007BFF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ':hover': {
            backgroundColor: "red",
            color: 'white',
        },
    }),
};

class VerbalConsent extends Component {

    constructor() {
        super();
        context = this;

        this.state = {
            ...this.getModal("HospitalROIData"),
            HospitalROIData: this.getModal("HospitalROIData"),
            mandatoryInfo: this.getModal("HospitalROIData"),
            labelText: this.getModal("HospitalROIData"),
            tooltips: this.getModal("HospitalROIData"),
            tooltipsVisibility: this.getModal("HospitalROIData"),
            fieldName: this.getModal("HospitalROIData"),
            fieldType: this.getModal("HospitalROIData"),

            HospitalROIHistoryColumns: this.getModal("HospitalROIHistoryColumns"),

            //Modals
            showAddHospitalROIModal: false,
            showHospitalROIDetailsModal: false,

            //currentView
            currentView: 'Add',
            all_uploaded_documents: [],
            DocumentsColumns: this.getModal("DocumentsColumns"),
            print: false,
            historyID: 0,
            min_dob: 14,

            //Authorization Forms Mapping Class
            HospitalROI_forms_mappings: {},

            // for testing purpose
            abc : 'xyz',
            // disable button on save
            disable:false,
        };


        this.handleHospitalROIelectChange = this.handleHospitalROIelectChange.bind(this);
    }



    getModal(type) {
        let dataModel = '';
        var now = new Date();
        let client_name = '';
        if (encryptStorage.getItem('client_first_name') && encryptStorage.getItem('client_first_name') !== '' && encryptStorage.getItem('client_first_name') !== null && encryptStorage.getItem('client_first_name') !== undefined) {
            client_name = client_name + encryptStorage.getItem('client_first_name') + ' ';
        }
        if (encryptStorage.getItem('client_middle_name') && encryptStorage.getItem('client_middle_name') !== '' && encryptStorage.getItem('client_middle_name') !== null && encryptStorage.getItem('client_middle_name') !== undefined) {
            client_name = client_name + encryptStorage.getItem('client_middle_name') + ' ';
        }
        if (encryptStorage.getItem('client_last_name') && encryptStorage.getItem('client_last_name') !== '' && encryptStorage.getItem('client_last_name') !== null && encryptStorage.getItem('client_last_name') !== undefined) {
            client_name = client_name + encryptStorage.getItem('client_last_name') + ' ';
        }


        switch (type) {
            case "HospitalROIData":
                dataModel = {
                    selectedFile: null,
                    e_sign: 'No',
                    toggleSSN: true,
                    patient_name: client_name.trim(),
                    rrc_org: localStorage.getItem('selected_organization'),
                    form_selection_type: '',
                    types_of_records: '',
                    other_type_of_record: '',
                    covering_period_type: '',
                    specific_time_records: moment(),
                    specific_time_records_to: moment().add(1, 'month'),
                    authorization_confirmation: '',
                    client_name: '',
                    client_signature: '',
                    staff_signature: '',
                    RRC_phone: '',
                    RRC_email: '',
                    RRC_fax: '',
                    RRC_address: '',
                    RRC_city: '',
                    RRC_state: '',
                    RRC_zip: '',
                    reason: '',
                    purpose: '',
                    other_purpose: '',
                    other_entities: '',
                    other_entities_other: '',
                    other_entities_parent_guardian: '',
                    relation_with_clients: '',
                    relation_with_clients_other: '',
                    checkbox: '',
                    other_checkbox: '',
                    fax_info: '',
                    is_patient_legally_incapacitated: '',
                    expiry_period_from: '',
                    expiry_period_to: '',
                    expiry_condition_or_event: '',
                    authorization_copy_received: '',
                    patient_name_in_sign: '',
                    social_security: encryptStorage.getItem('ssn'),
                    date: '',
                    date1: '',
                    date2: '',
                    date3: '',
                    patient_sign: '',
                    date_in_sign: moment(),
                    witness_sign: '',
                    patient_personal_representative_name: '',
                    patient_personal_representative_sign: '',
                    legal_authority: '',
                    personal_representative_docs_received: '',
                    authorization_status: 'Active',
                    documents: null,
                    updated_on: '',
                    revoked_on: '',
                    updated_by: ''

                }
                break;

            case "resetData":
                dataModel = {
                    selectedFile: null,
                    e_sign: 'No',
                    patient_name: client_name.trim(),
                    toggleSSN: true,
                    rrc_org: localStorage.getItem('selected_organization'),
                    form_selection_type: '',
                    types_of_records: '',
                    other_type_of_record: '',
                    covering_period_type: '',
                    specific_time_records: moment(),
                    specific_time_records_to: moment().add(1, 'month'),
                    authorization_confirmation: '',
                    client_name: '',
                    client_signature: '',
                    staff_signature: '',

                    RRC_phone: '',
                    RRC_email: '',
                    RRC_fax: '',
                    RRC_address: '',
                    RRC_city: '',
                    RRC_state: '',
                    RRC_zip: '',
                    reason: '',
                    purpose: '',
                    other_purpose: '',
                    other_entities: '',
                    other_entities_other: '',
                    relation_with_clients: '',
                    other_relation_with_clients: '',
                    checkbox: '',
                    other_checkbox: '',
                    fax_info: '',
                    is_patient_legally_incapacitated: '',
                    expiry_period_from: '',
                    expiry_period_to: '',
                    expiry_condition_or_event: '',
                    authorization_copy_received: '',
                    patient_name_in_sign: '',
                    social_security: encryptStorage.getItem('ssn'),
                    date: '',
                    date1: '',
                    date2: '',
                    patient_sign: '',
                    date_in_sign: moment(),
                    witness_sign: '',
                    patient_personal_representative_name: '',
                    patient_personal_representative_sign: '',
                    legal_authority: '',
                    personal_representative_docs_received: '',
                    authorization_status: 'Active',
                    documents: null,
                    updated_on: '',
                    revoked_on: '',
                    updated_by: ''

                }
                break;

            case "DocumentsColumns":
                dataModel = [
                    {
                        name: 'File name',
                        sortable: true,
                        cell: (row) => (
                            <div>
                                <NavLink to={row.url} target="_blank" alt={row.name}>{row.name}</NavLink>
                            </div>
                        ),

                    },
                    {
                        name: 'Date',
                        selector: 'time',
                        sortable: false,
                    },
                    {
                        name: 'Uploaded by',
                        selector: 'uploaded_by',
                        sortable: true,
                    },
                    {
                        name: 'Remarks',
                        selector: 'remarks',
                        sortable: true,
                    }
                ];
                break;

            case "HospitalROIHistoryColumns":
                dataModel = [
                    {
                        name: 'Approved by',
                        selector: 'approved_by',
                        sortable: true,

                    },
                    {
                        name: 'Date',
                        selector: 'date',
                        sortable: true,
                        cell: (row) => {
                            if (row.date) {
                                return moment.unix(row.date).tz("America/New_York").format("MM/DD/YYYY");
                            } else {
                                return "";
                            }
                        },
                    },

                    {
                        name: 'View',
                        sortable: false,
                        cell: (row) => (
                            <div>
                                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleAuthorizationDetails.bind(this, 'Update')}></i></Link>
                            </div>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    },

                ];
                break;
        }
        return dataModel;
    }

    componentWillMount() {
        // for fetching layout definition
        axios.get(global.restApiURL + 'webservice/rest/class/id/51?apikey=' + localStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                console.log(res, 'res from will');
                var response = res.data;
                
                if (response.success && res.status == 200) {
                    validationObj = validationParser(response.data.layoutDefinitions.children[0].children);
                    let validationObj_old = validationParser1(response.data.layoutDefinitions.children[0].children);
                    console.log("validation : ", validationObj);

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,
                        fieldType: validationObj.fieldtype,
                        ...validationObj_old
                    });
                    console.log(validationObj, 'validationObj');

                    this.createSelectOptions();

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    this.props.navigate(`${global.domain}global.traversArray["out"]`);
                    // window.location.href = global.domain + global.traversArray['out'];

                    
                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });

        //Authorization Forms Mapping
        axios.get(global.restApiURL + 'webservice/rest/class/id/51?apikey=' + localStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success && res.status == 200) {
                    let FormsMappingObjects = validationParser1(response.data.layoutDefinitions.children[0].children);

                    this.setState({
                        Authorization_forms_mappings: { ...FormsMappingObjects },
                    });

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    this.props.navigate(`${global.domain}global.traversArray["out"]`);
                    // window.location.href = global.domain + global.traversArray['out'];

                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });
    }

    componentDidMount() {
        this.setState({disable:true})
        this.getHospitalROIList();

    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    getHospitalROIList = () => {
        if (localStorage.getItem('clientId') !== '') {

            let getHospitalROIListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: localStorage.getItem('clientId'),
            };

            axios.post(global.restApiURL + 'customRest/getConsentFormList', JSON.stringify(getHospitalROIListBody))
                .then(res => {
                    console.log(res, 'getConsentforROIList', JSON.stringify(getHospitalROIListBody));

                    if (res.data.success && res.status == 200) {
                        // const response = res.data.data.elements;

                        setTimeout(function () {

                            context.setState({
                                changeParticipantionsHistoryData: context.parseHospitalROIHistory(res.data.data)
                            });
                            console.log("obj his data : ", context.state.changeParticipantionsHistoryData);

                            localStorage.setItem('HistoryROIListData_length', res.data.data.length)
                        }, 50)

                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        this.props.navigate(`${global.domain}global.traversArray["out"]`);
                        // window.location.href = global.domain + global.traversArray['out'];

                    }
                    else {
                        alert(res.msg);
                    }


                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })
        }
    }

    resetDataField = (fieldName) => {
        let HospitalROIDataCopy = this.state.getHospitalROIListBodyData;
        let resetCopy = this.getModal('resetData');
        if (resetCopy[fieldName] !== undefined) {
            HospitalROIDataCopy[fieldName] = resetCopy[fieldName];
        }

        if (fieldName === 'specific_time_records') {
            HospitalROIDataCopy['specific_time_records_to'] = resetCopy['specific_time_records_to'];
        } else if (fieldName === 'is_patient_legally_incapacitated') {

            HospitalROIDataCopy['patient_personal_representative_name'] = resetCopy['patient_personal_representative_name'];
            HospitalROIDataCopy['patient_personal_representative_sign'] = resetCopy['patient_personal_representative_sign'];
            HospitalROIDataCopy['patient_personal_representative_sign_date'] = resetCopy['patient_personal_representative_sign_date'];
            HospitalROIDataCopy['legal_authority'] = resetCopy['legal_authority'];
            console.log('is_patient_legally_incapacitated', HospitalROIDataCopy)
        }
        console.log('resetting data', fieldName, resetCopy, resetCopy[fieldName])

        this.setState({ HospitalROIData: HospitalROIDataCopy });
    }

    parseHospitalROIHistory(historyDataArray) {
        let parsedData = [];

        parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {

            var op = {};

            item.map((val) => {
                op[val.name] = val.value;
            })

            // console.log("op : " , op );

            return op;

        }, this);

        // console.log("parsed Data : " , parsedData);

        return parsedData;
    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({


                // Multiselect
                other_entities_MultiOptions: multiSelectOptionGenerator(validationObj.options.other_entities),
                relation_with_clients_MultiOptions: selectOptionsGenerator(validationObj.options.relation_with_clients, context.state.HospitalROIData.relation_with_clients),
                checkbox_MultiOptions: multiSelectOptionGenerator(validationObj.options.checkbox),

            });

        }
    }

    handleAuthorizationDetails(type, event) {
        if (type !== '' && ['Update', 'Revoke'].includes(type)) {
            this.setState({ currentView: type });
        } else {
            this.props.navigate("")

            // window.location.href = '';
        }
        let historyID = event.target.id;
        this.setState({ historyID: historyID });

        console.log("history : ", historyID);

        context.setState({
            HospitalROIData: context.getModal("HospitalROIData")
        })

        axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
            .then(res => {

                const response = res.data.data.elements;
                let documents_uploaded = [];
                //console.log("Obj data , res : " , formObjDataMapper2(response , context.getModal("followupdata")) , response );

                let parsedHospitalROIDetails = formObjDataMapper2(response, context.getModal("HospitalROIData"));

                //values are commonly stored in single variable to be shown in Datatable
                if (parsedHospitalROIDetails.documents !== '') {
                    let docs = JSON.parse(parsedHospitalROIDetails.documents);

                    documents_uploaded = docs !== null && docs.length > 0 ? documents_uploaded.concat(docs) : documents_uploaded;
                }

                console.log('docuemnts uploaded', documents_uploaded)



                parsedHospitalROIDetails['documents'] = null;
                parsedHospitalROIDetails['selectedFile'] = null;

                Object.entries(parsedHospitalROIDetails).map(([key, value]) => {
                    if (['date', 'date_in_sign', 'patient_personal_representative_sign_date', 'updated_on'].includes(key)) {
                        parsedHospitalROIDetails[key] = (value !== '' && value !== null && value !== undefined && value !== 'Invalid date' ? new Date(value) : '');
                        console.log('date converted', key, value, parsedHospitalROIDetails[key], parsedHospitalROIDetails)
                    }
                })

                console.log('documents 2', documents_uploaded)

                context.setState({
                    HospitalROIData: parsedHospitalROIDetails,
                    all_uploaded_documents: documents_uploaded,
                    showAddHospitalROIModal: true
                })

                console.log(context.state.HospitalROIData);

                context.createSelectOptions();

                // context.onFollowUpDetailsModalOpen();

            })
            .catch(err => { // log request error and prevent access to undefined state
                context.setState({ loading: false, error: true });
                console.error(err);
            })
    }

    openAddHospitalROIModal() {
        context.setState({
            currentView: 'Add',
            all_uploaded_documents: [],
            historyID: 0,
            HospitalROIData: context.getModal('resetData'),
            showAddHospitalROIModal: true
        })
    }

    closeAddHospitalROIModal = () => {
        context.getHospitalROIList();
        context.setState({
            HospitalROIData: context.getModal('resetData'),
            showAddHospitalROIModal: false,
            all_uploaded_documents: []
        })
        setTimeout(() => context.createSelectOptions(), 100);

        localStorage.removeItem('modalOpen');
    }

    handleHospitalROIelectChange(event) {
        console.log("selectchange : ", event.target.name);
        let name = event.target.name;
        let value = event.target.value;

        this.setState((prevState) => ({
            ...prevState,
            HospitalROIData: {
                ...prevState.HospitalROIData,
                [name]: value
            }
        }));

    }

    handlePhoneChange = (event) => {

        // //console.log(event.target.value)
        if (event.target !== null) {
            let test = event.target.value;

            if (event.target.name === "RRC_phone") {
                //console.log("Test : " , event.target.name ) ;

                this.setState(prevState => ({
                    ...prevState,
                    HospitalROIData: {
                        ...prevState.HospitalROIData,
                        RRC_phone: test
                    },
                }));
            }
            if (event.target.name === "RRC_fax") {
                //console.log("Test : " , event.target.name ) ;

                this.setState(prevState => ({
                    ...prevState,
                    HospitalROIData: {
                        ...prevState.HospitalROIData,
                        RRC_fax: test
                    },
                }));
            }

            if (event.target.name === "social_security") {
                //console.log("Test : " , event.target.name ) ;

                this.setState(prevState => ({
                    ...prevState,
                    unSaved: true,
                    HospitalROIData: {
                        ...prevState.HospitalROIData,
                        social_security: test
                    }
                }));
            }
        }


    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {

        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.HospitalROIData.RRC_fax.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    beforeMaskedValueChange2 = (newState, oldState, userInput) => {

        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.HospitalROIData.RRC_phone.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    handleMultiSelectChanges = (selectedOption, event) => {
        this.setState({disable:false})
        var updatedState = this.state.HospitalROIData;
        updatedState[event.name] = selectedOption;
        this.setState(prevState => ({
            ...prevState,
            HospitalROIData: updatedState
        }));
        console.log(this.state.HospitalROIData, 'gggggggggggggg');
        // console.log( event.name ," : ", this.state.lastNightStayData);
    };
    handleFollowUpSelectChange = (event) => {
        // console.log("selectchange : ", event.target.value);
        // let name = event.target.name;
        // let value = event.target.value;

        // if (name === "relation_with_clients" ) {
        //     this.setState((prevState) => ({
        //         ...prevState,
        //         HospitalROIData: {
        //             ...prevState.HospitalROIData,
        //             [name]: value
        //         }
        //     }));
        // }
        
        console.log(event.target.name, 'event name');
        this.setState({disable:false})
        var updatedState = this.state.HospitalROIData;

        updatedState[event.target.name] = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            HospitalROIData: updatedState
        }));
        console.log(this.state.HospitalROIData, 'gggggggggggggg');
    }



    closeAuthorizationDetailsModal() {

        context.setState({
            HospitalROIData: context.getModal('resetData'),
            showAddHospitalROIDataModal: false
        });
        setTimeout(() => context.createSelectOptions(), 100);
    }

    openAuthorizationDetailsModal() {
        context.setState({
            showAddHospitalROIModal: false,
            showHospitalROIDetailsModal: true,
        });
    }

    onChangeHandler = event => {

        console.log('uploaded files', event.target.files);
        let file_size = event.target.files[0].size;

        //or if you like to have name and type
        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;
        //do whatever operation you want to do here
        console.log("file size : ", file_size, " file type : ", file_type);
        let selectedFile = [];
        for (let i = 0; i < event.target.files.length; i++) {
            selectedFile.push(event.target.files[i]);
        }

        console.log('select files', selectedFile)
        this.setState({
            selectedFile,
            loaded: 0,
        })

    }


    saveHospitalROIdetails = () => {
        this.setState({disable:true})
        let buttonType = 'save';//event.target.value ;
        let today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        console.log("date : ", date);
        console.log("date : ", date);
        const data = new FormData();
        Object.entries(context.state.HospitalROIData).map(([key, value]) => {
            if (key === 'types_of_records') {
                if (this.state.HospitalROIData.types_of_records !== undefined && this.state.HospitalROIData.types_of_records !== null && this.state.HospitalROIData.types_of_records !== '') {
                    for (let i = 0; i < this.state.HospitalROIData.types_of_records.length; i++) {
                        data.append(`types_of_records[${i}]`, this.state.HospitalROIData.types_of_records[i].label)
                    }
                } else {
                    data.append(`types_of_records`, '')
                }

            } else if (key === 'other_entities') {
                if (this.state.HospitalROIData.other_entities !== undefined && this.state.HospitalROIData.other_entities !== null && this.state.HospitalROIData.other_entities !== '') {
                    for (let i = 0; i < this.state.HospitalROIData.other_entities.length; i++) {
                        data.append(`other_entities[${i}]`, this.state.HospitalROIData.other_entities[i].label)
                    }
                } else {
                    data.append(`other_entities`, '')
                }

                /* }else if(['expiry_period_from', 'expiry_period_to', 'date'].includes(key)){
                   data.append(key, getFormattedDate(value));
                 }else if(['date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on'].includes(key)){
                   console.log('formatting date : ',key)
                   data.append(key, getFormattedDate(value, true));*/
            } else if (key === 'checkbox') {
                if (this.state.HospitalROIData.checkbox !== undefined && this.state.HospitalROIData.checkbox !== null && this.state.HospitalROIData.checkbox !== '') {
                    for (let i = 0; i < this.state.HospitalROIData.checkbox.length; i++) {
                        data.append(`checkbox[${i}]`, this.state.HospitalROIData.checkbox[i].label)
                    }
                } else {
                    data.append(`checkbox`, '')
                }

                /* }else if(['expiry_period_from', 'expiry_period_to', 'date'].includes(key)){
                   data.append(key, getFormattedDate(value));
                 }else if(['date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on'].includes(key)){
                   console.log('formatting date : ',key)
                   data.append(key, getFormattedDate(value, true));*/
            } else if (['specific_time_records', 'specific_time_records_to', 'date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on', 'expiry_period_from', 'expiry_period_to', 'date','date1','date2',"date3"].includes(key)) {

                if (key === 'expiry_period_from') {
                    value = context.state.HospitalROIData.date_in_sign;
                }

                data.append(key, value !== '' && value !== null && value !== undefined && value !== "Invalid date" ? moment(value).format('YYYY-MM-DD') : '');
            } else {
                data.append(key, value);
            }

        })
        let emptyFormData = true;
        for (var val of data.values()) {
            if (val !== '' && val !== 'null' && val !== null) {
                emptyFormData = false;

            } else {
                console.log('entries', data.values(), data.entries(), this.state.selectedFile);
            }
        }

        if (localStorage.getItem('HistoryROIListData_length') === 0) {
            data.append('key', localStorage.getItem('clientId') + "-1");
        } else {
            data.append('key', localStorage.getItem('clientId') + "-" + (parseInt(localStorage.getItem('HistoryROIListData_length')) + 1))
        }


        if (this.state.HospitalROIData.selectedFile !== null) {
            for (let i = 0; i < this.state.HospitalROIData.selectedFile.length; i++) {
                console.log('representative_docs length', this.state.HospitalROIData.selectedFile.length)
                if (this.vlidateFileSelection(this.state.HospitalROIData.selectedFile[i])) {
                    data.append(`representative_docs[${i}]`, this.state.HospitalROIData.selectedFile[i])
                } else {
                    return false;
                }

            }
        }

        if (this.state.HospitalROIData.documents !== null) {
            for (let i = 0; i < this.state.HospitalROIData.documents.length; i++) {
                console.log('representative_docs length', this.state.HospitalROIData.documents.length)
                if (this.vlidateFileSelection(this.state.HospitalROIData.documents[i])) {
                    data.append(`attachments[${i}]`, this.state.HospitalROIData.documents[i]);
                } else {
                    return false;
                }
            }
        }


        data.append('client_id', localStorage.getItem('clientId'))
        data.append('updated_by', localStorage.getItem('loggedinUserID'))
        data.append('updated_on', getFormattedDate(new Date(), true))
        data.append('apikey', localStorage.getItem('__TOKEN__'))
        data.append('version', "1")
        data.append('historyID', this.state.historyID);
        data.append('signature', this.state.HospitalROIData.signature)
        data.append('staff_signature', this.state.HospitalROIData.staff_signature)

        // data.append('signature',this.state.HospitalROIData.signature)

        console.log('data abcd', data)
        console.log(data.values(), 'data.values.signature');
        axios.post(global.restApiURL + "customRest/uploadCCDAROIAttachments", data)
            .then(res => {
                // console.log(res) ;
                // alert(res.data.msg);
                let response = res.data;
                if (response.success && res.status == 200) {
                    let state_of_success = '';
                    switch (context.state.currentView) {
                        case 'Add':
                            state_of_success = 'added';
                            break;

                        case 'Update':
                            state_of_success = 'updated';
                            break;
                    }
                    
                    context.closeAddHospitalROIModal();
                   
                     localStorage.setItem('success_msg', "verbal consent " + state_of_success + " successfully.");
                     showSuccessToast(localStorage.getItem("success_msg"));

                    localStorage.setItem('HistoryROIListData_length', (parseInt(localStorage.getItem('HistoryROIListData_length')) + 1));
                    this.props.navigate("")
                    this.showSuccessToast()


                    // window.location.href = '';
                }
                else if ("error_code" in response) {
                    // alert(response.msg);
                    showErrorToast(response.msg);
                    this.props.navigate(`${global.domain}global.traversArray["out"]`);
                    // window.location.href = global.domain + global.traversArray['out'];

                }
                else {
                    showErrorToast(response.msg);
                    alert(response.msg);
                    context.closeAddHospitalROIModal();
                }
            }).catch(err => { 

                console.error(err);
            });

    }

    vlidateFileSelection(file) {

        if ((file.size / 1024 / 1024) > 5) {
            alert(file.name + " : File size too large !");
            return false;
        }
        else {
            return true;
        }
    }

    uploadImage = (e) => {
        let file = e.target.files[0];
        if (file && !file.name) {
            window.alert("Please select a file");
            return false;
        }
        if (file.size > 10e6) {
            window.alert("Please upload a file smaller than 10 MB");
            return false;
        }
    }

    Filevalidation = () => {
        const fi = document.getElementById('file');
        // Check if any file is selected. 
        if (fi.files.length > 0) {
            for (var i = 0; i <= fi.files.length - 1; i++) {

                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                // The size of the file. 
                if (file >= 4096) {
                    alert(
                        "File too Big, please select a file less than 4mb");
                } else if (file < 2048) {
                    alert(
                        "File too small, please select a file greater than 2mb");
                } else {
                    document.getElementById('size').innerHTML = '<b>'
                        + file + '</b> KB';
                }
            }
        }
    }

    getOneYearIntervalDateFromDateOfSigning() {
        const date_of_signing = this.state.HospitalROIData.date_in_sign;
        let date_after_one_yr_of_sign = new Date(this.state.HospitalROIData.date_in_sign);
        date_after_one_yr_of_sign = new Date(date_after_one_yr_of_sign.setFullYear(date_after_one_yr_of_sign.getFullYear() + 1));
        console.log('date of signing', this.state.HospitalROIData.date_in_sign, date_of_signing, date_after_one_yr_of_sign)

        return '(' + convertDate(date_of_signing, 'MM/DD/YYYY') + ' - ' + convertDate(date_after_one_yr_of_sign, 'MM/DD/YYYY') + ')';
    }

    handleDateChange = name => date => {
        //console.log("Selected date is : " + date ) ;
        var stateCopy = Object.assign({}, this.state);

        if (name === 'date_in_sign') {
            let expiry_date = new Date(date);
            expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
            stateCopy.HospitalROIData['expiry_period_to'] = expiry_date;
            stateCopy.HospitalROIData[name] = date;
        } else {
            stateCopy.HospitalROIData[name] = date;
        }
        this.setState(stateCopy);
    };

    handleRadioChange = (event) => {
        this.setState({disable:false})
        let radio_options = context.state[event.target.name]['options'];
        if (event.target.name === 'is_patient_legally_incapacitated') {
            context.resetDataField('is_patient_legally_incapacitated');
        } else {
            radio_options && radio_options !== null && radio_options !== undefined && radio_options.map((option) =>
                context.resetDataField(option.value)
            )
        }
        console.log("event name : ", event.target.name, event.target.value);
        let tempOb = context.state.HospitalROIData;
        // console.log("before : " , this.state.phq9Data);
        if (event.target.name === 'authorization_period_type' && event.target.value === 'one_year_from_sign') {
            let expiry_date = new Date(convertDate(tempOb.date_of_signing, 'MM/DD/YYYY'));
            expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
            tempOb['expiry_period_to'] = expiry_date;
        }
        tempOb[event.target.name] = event.target.value;
        this.setState((prevState) => ({
            ...prevState,
            HospitalROIData: tempOb
        })
        );
    }

    handleCheckboxChange = (event) => {
        let tempOb = context.state.HospitalROIData;

        // console.log("before : " , this.state.phq9Data);

        tempOb[event.target.name] = event.target.checked;

        this.setState((prevState) => ({
            ...prevState,
            HospitalROIData: tempOb
        })
        );

    }

    route = (route) => {
        localStorage.setItem('modalOpen', true);
        switch (route) {
            // window.location.href = global.domain + "followup";
            case "Follow-Up":
              this.props.navigate(`${global.domain}followup`);
      
              break;
      
            case "Routing":
                this.props.navigate(`${global.domain}routing`);
                // window.location.href = global.domain + "routing";
      
              break;
      
              case "Referral":
                  this.props.navigate(`${global.domain}referrals`);
                  // window.location.href = global.domain + "referrals";
      
              break;
          }
       
    }

   
    handleEvent(event, picker) {


        let tempOb = context.state.HospitalROIData;

        // console.log("before : " , tempOb);

        tempOb['specific_time_records'] = picker.startDate;
        tempOb['specific_time_records_to'] = picker.endDate;

        context.setState((prevState) => ({
            ...prevState,
            HospitalROIData: tempOb
        })
        );

    }

    onDrop(files) {
        this.setState(prevState => ({
            ...prevState,
            HospitalROIData: {
                ...prevState.HospitalROIData,
                selectedFile: files
            },
        }));
    }

    onDrop_docs(files) {
        this.setState({disable:false}) 
        this.setState(prevState => ({
            ...prevState,
            HospitalROIData: {
                ...prevState.HospitalROIData,
                documents: files
            },
        }));
    }

    enablePrintStyles = () => {
        console.log('printing')
        this.setState({ print: true })
        return Promise.resolve();
        console.log('printing state', this.state)
    }

    disablePrintStyles = () => {
        console.log('printing close')
        this.setState({ print: false })
    }

    handleTimer(date, field) {
        // debugger
        this.setState({disable:false})
        let HospitalROIData_temp = this.state.HospitalROIData;
        if (field === 'date_in_sign') {
            HospitalROIData_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY') : date;
            HospitalROIData_temp['expiry_period_to'] = moment(date._d).add(1, 'years').format('MM/DD/YYYY');
        } else {
            HospitalROIData_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY') : date;
        }

        context.setState({ HospitalROIData: HospitalROIData_temp, unSaved: true });
    };
    handleTimeronBlur(date, field) {

        date = date ? date : context.state.HospitalROIData[field];

        // date = context.state.HospitalROIData[field];
        console.log("timer 2", date, field);
        let HospitalROIData_temp = this.state.HospitalROIData;

        // HospitalROIData_temp[field] = typeof date !== 'string' && date !==null && date !==undefined ? moment(date).format('MM/DD/YYYY') : " ";
        HospitalROIData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().format('MM/DD/YYYY') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
        context.setState({ HospitalROIData: HospitalROIData_temp, unSaved: true });
    };

    validDobDate(current, selectedDate) {
        return current.isBefore(moment().subtract(context.state.min_dob, 'years'));
    };

    validExpiryDate(current, selectedDate) {
        return current.isAfter(moment(context.state.HospitalROIData.date_in_sign));
    };

    toggleSSN = () => {
        this.setState({ toggleSSN: !this.state.toggleSSN });
    }

    beforeMaskedValueChangeSSN = (newState, oldState, userInput) => {

        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.HospitalROIData.social_security.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    autoResization = () => {
        this.setState({
            abc : 'xyz'
        });
    }

    render() {

        $(document).ready(function () {


            var NumRegex = /^[0-9]+$|^$|^\s$/;
            var EmailRegex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

            $.validator.addMethod('NumRegex', function (value, element, parameter) {
                return value.match(NumRegex);
            }, 'Please enter a valid zipcode.');

            $.validator.addMethod('EmailRegex', function (value, element, parameter) {
                return value.match(EmailRegex);
            }, 'Please enter a valid email address.');

            $('#AuthorizationForm').validate({
                rules: {
                    RRC_zip: {
                        NumRegex: true
                    },
                    RRC_email: {
                        EmailRegex: true
                    },
                },

                submitHandler: function () {
                    context.saveHospitalROIdetails();
                },


                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.form-group').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                },

            });


            $('form input').keydown(function (e) {
                if (e.keyCode == 13) {
                    var inputs = $(this).parents("form").eq(0).find(":input");
                    if (inputs[inputs.index(this) + 1] != null) {
                        inputs[inputs.index(this) + 1].focus();
                    }
                    e.preventDefault();
                    return false;
                }
            });
        });

        localStorage.setItem('formname', 'Criminal Justice ROI');

        console.log('specific time', this.state.HospitalROIData);

        let min_dob = new Date();
        min_dob.setFullYear(min_dob.getFullYear() - this.state.min_dob);

        let label = '';
        let start = this.state.HospitalROIData.specific_time_records !== "" && this.state.HospitalROIData.specific_time_records !== null && this.state.HospitalROIData.specific_time_records !== undefined ? this.state.HospitalROIData.specific_time_records.format('MM/DD/YYYY') : "";

        let end = this.state.HospitalROIData.specific_time_records_to !== "" && this.state.HospitalROIData.specific_time_records_to !== null && this.state.HospitalROIData.specific_time_records_to !== undefined ? this.state.HospitalROIData.specific_time_records_to.format('MM/DD/YYYY') : "";
        label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }
        let width_md_6 = {}
        let width_md_25 = {}
        let width_md_30 = {}
        let width_md_45 = {}
        let width_md_100 = {}
        let width_md_38 = {}
        let width_md_I = {}
        let width_md_auth = {}
        let width_md_5 = {}
        let width_md_4 = {}
        let width_md_3 = {}
        let width_md_2 = {}
        let width_md_10 = {}
        let width_md_1 = {}
        let floatLeft = {}
        let checkboxMargin = {}
        let bodyMargin = {}
        let displayNone = {}
        let table_print = {}
        let td_print = {}
        let th_print = {}
        let pageBreak = {}
        let width_40 = {}


        if (this.state.print === true) {

            floatLeft = {
                float: "left",
                padding: "8px 2px 0px 2px"
            }
            width_40 = {
                width: '40%'
            }
            checkboxMargin = {
                padding: "3px",
                display: "inline"
            }

            displayNone = {
                display: "none"
            }

            bodyMargin = {
                margin: "30px"
            }

            width_md_6 = {
                width: "50%",
                display: "inline",
                float: "left"
            }
            width_md_100 = {
                width: "100%",
                display: "inline",
                float: "left"
            }
            width_md_I = {
                width: "3%",
                display: "inline",
                float: "left",
                marginTop: '1%'
            }

            width_md_5 = {
                width: "41.666667%",
                display: "inline",
                float: "left"
            }
            width_md_25 = {
                width: "24.666667%",
                display: "inline",
                float: "left",
                marginTop: '1%'
            }
            width_md_30 = {
                width: "29.666667%",
                display: "inline",
                float: "left",
            }
            width_md_45 = {
                width: "44.666667%",
                display: "inline",
                float: "left",
                marginTop: '1%'
            }
            width_md_38 = {
                width: "38.666667%",
                display: "inline",
                float: "left"
            }
            width_md_auth = {
                width: "13%",
                display: "inline",
                float: "left",
                marginTop: '1%'
            }

            width_md_4 = {
                width: "33.333333%",
                display: "inline",
                float: "left"
            }

            width_md_3 = {
                width: "25%",
                display: "inline",
                float: "left"
            }

            width_md_2 = {
                width: "16.666667%",
                display: "inline",
                float: "left"
            }

            width_md_10 = {
                width: "83.33333333%",
                display: "inline",
                float: "left"
            }

            width_md_1 = {
                width: "8.333333%",
                display: "inline",
                float: "left"
            }

            table_print = {
                borderCollapse: "collapse",
                width: "100%"
            }

            td_print = {
                border: "1px solid #ddd",
                padding: "8px",
            }

            th_print = {
                paddingTop: "12px",
                paddingBottom: "12px",
                textAlign: "left",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "1px solid #ddd",
                padding: "8px",
            }
            pageBreak = { height: "120vh", paddingTop: "15vh" }


        }

        let locale = {
            format: 'MM/DD/YYY',
            separator: ' - ',
            applyLabel: 'Apply',
            cancelLabel: 'Clear',
            weekLabel: 'W',
            customRangeLabel: 'Custom Range',
            daysOfWeek: moment.weekdaysMin(),
            monthNames: moment.monthsShort(),
            firstDay: moment.localeData().firstDayOfWeek(),
        };

        let fax_info = [];
        {
            validationObj.options !== undefined && validationObj.options.fax_info.map((option) => {

                fax_info.push(
                    <div className=" col-lg-1  col-md-1 form-group d-inline">
                        <input className="" type="radio" name={context.state.fieldName.fax_info} value={option.value} defaultChecked={context.state.HospitalROIData.fax_info === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                    </div>
                )

            })
        }

        let docs_content_for_printing = '';
        if (this.state.all_uploaded_documents !== null && this.state.all_uploaded_documents !== undefined && this.state.all_uploaded_documents.length > 0) {
            let docs_content_headers = <tr><th style={th_print}>File</th><th style={th_print}>Remarks</th><th style={th_print}>Uploaded On</th></tr>;
            let docs_content_body = [];
            this.state.all_uploaded_documents.map(doc => docs_content_body.push(<tr><td style={td_print}>{doc.name}</td><td style={td_print}>{doc.remarks}</td><td style={td_print}>{doc.time}</td></tr>))

            docs_content_for_printing = <table style={table_print}>{docs_content_headers}{docs_content_body}</table>;
        }
        
    

        return (

            <div className="hold-transition sidebar-mini text-sm">

                <div className="wrapper">
                    <AppTopNav />
                    <div className="content-wrapper">

                        <Patientinfoheader clientId={localStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
                            email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
                            peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={localStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
                        <section className="content">
                            <div className="row">
                                <Sidenav />
                                <div className="container-fluid col-md-9 col-lg-10" onMouseMove={this.autoResization}>
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <p className="lead float-left"><b className="text-green">Documentation Of Criminal Justice ROI</b></p>
                                            <button type="submit" className="btn btn-primary float-right" value="proceed"
                                             
                                             // window.location.href = global.traversArray['verbal-consent']}
                                            onClick={() =>                                                 
                                            this.props.navigate(`${global.traversArray["verbal-consent"]}`)
                                        }
                                            >
                                                <img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                            {can_add('HospitalROI') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddHospitalROIModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add New</button> : ""}
                                            <input type="hidden" id="clicked" value="" readOnly />
                                        </div>
                                        <div className="card-body">
                                            <DataTable
                                                className='table_Data'
                                                columns={this.state.HospitalROIHistoryColumns}
                                                data={this.state.changeParticipantionsHistoryData}
                                                customStyles={global.customTableStyles}
                                                striped={true}
                                                pagination={this.state.changeParticipantionsHistoryData ? true : false}
                                                paginationComponentOptions={global.paginationComponentOptions}
                                                highlightOnHover
                                                pointerOnHover
                                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                            />
                                        </div>
                                        <div className="card-footer ">
                                            <div className="float-right"><button type="submit" className="btn btn-primary float-right" value="proceed" 
                                               onClick={() =>                                                 
                                                this.props.navigate(`${global.traversArray["verbal-consent"]}`)
                                            }
                                            // window.location.href = global.traversArray['verbal-consent']
                                                
                                                >
                                                    <img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                                {can_add('HospitalROI') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddHospitalROIModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add New</button> : ""}
                                                <input type="hidden" id="clicked" value="" readOnly />
                                            </div>
                                        </div>
                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showAddHospitalROIModal}
                                            onClose={this.closeAddHospitalROIModal}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            className="modal-xl modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-6">
                                                            <h4 className="modal-title float-left text-green">{this.state.currentView} Criminal Justice ROI </h4>
                                                            <br />
                                                            <br />
                                                            <span> Authorization for Use, Disclosure, and/or Release of Information
                                                                For Criminal Justice Reporting and Related Entities-Adult</span>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form id="AuthorizationForm" method="post" action="javascript:void(0)" ref={el => (this.componentRef = el)}>
                                                    {this.state.print && <>
                                                        <span> Authorization for Use, Disclosure, and/or Release of Information For Criminal Justice Reporting and Related Entities-Adult</span>
                                                    </>}
                                                    <fieldset style={bodyMargin}>
                                                        <div className="row edit_allowed" >
                                                            <div className="col-lg-12 col-lg-12 form-group">
                                                                <span style={width_md_I} className="col-lg-1 col-md-1">I, </span>
                                                                <input type="text" style={width_md_5} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.patient_name} id={context.state.fieldName.patient_name} defaultValue={this.state.HospitalROIData.patient_name} required={this.state.mandatoryInfo.patient_name} maxLength={this.state.patient_name.columnLength} disabled={true} />
                                                                <span style={width_md_auth} className="col-lg-1 col-md-1"> authorize the </span><input type="text" style={width_md_38} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.rrc_org} id={context.state.fieldName.rrc_org} defaultValue={this.state.HospitalROIData.rrc_org} required={this.state.mandatoryInfo.rrc_org} maxLength={this.state.rrc_org.columnLength} disabled={true} />
                                                            </div>
                                                            <div className="col-lg-12 col-lg-12 form-group">
                                                                or their individual representative(s) to send and receive information from members of the interdisciplinary treatment team and other entities as listed below.
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div style={width_md_100} className="col-lg-12  col-md-12 form-group specific_forms_selected" >
                                                                <Select
                                                                    name={context.state.fieldName.other_entities}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    value={this.state.HospitalROIData.other_entities}
                                                                    onChange={this.handleMultiSelectChanges}
                                                                    options={this.state.other_entities_MultiOptions}
                                                                    styles={colourStyles}
                                                                    required={this.state.other_entities.mandatory}
                                                                />
                                                                {this.state.HospitalROIData.other_entities && context.state.HospitalROIData.other_entities.filter(function (e) { return e.label === 'Any individuals employed with or representing the following hospital providers:' }).length > 0 ? (
                                                                    <div style={width_md_100} className="col-lg-12  col-md-12 form-group specific_forms_selected" >
                                                                        <ul>
                                                                            <li>All programs and departments associated with UAB Hospital</li>
                                                                            <li>Medical West</li>
                                                                            <li>Cooper Green Mercy Health Services</li>
                                                                            <li>Brookwood Baptist Hospital (and all affiliates including but not limited to Shelby Baptist, Walker Baptist)</li>
                                                                            <li>Grandview Medical Center</li>
                                                                            <li>St. Vincent’s Downtown</li>
                                                                            <li>St. Vincent’s East</li>
                                                                        </ul>
                                                                    </div>
                                                                ) : ""}
                                                                {this.state.HospitalROIData.other_entities && context.state.HospitalROIData.other_entities.filter(function (e) { return e.label === 'Any Parent/Guardian with custodial rights'; }).length > 0 ? (
                                                                    <TextareaAutosize 
                                                                        className="form-control mt-2" 
                                                                        style={{overflow : 'hidden'}}
                                                                        name="other_entities_parent_guardian" 
                                                                        id="other_entities_parent_guardian" 
                                                                        minRows={(this.state.HospitalROIData.other_entities_parent_guardian?.length / 150) + this.state.HospitalROIData.other_entities_parent_guardian?.split(/\r\n|\r|\n/).length}
                                                                        placeholder="Any Parent/Guardian with custodial rights" 
                                                                        defaultValue={this.state.HospitalROIData.other_entities_parent_guardian} 
                                                                        required={this.state.other_entities_other.mandatory} 
                                                                        onChange={this.handleRadioChange} 
                                                                    />
                                                                ) : ""}
                                                                {this.state.HospitalROIData.other_entities && context.state.HospitalROIData.other_entities.filter(function (e) { return e.label === 'Other'; }).length > 0 ? (
                                                                    <TextareaAutosize 
                                                                        className="form-control mt-2" 
                                                                        name="other_entities_other" 
                                                                        id="other_entities_other" 
                                                                        minRows={(this.state.HospitalROIData.other_entities_other?.length / 150) + this.state.HospitalROIData.other_entities_other?.split(/\r\n|\r|\n/).length}
                                                                        style={{overflow : 'hidden'}}
                                                                        placeholder={this.state.labelText.other_entities} 
                                                                        defaultValue={this.state.HospitalROIData.other_entities_other} 
                                                                        required={this.state.other_entities_other.mandatory} 
                                                                        onChange={this.handleRadioChange} 
                                                                    />
                                                                ) : ""}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="row edit_allowed" >
                                                                <div className="col-lg-12 col-lg-12 form-group">
                                                                    <span style={width_md_25} className="col-lg-3 col-md-3">I understand that I am giving the </span>
                                                                    <input type="text" style={width_md_30} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.rrc_org} id={context.state.fieldName.rrc_org} defaultValue={this.state.HospitalROIData.rrc_org} required={this.state.mandatoryInfo.rrc_org} maxLength={this.state.rrc_org.columnLength} disabled={true} />
                                                                    <span style={width_md_45} className="col-lg-3 col-md-3">permission to have open and honest</span><br /><br /> <span >conversations/correspondence with any of the above agents concerning my participation in the {this.state.HospitalROIData.rrc_org}. I understand any information released will accurately reflect my behavior as a participant in/at the {this.state.HospitalROIData.rrc_org}. I understand that this
                                                                        release is set to expire one year after the signing date or if otherwise specified. The information to be released may include, but is not limited to, the following. </span>
                                                                </div>
                                                            </div>
                                                            <div style={width_md_100} className="col-lg-12  col-md-12 form-group specific_forms_selected" >
                                                                <Select
                                                                    name={context.state.fieldName.checkbox}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    value={this.state.HospitalROIData.checkbox}
                                                                    onChange={this.handleMultiSelectChanges}
                                                                    options={this.state.checkbox_MultiOptions}
                                                                    styles={colourStyles}
                                                                    required={this.state.checkbox.mandatory}
                                                                />
                                                                {this.state.HospitalROIData.checkbox && context.state.HospitalROIData.checkbox.filter(function (e) { return e.label === 'Other (Specify)'; }).length > 0 ? (
                                                                    <TextareaAutosize 
                                                                        className="form-control mt-2" 
                                                                        name="other_checkbox" 
                                                                        id="other_checkbox" 
                                                                        minRows={(this.state.HospitalROIData.other_checkbox?.length / 150) + this.state.HospitalROIData.other_checkbox?.split(/\r\n|\r|\n/).length}
                                                                        style={{overflow : 'hidden'}}
                                                                        placeholder='Please describe other information' 
                                                                        defaultValue={this.state.HospitalROIData.other_checkbox} 
                                                                        required={this.state.other_checkbox.mandatory} 
                                                                        onChange={this.handleRadioChange} 
                                                                    />
                                                                ) : ""}
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <strong>A SEPARATE AUTHORIZATION, AS DEFINED BY HIPAA, IS REQUIRED FOR PSYCHOTHERAPY NOTES.</strong>
                                                            <span>I understand that my records are protected under Federal Confidentiality Regulations (Federal Register, 42 CFR Part 2) and cannot be disclosed or re-disclosed by
                                                                the receiving party without my written consent unless otherwise provided for in the regulations. I, being in full knowledge that the information to be released, may be
                                                                either positive or negative, depending upon my behavior, authorize the {this.state.HospitalROIData.rrc_org}  and its affiliates to release any of this confidential information
                                                                which is, or will come to be in their position, either written or verbal, for the purpose of coordinating treatment efforts.</span>
                                                        </div>
                                                        <br />
                                                        <div className='row'>
                                                            <div style={width_md_6} className='col-lg-6 col-md-6 '>
                                                                <span>Relationship to client</span>   {/*relation to the clients*/}
                                                            </div>
                                                            <div className="col-lg-6 col-md-6" style={width_40}>
                                                                <div className="form-group">
                                                                    <select name='relation_with_clients' className="form-control" aria-hidden="true" value={this.state.HospitalROIData.relation_with_clients} onChange={this.handleFollowUpSelectChange} required={this.state.relation_with_clients.mandatory} >
                                                                        {this.state.relation_with_clients_MultiOptions}
                                                                    </select>
                                                                </div>
                                                                {/* {this.state.HospitalROIData.relation_with_clients === 'Other (Please Specify Below)' ? (<input type="text" className="form-control mt-2" name="other_relation_with_clients" id="other_relation_with_clients" placeholder={this.state.labelText.other_relation_with_clients} defaultValue={this.state.HospitalROIData.other_relation_with_clients} required={this.state.other_relation_with_clients.mandatory} onChange={this.handleRadioChange} />) : ""} */}
                                                            </div>
                                                        </div>
                                                        {/* Code Added Bby ashraf start*/}
                                                        {this.state.HospitalROIData.relation_with_clients === 'Other (describe)' && <>
                                                            <div className='row'>
                                                                <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                    <span>Describe other relationship</span>
                                                                </div>
                                                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                    <input type="text" className="form-control mt-2" name="other_relation_with_clients" id="other_relation_with_clients" placeholder={this.state.labelText.other_relation_with_clients} defaultValue={this.state.HospitalROIData.other_relation_with_clients} required={this.state.other_relation_with_clients.mandatory} onChange={this.handleRadioChange} />
                                                                </div>
                                                            </div>
                                                        </>}
                                                        {/* Code Added Bby ashraf End*/}


                                                        <div className='row'>
                                                            <span>If you are the legal guardian or representative appointed by the Court for the client, please attach a copy of this authorization to receive this protected health
                                                                information.</span>
                                                        </div>
                                                        <div className='row' style={{ marginTop: '3%' }}>
                                                            <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                <LabelField  {...context.state.client_signature} />  {/*Client Signature*/}
                                                            </div>
                                                            <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <input type="text" className="form-control" placeholder="Signature" name={context.state.fieldName.client_signature} id={context.state.fieldName.client_signature} defaultValue={this.state.HospitalROIData.client_signature==="null"?"":this.state.HospitalROIData.client_signature} required={this.state.mandatoryInfo.client_signature} maxLength={this.state.client_signature.columnLength} onChange={this.handleRadioChange} />
                                                            </div>
                                                        </div>
                                                        <div className='row' >
                                                            <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                <LabelField  {...context.state.date} /> (MM/DD/YYYY) {/*date*/}
                                                            </div>
                                                            <div style={width_md_6} className="col-lg-6 col-md-6 form-group">
                                                                <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                    <span style={{ width: '100%' }}>
                                                                        <DateTime
                                                                            onBlur={(date) => this.handleTimeronBlur(date, 'date')}
                                                                            onChange={(date) => this.handleTimer(date, 'date')}
                                                                            inputProps={{ value: typeof context.state.HospitalROIData.date === 'string' ? context.state.HospitalROIData.date : moment(context.state.HospitalROIData.date).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.date, name: 'date', placeholder: 'Date' }}
                                                                            defaultValue={moment(this.state.HospitalROIData.date).format('MM/DD/YYYY')}
                                                                            dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                            // isValidDate={this.validDobDate}
                                                                            input={true}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.HospitalROIData.relation_with_clients === 'Parent/legal guardian' || this.state.HospitalROIData.relation_with_clients === 'Personal representative' ?
                                                            (<div>
                                                                <strong><span>Parent/Guardian/Personal Representative (if applicable)</span></strong> <br />
                                                                <br />
                                                                <div className='row'>
                                                                    <div style={width_md_6} className='col-lg-6  col-md-6 form-group' >
                                                                        <LabelField  {...context.state.signature} /> {/*signature*/}
                                                                    </div>
                                                                    <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                        <input type="text" className="form-control" placeholder="Signature" name={context.state.fieldName.signature} id={context.state.fieldName.signature} defaultValue={this.state.HospitalROIData.signature === "undefined" ? "" : this.state.HospitalROIData.signature} required={this.state.mandatoryInfo.signature} onChange={this.handleRadioChange} />
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                        <LabelField  {...context.state.date} /> (MM/DD/YYYY) {/*date*/}
                                                                    </div>
                                                                    <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                        <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                            <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                        </div>
                                                                        <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                            <span style={{ width: '100%' }}>
                                                                                <DateTime
                                                                                    onBlur={(date) => this.handleTimeronBlur(date, 'date1')}
                                                                                    onChange={(date) => this.handleTimer(date, 'date1')}
                                                                                    inputProps={{ value: typeof context.state.HospitalROIData.date1 === 'string' ? (context.state.HospitalROIData.date1==="Invalid date"?"":context.state.HospitalROIData.date1): moment(context.state.HospitalROIData.date1).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.date1, name: 'date', placeholder: 'Date' }}
                                                                                    defaultValue={moment(this.state.HospitalROIData.date1).format('MM/DD/YYYY')}
                                                                                    dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                                    // isValidDate={this.validDobDate}
                                                                                    input={true}
                                                                                />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>) : ''}
                                                        <div className='row'>
                                                            <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                <LabelField {...context.state.staff_signature} /> {/* satff signature*/}
                                                            </div>
                                                            <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <input type="text" className="form-control" placeholder="Staff Signature" name={context.state.fieldName.staff_signature} id={context.state.fieldName.staff_signature} defaultValue={this.state.HospitalROIData.staff_signature==="null"?"":this.state.HospitalROIData.staff_signature} required={this.state.mandatoryInfo.staff_signature} onChange={this.handleRadioChange} />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                <LabelField  {...context.state.date} /> (MM/DD/YYYY){/*date*/}
                                                            </div>
                                                            <div style={width_md_6} className='col-lg-6  col-md-6 form-group'>
                                                                <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                    <span style={{ width: '100%' }}>
                                                                        <DateTime
                                                                            onBlur={(date) => this.handleTimeronBlur(date, 'date2')}
                                                                            onChange={(date) => this.handleTimer(date, 'date2')}
                                                                            inputProps={{ value: typeof context.state.HospitalROIData.date2 === 'string' ? (context.state.HospitalROIData.date2==="Invalid date"?"":context.state.HospitalROIData.date2 ): moment.utc(context.state.HospitalROIData.date2).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.date2, name: 'date', placeholder: 'Date' }}
                                                                            defaultValue={moment.utc(this.state.HospitalROIData.date2).format('MM/DD/YYYY')}
                                                                            dateFormat="MM/DD/YYYY" timeFormat={false}
                                                                            // isValidDate={this.validDobDate}
                                                                            input={true}
                                                                        />
                                                                     
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    {this.state.print ? "" : (
                                                        <>
                                                            <div className="row  mt-2">
                                                                <div className="col-lg-12  col-md-12 form-group">
                                                                    <label><strong>Documents</strong></label>
                                                                </div>
                                                                <div className="col-lg-12  col-md-12 form-group app">
                                                                    <ReactDropzone onDrop={this.onDrop_docs.bind(this)} >
                                                                        {({ getRootProps, getInputProps }) => (
                                                                            <section >
                                                                                <div {...getRootProps()} className="dropzone">
                                                                                    <input {...getInputProps()} name="documents" />
                                                                                    <div className="card-text file-upload-message">
                                                                                        <i className="fas fa-cloud-upload-alt"></i>
                                                                                        <p>Drag and drop files here</p>
                                                                                    </div>
                                                                                </div>
                                                                            </section>
                                                                        )}
                                                                    </ReactDropzone>
                                                                    {this.state.HospitalROIData.documents !== null && this.state.HospitalROIData.documents !== '' && this.state.HospitalROIData.documents !== undefined ? (
                                                                        <aside className="mt-2">
                                                                            <strong>Selected File</strong>
                                                                            <ul>
                                                                                {Array.isArray(this.state.HospitalROIData.documents) ? this.state.HospitalROIData.documents.map(f => (
                                                                                    <li key={f.name}>
                                                                                        {f.name} - {f.size} bytes
                                                                                    </li>
                                                                                )) : this.state.HospitalROIData.documents}
                                                                            </ul>
                                                                        </aside>) : ""}
                                                                </div>

                                                                <div className="col-lg-12  col-md-12 form-group personal_docs_div" >
                                                                    <div className="personal_docs">
                                                                        <DataTable
                                                                            className='table_Data' 
                                                                            columns={this.state.DocumentsColumns}
                                                                            data={this.state.all_uploaded_documents}
                                                                            customStyles={global.customTableStyles}
                                                                            striped={true}
                                                                            pagination={this.state.all_uploaded_documents ? true : false}
                                                                            paginationComponentOptions={global.paginationComponentOptions}
                                                                            highlightOnHover
                                                                            pointerOnHover
                                                                            noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </>
                                                    )}
                                                    <div className="row edit_allowed">
                                                        <div className="col-lg-12  col-md-12 form-group" style={{ marginTop: "30px", border: "1px dashed #cec5c5", padding: "20px", backgroundColor: "#f2f2f2", fontSize: "11px" }}>
                                                            <strong>To Information Recipient:</strong><br />
                                                            This information has been disclosed to you from records protected by Federal Confidentiality Rules (42CFR Part 2) and HIPAA (45 CFR Parts 160 and 164). The Federal Rules prohibit you from making any further disclosure of this information unless further discloser is expressly permitted by the written consent of the person to whom it pertains or as otherwise permitted by 42CFR Part 2 and CFR Parts 160 and 164. A general authorization for release of medical or other information is not sufficient for this purpose. The Federal Rules restrict any use of the information to criminally investigate or prosecute any alcohol or drug Use client.
                                                        </div>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="justify-content-between" >
                                                            <Button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddHospitalROIModal} type="button" form="myForm" key="submit" htmlType="submit">Close</Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        {this.state.currentView === 'Revoke' || !can_edit ? "" : (
                                                            <button type="submit" disabled={this.state.disable} id="saveButton" className="btn btn-success float-right btnmargin" htmlType="submit" form="AuthorizationForm"> <i className="fas fa-save"></i> Save</button>
                                                        )}

                                                        <ReactToPrint documentTitle="Authorization Form" trigger={() =>
                                                            <button type="button" className="btn btn-primary float-right btnmargin">
                                                                <i className="fa fa-print" aria-hidden="true"></i>&nbsp;
                                                                Print
                                                            </button>}
                                                            onBeforeGetContent={() => this.enablePrintStyles()}
                                                            content={() => this.componentRef}
                                                            onAfterPrint={() => this.disablePrintStyles()}
                                                        />
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }

}

export default HOC(VerbalConsent);
