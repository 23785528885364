export function dataFormatter(rawData , structure , keys)
      {
        // console.log(rawData);
        var validationObj = [] ;
        

        rawData.map( (val) => {

            let innerValidationObj = {
                name : "",
                title : "",
                tooltip : "",
                fieldtype : "",
                options : [] ,
                columnLength : "",
                noeditable : "",
                invisible : "",
                mandatory : "",
                regex : "" 
            } ;

            if(val.childs === undefined )
            {
                innerValidationObj.title = val.title ;
                innerValidationObj.name = val.name ;
                innerValidationObj.tooltip = val.tooltip;
                innerValidationObj.options = val.options ;
                innerValidationObj.fieldtype = val.fieldtype ;
                innerValidationObj.columnLength = val.columnLength;
                innerValidationObj.noteditable = val.noteditable ;
                innerValidationObj.invisible = val.invisible ;
                innerValidationObj.mandatory = val.mandatory ;
                innerValidationObj.regex = val.regex ;

                validationObj[val.name] = innerValidationObj ;
                 
            }
            else
            {
                val.childs.map( (innerChild) => {

                    innerValidationObj.title = val.title ;
                    innerValidationObj.name = val.name ;
                    innerValidationObj.tooltip = val.tooltip;
                    innerValidationObj.options = val.options ;
                    innerValidationObj.fieldtype = val.fieldtype ;
                    innerValidationObj.columnLength = val.columnLength;
                    innerValidationObj.noteditable = val.noteditable ;
                    innerValidationObj.invisible = val.invisible ;
                    innerValidationObj.mandatory = val.mandatory ;
                    innerValidationObj.regex = val.regex ;
    
                    validationObj[innerChild.name] = innerValidationObj ;
                   
                });
            }  
        } );

        return validationObj ;
}

export function getFormattedDate( dateString, include_time = false )
    {
        console.log('dateString', dateString)
        if(dateString === '' || dateString === undefined || dateString === null  ){
            return '';
        }
        let formattedString = '' ;

            formattedString = dateString.getFullYear() + "-" ;

            if( dateString.getMonth() < 9 )
            {
                formattedString = formattedString + "0" + (dateString.getMonth() + 1 ) + "-" ;
            }
            else
            {
                formattedString = formattedString + dateString.getMonth() ;
            }

            if( dateString.getDate() < 10 )
            {
                formattedString = formattedString + "0" + dateString.getDate() ;
            }
            else
            {
                formattedString = formattedString + dateString.getDate() ;
            }

            if(include_time){
                if( dateString.getHours() < 10 )
                {
                    formattedString = formattedString + " 0" + dateString.getHours() ;
                }
                else
                {
                    formattedString = formattedString +" "+ dateString.getHours() ;
                }

                if( dateString.getMinutes() < 10 )
                {
                    formattedString = formattedString + ":0" + dateString.getMinutes();
                }
                else
                {
                    formattedString = formattedString +":"+ dateString.getMinutes();
                }
            }

            // console.log("formatted string : " , formattedString ) ;

        return formattedString ;
    }


export function convertDate(date = new Date(), format = '')
{   


    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]
    
    date= new Date(date);
    var dd = date.getDate(); 
    var mm = date.getMonth() + 1; 

    var yyyy = date.getFullYear(); 
    var H = date.getHours();
    var i = date.getMinutes();

    if (dd < 10) { 
        dd = '0' + dd; 
    } 
    if (mm < 10) { 
        mm = '0' + mm; 
    } 

    

    if (H < 10) { 
        H = '0' + H; 
    } 

    if (i < 10) { 
        i = '0' + i; 
    } 

    var month = date.getMonth();
    
    return format.replace(/DD/, dd).replace(/MM/, mm).replace(/YYYY/, yyyy).replace(/H/, H).replace(/i/, i).replace(/M/, month ? months[month].substring(0, 3) : '');

}