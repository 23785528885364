import axios from 'axios';
import { ka } from 'date-fns/locale';
import React, { Component } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import "./PrintAssesment.css"

export default class PrintScreening extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screeningData: [
                {
                    last_name: "",
                    first_name: "",
                    middle_initial: "",
                    alias: "",
                    dob: "",
                    age: "",
                    gender: "",
                    ssn: "",
                    medicaid: "",
                    race: [],
                    ethnicity: "",
                    address_lns: "",
                    city_lns: "",
                    state_lns: "",
                    county_lns: "",
                    zipcode_lns: "",

                    primary_phone: "",
                    secondary_phone: "",
                    name_primary: "",
                    phone_primary: "",
                    relationship_primary: "",
                    primary_email: "",
                    important_thing: "",

                    presenting_problem_explain: "",
                    marriage_period: "",
                    number_of_marriages: "",

                    language_preference: "",
                    linguistic_status: "",
                    hearing_status: "",
                    military_status: "",

                    primary_referral: "",
                    other_referral_source: "",
                    referral_reason: "",

                    princepal_source_of_income: "",
                    other_income_place: "",
                    family_annual_income: "",

                    company_name: "",
                    policy_number: "",
                    group_number: "",
                    payment_source: "",

                    special_population: "",
                    other_priority_population: "",
                    priority_population: "",

                    presenting_problems: [],
                    secondary_referral: [],
                    insurance_type: "",
                    priorityPopulationOption: "",
                    // maritial_status: "",

                    uncope_score: "",
                    form_type: "uncopeScreening",

                    questions: [],

                    uncopeQuestions: [
                        {
                            question:
                                "1. In the past year, have you ever drank or used drugs more than you meant to? ¹˒²",
                            answer: "",
                        },
                        {
                            question:
                                "2. Have you ever neglected some of your usual responsibilities because of alcohol or drugs? ²",
                            answer: "",
                        },
                        {
                            question:
                                "3. Have you felt you wanted or needed to cut down on your drinking or drug use in the last year? ¹˒²",
                            answer: "",
                        },
                        {
                            question:
                                "4. Has anyone objected to your drinking or drug use? ³˒¹ Or has your family, friend, or anyone else ever told you they objected to alcohol or drug use?",
                            answer: "",
                        },
                        {
                            question:
                                "5. Have you ever found yourself preoccupied with wanting to use alcohol or drugs? ² Or have you found yourself thinking a lot about drinking or using?",
                            answer: "",
                        },
                        {
                            question:
                                "6. Have you ever used alcohol or drugs to relieve emotional discomfort, such as sadness, anger or boredom? ²˒¹",
                            answer: "",
                        },
                    ],

                    craftQuestions: [
                        {
                            question:
                                "1. Have you ever ridden in a car driven by someone (including yourself) who was high or had been using alcohol or drugs?",
                            answer: "",
                        },
                        {
                            question:
                                "2. Do you ever use alcohol or drugs to relax, feel better about yourself, or fit in?",
                            answer: "",
                        },
                        {
                            question:
                                "3. Do you ever use alcohol or drugs while you are by yourself or alone?",
                            answer: "",
                        },
                        {
                            question:
                                "4. Do you ever forget things you did while using alcohol or drugs?",
                            answer: "",
                        },
                        {
                            question:
                                "5. Do your family or friends ever tell you that you should cut down on your drinking or drug use? ",
                            answer: "",
                        },
                        {
                            question:
                                "6. Have you ever gotten into trouble while you were using alcohol or drugs?",
                            answer: "",
                        },
                    ],

                    isChecked_same_home_number: false,
                    otherPresentingProblem: "",
                    maritial_status: "",

                },

            ],
            dataListBody: {
                apikey: localStorage.getItem("__TOKEN__"),
                client_id: localStorage.getItem("clientId"),
            },
        }
    }
    referralSource = [
        {
            value: "A_and_or_D_Treatment_Inpatient_Res",
            label: "A and/or D Treatment, Inpatient Res",
        },
        {
            value: "A_and_or_D_Treatment_Not_Inpatient",
            label: "A and/or D Treatment, Not Inpatient",
        },
        { value: "Clergy", label: "Clergy" },
        {
            value: "Court_Correctional_Agency",
            label: "Court / Correctional Agency",
        },
        { value: "dhr", label: "DHR" },
        {
            value: "Diversionary_Program_TASC",
            label: "Diversionary Program / TASC",
        },
        { value: "DUI_DWI", label: "DUI / DWI" },
        { value: "Educational_Agency", label: "Educational Agency" },
        { value: "Employer_EAP", label: "Employer / EAP" },
        { value: "Family", label: "Family" },
        {
            value: "Formal_Adjudication_Process",
            label: "Formal Adjudication Process",
        },
        { value: "Friend", label: "Friend" },
        {
            value: "General_Psychiatric_Hospital",
            label: "General / Psychiatric Hospital",
        },
        { value: "Guardian", label: "Guardian" },
        { value: "ID_310_Program", label: "ID 310 Program" },
        { value: "ID_arc", label: "ID ARC" },
        { value: "ID_Regional_Office", label: "ID Regional Office" },
        { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
        { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
        {
            value: "Nursing_Home_Extended_Care",
            label: "Nursing Home / Extended Care",
        },
        { value: "Parent", label: "Parent" },
        { value: "Partial_Day_Organization", label: "Partial Day Organization" },
        {
            value: "Personal_Care_Boarding_Home",
            label: "Personal Care/Boarding Home",
        },
        { value: "Physician", label: "Physician" },
        { value: "Police", label: "Police" },
        { value: "Prison", label: "Prison" },
        { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
        { value: "Probation_Parole", label: "Probation / Parole" },
        { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
        { value: "School_System", label: "School System" },
        { value: "Self", label: "Self" },
        { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
        { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
        { value: "Spouse", label: "Spouse" },
        {
            value: "State_County_Psych_Hospital",
            label: "State / County Psych Hospital",
        },
        { value: "State_Federal_Court", label: "State / Federal Court" },
        { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
        { value: "If_Other_Specify_below", label: "Other" },
    ];
    sourceOfPayment_Option = [
        { value: "blue_cross", label: "Blue Cross/Blue Shield" },
        { value: "dmh", label: "DMH" },
        { value: "health_ins", label: "Health Ins (Not BCBS)" },
        { value: "medicaid", label: "Medicaid" },
        { value: "medicare", label: "Medicare" },
        { value: "no_charge", label: "No Charge (free...)" },
        { value: "other_gov_payments", label: "Other Gov. Payments" },
        { value: "personal_resourses", label: "Personal Resources (Self/Family)" },
        { value: "service_contract", label: "Service Contract (EAP,HMO etc)" },
        { value: "workers_compensation", label: "Workers Compensation" },
    ];
    priorityPopulation = [
        { value: "pregnant_IV_user", label: "Pregnant IV User" },
        { value: "pregnant_user", label: "Pregnant User" },
        { value: "iv_user", label: "IV User" },
        { value: "women_w/dependent_children", label: "Women w/dependent Children", },
        { value: "hiv_positive_individuals_for_methadone_providers_only", label: "HIV Positive Individuals (For Methadone Providers Only)", },
        { value: "not_applicable", label: "Not Applicable" },
        { value: "other", label: "Other" },
    ];

    specialPopulation = [
        { value: "IV_Drug_User", label: "IV Drug User" },
        { value: "Pregnant_Women", label: "Pregnant Women" },
        { value: "Women_w_dependent_Child", label: "Women w/dependent Child" },
        { value: "Not_Applicable", label: "Not Applicable" },
    ];
    insurancetype = [
        { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
        { value: "Health_Maintenance_Organization_(HMO)", label: "Health Maintenance Organization (HMO)", },
        { value: "Medicaid", label: "Medicaid" },
        { value: "Medicare", label: "Medicare" },
        { value: "Private_Insurance", label: "Private Insurance" },
        { value: "Workers_Compensation", label: "Workers Compensation" },
        { value: "Unknown", label: "Unknown" },
        { value: "None", label: "None" },
        { value: "Other", label: "Other" },
    ];


    componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        let res = await axios.post(global.restApiURL + "customRest/getScreeningPdf", JSON.stringify(this.state.dataListBody));
        console.log("screeningData is : ", this.state.screeningData)
        // JSON.parse(res.data.data[0].questions).map((value)=>{
        //     console.log( value)
        // })
        this.setState({
            screeningData: res.data.data.map((val) => {
                return (
                    {
                        last_name: val.last_name,
                        first_name: val.first_name,
                        middle_initial: val.middle_initial,
                        alias: val.alias,
                        dob: val.dob,
                        age: val.age,
                        gender: val.gender,
                        ssn: val.ssn,
                        medicaid: val.medicaid,
                        // race:val.race,    
                        race: val.race.length > 0 ? JSON.parse(val.race).map((value) => { return ({ value: value.value, label: value.label }) }) : [],
                        ethnicity: val.ethnicity,
                        address_lns: val.address_lns,
                        city_lns: val.city_lns,
                        state_lns: val.state_lns,
                        county_lns: val.county_lns,
                        zipcode_lns: val.zipcode_lns,

                        primary_phone: val.primary_phone,
                        secondary_phone: val.secondary_phone,
                        name_primary: val.name_primary,
                        phone_primary: val.phone_primary,
                        relationship_primary: val.relationship_primary,
                        primary_email: val.primary_email,
                        important_thing: val.important_thing,

                        presenting_problem_explain: val.presenting_problem_explain,
                        marriage_period: val.marriage_period,
                        number_of_marriages: val.number_of_marriages,

                        language_preference: val.language_preference,
                        linguistic_status: val.linguistic_status,
                        hearing_status: val.hearing_status,
                        military_status: val.military_status,

                        primary_referral: val.primary_referral,
                        other_referral_source: val.other_referral_source,
                        referral_reason: val.referral_reason,

                        princepal_source_of_income: val.princepal_source_of_income,
                        other_income_place: val.other_income_place,
                        family_annual_income: val.family_annual_income,

                        company_name: val.company_name,
                        policy_number: val.policy_number,
                        group_number: val.group_number,
                        payment_source: val.payment_source,

                        special_population: val.special_population,
                        other_priority_population: val.other_priority_population,
                        priority_population: val.priority_population,

                        // presenting_problems: [],
                        presenting_problems: val.presenting_problems.length > 0 ? JSON.parse(val.presenting_problems).map((value) => { return ({ value: value.value, label: value.label }) }) : [],

                        secondary_referral: val.secondary_referral_list.length > 0 ? JSON.parse(val.secondary_referral_list).map((value) => { return ({ value: value.value, label: value.label }) }) : [],
                        insurance_type: val.insurance_type,
                        priorityPopulationOption: val.priorityPopulationOption,
                        // maritial_status: "",

                        uncope_score: val.uncope_score,
                        form_type: val.form_type,

                        // uncopeQuestions:[
                        //     {
                        //         question:
                        //             "1. In the past year, have you ever drank or used drugs more than you meant to? ¹˒²",
                        //         answer: "no",
                        //     },
                        //     {
                        //         question:
                        //             "2. Have you ever neglected some of your usual responsibilities because of alcohol or drugs? ²",
                        //         answer: "no",
                        //     },
                        //     {
                        //         question:
                        //             "3. Have you felt you wanted or needed to cut down on your drinking or drug use in the last year? ¹˒²",
                        //         answer: "no",
                        //     },
                        //     {
                        //         question:
                        //             "4. Has anyone objected to your drinking or drug use? ³˒¹ Or has your family, friend, or anyone else ever told you they objected to alcohol or drug use?",
                        //         answer: "no",
                        //     },
                        //     {
                        //         question:
                        //             "5. Have you ever found yourself preoccupied with wanting to use alcohol or drugs? ² Or have you found yourself thinking a lot about drinking or using?",
                        //         answer: "no",
                        //     },
                        //     {
                        //         question:
                        //             "6. Have you ever used alcohol or drugs to relieve emotional discomfort, such as sadness, anger or boredom? ²˒¹",
                        //         answer: "no",
                        //     },
                        // ],

                        // craftQuestions:[
                        //     {
                        //         question:
                        //             "1. Have you ever ridden in a car driven by someone (including yourself) who was high or had been using alcohol or drugs?",
                        //         answer: "yes",
                        //     },
                        //     {
                        //         question:
                        //             "2. Do you ever use alcohol or drugs to relax, feel better about yourself, or fit in?",
                        //         answer: "yes",
                        //     },
                        //     {
                        //         question:
                        //             "3. Do you ever use alcohol or drugs while you are by yourself or alone?",
                        //         answer: "yes",
                        //     },
                        //     {
                        //         question:
                        //             "4. Do you ever forget things you did while using alcohol or drugs?",
                        //         answer: "",
                        //     },
                        //     {
                        //         question:
                        //             "5. Do your family or friends ever tell you that you should cut down on your drinking or drug use? ",
                        //         answer: "",
                        //     },
                        //     {
                        //         question:
                        //             "6. Have you ever gotten into trouble while you were using alcohol or drugs?",
                        //         answer: "",
                        //     },
                        // ],
                        questions: val.questions.length > 0 ? JSON.parse(val.questions).map((ques, index) => { return { question: ques.question, answer: ques.answer } }
                        ) : [],


                        isChecked_same_home_number: false,
                        // presenting_problems:val.presenting_problems,
                        otherPresentingProblem: val.otherPresentingProblem,
                        maritial_status: val.maritial_status,

                    }
                )
            })
        })

        console.log("screeningData is : ", this.state.screeningData)
    }
    render() {
        return <>
            {this.state.screeningData.map((val, index) => {

                return (
                    <>

                        <div className=" mx-1" >
                            {
                                index == 0 ? ("") : <div style={{ pageBreakBefore: 'always' }}></div>
                            }
                            <div>
                                <div className="row">
                                    <div className="col-12 mx-auto">
                                        <h3 className='text-green' style={{ padding: "10px 0px 10px 0px", textAlign: "center" }}>{val.form_type == "uncopeScreening" ? "UNCOPE Screening" : "CRAFFT Screening"}</h3>
                                    </div>
                                </div>
                                <hr />
                                <div >
                                    <>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Last Name</label>
                                                    <input type="text" disabled name="last_name" value={val.last_name} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>First Name</label>
                                                    <input type="text" disabled name="first_name" value={val.first_name} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>MI</label>
                                                    <input type="text" disabled name="middle_initial" value={val.middle_initial} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Alias 1</label>
                                                    <input type="text" disabled name="alias" value={val.alias} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Date of Birth</label>
                                                    <input type="text" disabled name="dob" value={val.dob} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Age</label>
                                                    <input type="text" disabled name="age" value={val.age} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Sex</label>
                                                    <input type="text" disabled name="gender" value={val.gender} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>SSN</label>
                                                    <input type="text" disabled id="ssn" name="ssn" className="form-control" value={`***-**-` + val.ssn.substr(val.ssn.length - 4)} />

                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Medicaid #</label>
                                                    <input type="text" disabled name="medicaid" value={val.medicaid} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Race </label>
                                                    <div className="form-control heightRemove PdfBackgroundColor"  name="race" style={{ backgroundColor: "#e9ecef" }}>{val.race.map((label) => label.label).join(", ")}</div>
                                                    {/* <TextareaAutosize rows={3} name="race" className="form-control" disabled value={val.race.map((label) => label.label).join(", ")}
                                                        style={{ overflow: "hidden" }}
                                                    /> */}
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Ethnicity</label>
                                                    <select className="form-control" name="ethnicity" value={val.ethnicity} disabled  >
                                                        <option value=""></option>
                                                        <option value="cuban">Cuban</option>
                                                        <option value="hispanic_specific_Origin_not_specified">Hispanic-Specific Origin not specified</option>
                                                        <option value="mexican">Mexican</option>
                                                        <option value="not_of_hispanic_orgin">Not of Hispanic Origin</option>
                                                        <option value="other_specific_hispanic">Other Specific Hispanic</option>
                                                        <option value="puerto_rican">Puerto Rican</option>
                                                        <option value="unknown">Unknown</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <div className="form-control heightRemove PdfBackgroundColor" name="address_lns" style={{ backgroundColor: "#e9ecef" }}>{val.address_lns}</div>
                                                    {/* <TextareaAutosize rows={3} disabled name="address_lns" className="form-control" value={val.address_lns} style={{ overflow: "hidden" }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" disabled name="city_lns" className="form-control" value={val.city_lns} />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>State</label>
                                                    <input type="text" disabled name="state_lns" className="form-control" value={val.state_lns} />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>County</label>
                                                    <input type="text" disabled name="county_lns" className="form-control" value={val.county_lns} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Zip Code</label>
                                                    <input type="text" disabled name="zipcode_lns" className="form-control" value={val.zipcode_lns} />
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Home Phone Number</label>
                                                    <input type="text" disabled name="primary_phone" value={val.primary_phone} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Cell Phone Number</label>
                                                    <input
                                                        type="text" name="secondary_phone" disabled
                                                        className="form-control"
                                                        value={!val.isChecked_same_home_number ? val.secondary_phone : val.primary_phone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label> Emergency Contact Name </label>
                                                    <input type="text" disabled name="name_primary" value={val.name_primary} className="form-control" />
                                                </div>
                                            </div>

                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Emergency Contact Number </label>
                                                    <input type="text" disabled name="phone_primary" value={val.phone_primary} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Relationship</label>
                                                    <input
                                                        type="text"
                                                        name="relationship_primary" disabled
                                                        className="form-control" value={val.relationship_primary}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label>Emergency Email</label>
                                                    <input
                                                        type="text"
                                                        name="primary_email" disabled
                                                        className="form-control"
                                                        value={
                                                            val.primary_email
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                                <hr />
                                <div className="marginBottom1">
                                    <div className="row">
                                        <div className="col-12">
                                            What is the most important thing you want that
                                            made you contact us?
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <div className="form-control heightRemove PdfBackgroundColor" name="important_thing"  style={{ backgroundColor: "#e9ecef" }}>{val.important_thing}</div>
                                            {/* <TextareaAutosize 
                                                name="important_thing" 
                                                className="form-control" 
                                                disabled 
                                                value={val.important_thing}
                                                minRows={(val.important_thing?.length / 125) + val.important_thing?.split(/\r\n|\r|\n/).length}
                                                style={{ overflow: "hidden" ,textAlign: "justify"}} 
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-12">
                                            <label>Presenting Problems:</label>
                                            <div className="form-control heightRemove PdfBackgroundColor" name="presenting_problems" style={{ backgroundColor: "#e9ecef" }}>{val.presenting_problems.map((label) => label.label).join(", ")}</div>
                                            {/* <TextareaAutosize name="presenting_problems" className="form-control" disabled value={val.presenting_problems.map((label) => label.label).join(", ")} style={{ overflow: "hidden" }} /> */}

                                        </div>
                                    </div>
                                    {val.otherPresentingProblem && (
                                        <div className="row">
                                            <div className="col-12">
                                            <div className="form-control heightRemove PdfBackgroundColor"  name="presenting_problem_explain" style={{ backgroundColor: "#e9ecef" }}>{val.presenting_problem_explain}</div>
                                                {/* <TextareaAutosize
                                                    rows={1}
                                                    name="presenting_problem_explain" 
                                                    disabled
                                                    value={val.presenting_problem_explain}
                                                    className="form-control"
                                                    style={{ overflow: "hidden" }}
                                                /> */}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <hr />

                                {/* <div className="row"> */}
                                <div className='row'>
                                    <div className={val.maritial_status == "married" ? "col-4" : "col-6"}>
                                        <div className="form-group">
                                            <label>Marital Status</label>
                                            <select className="form-control" name="maritial_status" value={val.maritial_status} disabled  >
                                                <option value=""></option>
                                                <option value="Common Law">Common Law</option>
                                                <option value="Single">Single</option>
                                                <option value="Living Together">Living Together</option>
                                                <option value="Married">Married</option>
                                                <option value="Separated">Separated</option>
                                                <option value="Divorced">Divorced</option>
                                                <option value="Widowed">Widowed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {val.maritial_status ==
                                        "married" && (
                                            <>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label>If married how long?</label>
                                                        <input
                                                            type="text" disabled
                                                            className="form-control"
                                                            name="marriage_period"
                                                            value={val.marriage_period}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label>Total Number of Marriages</label>
                                                        <input
                                                            type="text" disabled
                                                            className="form-control"
                                                            name="number_of_marriages"
                                                            value={val.number_of_marriages}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </div>
                                {/* </div> */}
                                <hr />
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Language Preference&nbsp;&nbsp;&nbsp; </label>
                                            <select className="form-control" name="language_preference" value={val.language_preference} disabled  >
                                                <option value=""></option>
                                                <option value="english">English</option>
                                                <option value="american_sign_language">American Sign Language</option>
                                                <option value="arabic">Arabic</option>
                                                <option value="chinese">Chinese</option>
                                                <option value="french_and_french_creole">French And French Creole(Including Cajun)  </option>
                                                <option value="german">German</option>
                                                <option value="korean">Korean</option>
                                                <option value="hindu">Hindu</option>
                                                <option value="italian">Italian</option>
                                                <option value="japanese">Japanese</option>
                                                <option value="laotian">Laotian</option>
                                                <option value="other_african_languages">Other African Languages</option>
                                                <option value="other_asian_languages">Other Asian Languages,</option>
                                                <option value="other_europian_languages">Other Europian Languages</option>
                                                <option value="other_indic_languages">Other Indic Languages</option>
                                                <option value="persian">Persian</option>
                                                <option value="portuguese">Portuguese</option>
                                                <option value="russian">Russian</option>
                                                <option value="spanish_and_spanish_creole">Spanish And Spanish Creole</option>
                                                <option value="tagalog">Tagalog</option>
                                                <option value="vietnamese">Vietnamase</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Linguistic Status</label>
                                            <select className="form-control" name="linguistic_status" value={val.linguistic_status} disabled  >
                                                <option value=""></option>
                                                <option value="cognitive_disability">Cognitive Disability</option>
                                                <option value="english_proficiency">English Proficiency</option>
                                                <option value="limited_english_proficiency">Limited English Proficiency</option>
                                                <option value="low_literacy_level">Low Literacy Level</option>
                                                <option value="not_literate">Not Literate  </option>
                                                <option value="other_disability">Other Disability  </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Hearing Status</label>
                                            <select className="form-control" name="hearing_status" value={val.hearing_status} disabled  >
                                                <option value=""></option>
                                                <option value="hearing">Hearing</option>
                                                <option value="hard_of_hearing">Hard of Hearing</option>
                                                <option value="deaf">Deaf</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* {age >= 18 && <> */}
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Military Status</label>
                                            <select className="form-control" name="military_status" value={val.military_status} disabled  >
                                                <option value=""></option>
                                                <option value="active">Active</option>
                                                <option value="non_active_honorable_dis">Non-Active Honorable Dis</option>
                                                <option value="non_active_dishonorable_dis">Non-Active Dishonorable Dis</option>
                                                <option value="military_dependent">Military Dependent</option>
                                                <option value="na">NA</option>
                                            </select>

                                        </div>
                                    </div>
                                    {/* </>} */}
                                </div>
                                <hr />
                                <div style={{ marginTop: "2%" }}>
                                    <div className="row" >
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>
                                                    Which is the primary referral source?
                                                </label>
                                                {/* <input type="text" name="primary_referral" disabled value={val.primary_referral} className="form-control" /> */}
                                                <select className="form-control" name="primary_referral" value={val.primary_referral} disabled  >
                                                    <option value=""></option>
                                                    {this.referralSource.map((referal) => (
                                                        <option value={referal.value}>  {referal.label}  </option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    {val.primary_referral ==
                                        "If_Other_Specify_below" && (
                                            <div className="row" >
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>If other, specify</label>
                                                        <div className="form-control heightRemove PdfBackgroundColor" name="other_referral_source" style={{ backgroundColor: "#e9ecef" }}>{val.other_referral_source}</div>
                                                        {/* <TextareaAutosize className="form-control" name="other_referral_source" disabled value={val.other_referral_source} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="row" >
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Secondary referral source</label>
                                                <div className="form-control heightRemove PdfBackgroundColor" name="secondary_referral" style={{ backgroundColor: "#e9ecef" }}>{val.secondary_referral.map((value) => value.label).join(", ")}</div>
                                                {/* <TextareaAutosize className="form-control" name="secondary_referral" disabled value={val.secondary_referral.map((value) => value.label).join(", ")}
                                                    style={{ overflow: "hidden",textAlign:"justify" }} /> */}
                                            </div>
                                        </div>
                                    </div>


                                   
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <label>Reason for referral</label>
                                        <div className="form-control heightRemove PdfBackgroundColor" name="referral_reason" style={{ backgroundColor: "#e9ecef" }}>{val.referral_reason}</div>
                                        {/* <TextareaAutosize
                                            name="referral_reason" 
                                            disabled
                                            className="form-control p-2 p-md-3"
                                            value={val.referral_reason}
                                            style={{ overflow: "hidden" }}
                                        /> */}
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div className="col-12">
                                        <strong style={{ fontSize: "1rem" }}>
                                            Financial
                                        </strong>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            {val.form_type == "uncopeScreening" ? (
                                                <label>
                                                    I receive my principal source of income
                                                    from
                                                </label>
                                            ) : (
                                                <label>
                                                    I or my parents principal source of
                                                    income is
                                                </label>
                                            )}
                                            <select className="form-control" name="princepal_source_of_income" value={val.princepal_source_of_income} disabled>
                                                <option value=""></option>
                                                <option value="wages_salary">Wages/Salary</option>
                                                <option value="public_assistance"> Public Assistance</option>
                                                <option value="retirement_pension">Retirement/Pension</option>
                                                <option value="disablity">Disablity</option>
                                                <option value="none">None</option>
                                                <option value="other">Other</option>
                                                {val.form_type == "craftScreening" ? (<option value="unknown">Unknown</option>) : ""}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>
                                                What would you estimate your household
                                                annual income to be?
                                            </label>
                                            <input
                                                type="text"
                                                name="family_annual_income"
                                                className="form-control" disabled

                                                value={
                                                    val.family_annual_income
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>
                                {val.princepal_source_of_income == "other" && (
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>If other, specify</label>
                                                <div className="form-control heightRemove PdfBackgroundColor" name="other_income_place" style={{ backgroundColor: "#e9ecef" }}>{val.other_income_place}</div>
                                                {/* <TextareaAutosize
                                                        style={{overflow:"hidden",textAlign:"justify"}}
                                                        minRows={(val.other_income_place?.length / 59) + val.other_income_place?.split(/\r\n|\r|\n/).length}
                                                        name="other_income_place" 
                                                        disabled
                                                        className="form-control"
                                                        value={val.other_income_place}
                                                    /> */}
                                            </div>
                                        </div>

                                    </div>
                                )
                                }

                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div className="col-12">
                                        <strong style={{ fontSize: "1rem" }}>
                                            Health Insurance and Source of Payment
                                        </strong>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Insurance Type</label>
                                            {/* <input type="text" disabled name="insurance_type" value={val.insurance_type} className="form-control" /> */}
                                            <select name="insurance_type" className="form-control" value={val.insurance_type} disabled >
                                                <option value=""></option>
                                                {this.insurancetype.map((insurance) => (
                                                    <option value={insurance.value}>
                                                        {insurance.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Specify the name of company</label>
                                            <input
                                                type="text"
                                                name="company_name" disabled
                                                className="form-control"
                                                value={val.company_name}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Policy Number</label>
                                            <input
                                                type="text" disabled
                                                name="policy_number"
                                                value={val.policy_number}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>



                                    <div className="col-6">
                                        <div className="form-group">
                                            <label>Group Number</label>
                                            <input
                                                type="text"
                                                name="group_number" disabled
                                                value={val.group_number}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Source of Payment</label>
                                                {/* <input type="text" disabled name="payment_source" value={val.payment_source} className="form-control" /> */}
                                                <select className="form-control" name="payment_source" value={val.payment_source} disabled  >
                                                    <option value=""></option>
                                                    {this.sourceOfPayment_Option.map((val) => (
                                                        <option value={val.value}>  {val.label}  </option>
                                                    ))}
                                                </select>


                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                {val.form_type == "uncopeScreening" ? (
                                                    <>
                                                        <label>Priority Population</label>
                                                        <select className="form-control" name="priority_population" value={val.priority_population} disabled  >
                                                            <option value=""></option>
                                                            {this.priorityPopulation.map((val) => (
                                                                <option value={val.value}>  {val.label}  </option>
                                                            ))}
                                                        </select>

                                                    </>
                                                ) : (
                                                    <>
                                                        {" "}
                                                        <label>Special Population</label>
                                                        <select className="form-control" name="special_population" value={val.special_population} disabled  >
                                                            <option value=""></option>
                                                            {this.specialPopulation.map((val) => (
                                                                <option value={val.value}>  {val.label}  </option>
                                                            ))}
                                                        </select>

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {val.priority_population ==
                                        "other" && (
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label>If other, please explain</label>
                                                        <div className="form-control heightRemove PdfBackgroundColor"   name="other_priority_population" style={{ backgroundColor: "#e9ecef" }}>{val.other_priority_population}</div>
                                                        {/* <TextareaAutosize
                                                            className="form-control" disabled
                                                            rows={2}
                                                            name="other_priority_population"
                                                            value={val.other_priority_population}                                                            
                                                        /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                </div>

                                <div className="row" style={{ marginTop: "2%" }}>
                                    <div className="col-12">
                                        {/* {form_type=="uncopeScreening"?"UNCOPE Screening":""} */}
                                        {val.form_type == "uncopeScreening" ? (
                                            <strong style={{ fontSize: "1rem" }}>
                                                UNCOPE - Adult
                                            </strong>
                                        ) : (
                                            <strong style={{ fontSize: "1rem" }}>
                                                CRAFFT - Adolescent
                                            </strong>
                                        )}
                                    </div>
                                </div>
                                <hr />
                                <div className="row marginBottom1">
                                    <div className="col-12">
                                        <table className="table table-bordered table-striped table-center">
                                            <thead>
                                                <th></th>
                                                <th>Yes</th>
                                                <th>No</th>
                                            </thead>
                                            <tbody>
                                                {val.questions.map(
                                                    (question, index) => (
                                                        <tr>
                                                            <td>{question.question}  {question.answer}</td>
                                                            <td>
                                                                <div className="form-check">
                                                                    {question.answer.trim() == "yes" ? <>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={"checkbox" + index + val.form_type}
                                                                            value="yes"
                                                                            checked="true"
                                                                            disabled
                                                                        />
                                                                    </> : <>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={"checkbox" + index + val.form_type}
                                                                            value="yes"
                                                                            disabled

                                                                        />
                                                                    </>}

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-check">
                                                                    {question.answer.trim() == "no" ? <>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            value="no"
                                                                            name={"checkbox" + index + val.form_type}
                                                                            checked="true"
                                                                            disabled
                                                                        />
                                                                    </> : <>
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            value="no"
                                                                            name={"checkbox" + index + val.form_type}
                                                                            disabled

                                                                        /></>}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                                }



                                                <tr>
                                                    <td>
                                                        <strong className="float-right">
                                                            {val.form_type == "uncopeScreening"
                                                                ? "UNCOPE Score"
                                                                : "CRAFFT Score"}
                                                        </strong>
                                                    </td>
                                                    <td colSpan={2}>{val.uncope_score}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row marginBottom1">
                                    <div
                                        className="col-md-12"
                                        style={{
                                            border: "2px solid lightgray",
                                            background: "rgb(242, 242, 242)",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {val.form_type == "uncopeScreening" ? (
                                            <label>
                                                <b>Sources:</b>
                                                <br />
                                                1. Brown, R. L., Leonard, T., Saunders, L.
                                                A., & Papasouliotis, O. (1977). A two-item
                                                screening test for alcohol and other drug
                                                problems. Journal of FamilyPractice, 44,
                                                (2), 151-160.
                                                <br />
                                                2. Hoffman, N. G. & Harrison, P. A.
                                                (1995). SUDDS-IV: Substance Use Disorders
                                                Diagnostic Schedule, Smithfield, RI:
                                                Evince Assessments.
                                                <br />
                                                3. Hoffman, N. G. ((1995). TAAD: Triage
                                                Assessment for Addictive Disorders,
                                                Smithfield, RI: Evince Clinical
                                                Assessments.
                                            </label>
                                        ) : (
                                            <label>
                                                <b>Source:</b> Knight JR; Shrier LA;
                                                Bravender TD; Farrell M; Vander Bilt J;
                                                Shaffer HJ (1999) A new brief screen for
                                                adolescent substance use. Archives of
                                                Pediatrics and Adolescent Medicine Jun;
                                                153(6). 591-6.
                                            </label>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                )
            })}
        </>
    }
}