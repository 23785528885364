import $ from 'jquery';
import React, { Component } from 'react';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import { validationParser } from '../ReusableComponents/validationParser2';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import Topnav, { AppTopNav } from './Topnav';
//Tooltip imports
import TextAreaAutosize from 'react-textarea-autosize';
import Tooltip from '../ReusableComponents/Tooltip';
import { can_add, can_edit } from '../ReusableComponents/urlGenerator';
import { ConfirmBeforeLeave } from './ConfirmBeforeLeave';
import { encryptStorage } from './LocalStorageInterceptor';
import axios from 'axios';
import HOC from '../HOC';
import { showSuccessToast } from './toastService';

var validationObj = {} ;

var context ;


class Recoverygoals extends Component{
    constructor () {
        super();
        context = this ;
        this.state = {
            recoveryGoalData : this.getModal(),
            mandatoryInfo : this.getModal(),
            labelText : this.getModal(),
            tooltips : this.getModal(),
            tooltipsVisibility : this.getModal(),
            fieldName : this.getModal(),
            unSaved : false,
            goals :[{
                      category: "",
                      category_disable: false,
                      three_broad_goals: "",
                      three_broad_goals_disable: false,
                      how_life_will_different: "",
                      how_life_will_different_disable:false,
                      goal_accomplished:"",
                      categorySelectOptions : [],
                    },
                    {
                      category: "",
                      category_disable: false,
                      three_broad_goals: "",
                      three_broad_goals_disable: false,
                      how_life_will_different: "",
                      how_life_will_different_disable:false,
                      goal_accomplished:"",
                      categorySelectOptions : [],
                    },
                    {
                      category: "",
                      category_disable: false,
                      three_broad_goals: "",
                      three_broad_goals_disable: false,
                      how_life_will_different: "",
                      how_life_will_different_disable:false,
                      goal_accomplished:"",
                      categorySelectOptions : [],
                    }],
            //Select options             
        };
    }

    componentWillMount(){
        //for validation
        axios.get(global.restApiURL+'webservice/rest/class/id/15?apikey='+localStorage.getItem('__CL_TOKEN__')).then(res => {
            var response = res.data;   
            if(response.success && res.status==200){
                validationObj = validationParser(response.data.layoutDefinitions.children[0].children);
                this.setState({
                labelText : validationObj.title,
                mandatoryInfo : validationObj.mandatory,
                tooltips : validationObj.tooltip,
                tooltipsVisibility : validationObj.tooltipVisibility,
                fieldName : validationObj.name,
                // radioOptions : validationObj.options
                });

                 context.state.goals.map((item, idx) => {
                    context.state.goals[idx]['categorySelectOptions'] = context.createSelectOptions();
                });
                this.setState({goals : context.state.goals});
            }
            else if ( "error_code" in response ){
                alert( response.msg ) ;
                window.location.href = global.domain+global.traversArray['out'];
            }
            else {
                alert( response.msg ) ;
            }
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err); 
            });
        }

    parseRecoveryGoalsHistory(historyDataArray) {
        let parsedData = [] ;
        parsedData = historyDataArray.length > 0  && historyDataArray.map((item, i) => {
            var op = {} ;
            item.map((val) => {
                if(val.name === "category"){
                    op['categorySelectOptions'] = this.createSelectOptions(i, val.value);
                }
                if(val.name === 'goal_accomplished'){
                    if(val.value === true){
                        op['category_disable'] = true;
                        op['three_broad_goals_disable'] = true;
                        op['how_life_will_different_disable'] = true;
                    }else{
                        op['category_disable'] = false;
                        op['three_broad_goals_disable'] = false;
                        op['how_life_will_different_disable'] = false;
                    }
                }
               op[val.name] = val.value ;
            })
            return op ;
        }, this);
        return parsedData ;
    }

    componentDidMount(){
        const {navigate } =this.props
        var prevExist = false;
        localStorage.setItem('ObectID','');
        if(localStorage.getItem('clientId')!=='')
        {
            let getFollowupListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: localStorage.getItem('clientId'),
            } ;
            axios.post(global.restApiURL + 'customRest/getRecoveryGoalsList' , JSON.stringify(getFollowupListBody)).then(res => {
            if(res.data.success)
            {
                // const response = res.data.data.elements;
                if(res.data.data.length >  0){
                    setTimeout(function() { 
                    context.setState({
                        goals : context.parseRecoveryGoalsHistory(res.data.data)
                    });
                    }, 50)
                }
                // this.overallResponseCalculator();                    
            }else if ( "error_code" in res ){
                alert( res.msg ) ;
                window.location.href = global.domain+global.traversArray['out'];
            }else{
                alert( res.msg ) ;
            }
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err); 
            })
        }
        $.validator.setDefaults({
            submitHandler: function () {
                $('button[type="submit"]').attr("disabled", "disabled");
                $("#saveButton2").attr("disabled", true);
                $("#saveButton").attr("disabled", true);
            var jsonData ='';
            jsonData = {'className':'RecoveryGoals','key':localStorage.getItem('clientId'),'published':true, 'apikey' : localStorage.getItem('__TOKEN__')};
            jsonData.elements = [];
            $.each(context.state.goals, function(){
                delete this.categorySelectOptions;
                jsonData.elements.push(this);
            });
            var json =  JSON.stringify(jsonData);
                axios.post(global.save_recovery_goals, json)
                    .then(function (res) {      	    
                var response = res.data;
                console.log("save response" , response.success );
                if( response.success ){
                    showSuccessToast('Recovery Goals saved successfully.');
                    if($('#clicked').val()==='proceed'){
                        const route = `${global.traversArray["recoverygoals"]}`;
                        // alert(`${global.traversArray["recoverygoals"]}` + route);
                        navigate(route);
                    }else{   
                        context.setState({'unSaved' : false})
                        window.location.href ="";
                    }
                } else{
                    localStorage.setItem( 'success_msg' , "Something went wrong!" );
                    
                }
                })
                .catch(function (err) {
                    console.log(err.message);
                });
            }
            }
        );

    }

    getModal()
    {
        var recoverygoalsDataModal = {
            goal_during_recov_1 : '',
            other_goal_during_recov_1 : '',
            goal_during_recov_2 : '',
            other_goal_during_recov_2 : '',
            goal_during_recov_3 : '',
            other_goal_during_recov_3 : '',
            diffrnt_life_in_recov_1 : '',
            other_diffrnt_life_in_recov_1 : '',
            diffrnt_life_in_recov_2 : '',
            other_diffrnt_life_in_recov_2 : '',
            diffrnt_life_in_recov_3 : '',
            other_diffrnt_life_in_recov_3 : '',
        } ;

        return recoverygoalsDataModal ;
    }

    handleId = (e) => {
        $('#clicked').val(e.target.value);
        $('#clicked').val(e.currentTarget.value);
    }

    saveChanges(){
        //isko b dekhna hai
        $('#clicked').val('save');
        $('#recoverygoalsform').submit();
    }

createSelectOptions(i = 0, trueValue = ''){
      if(validationObj !== "" && validationObj?.options?.category)
      {
        return selectOptionsGenerator(validationObj?.options?.category , trueValue);
         //console.log("validation obj : " , goals ) ;
      }
  }

  handleChange = idx => e => {
    const { name, value } = e.target;
    const goals = [...this.state.goals];
    if(name === "goal_accomplished"){
        goals[idx][name]= e.target.checked;
        if(e.target.checked){
            goals[idx]['category_disable'] = true;
            goals[idx]['three_broad_goals_disable'] = true;
            goals[idx]['how_life_will_different_disable'] = true;
        }else{ 
            goals[idx]['category_disable'] = false;
            goals[idx]['three_broad_goals_disable'] = false;
            goals[idx]['how_life_will_different_disable'] = false;
        }
    }else{
        goals[idx][name]= value;
    }
    
    this.setState({
      goals,
      unSaved : true,
    });

    console.log(this.state.goals);
  };
  handleAddRow = () => {
    const item = {
      category: "",
      three_broad_goals: "",
      how_life_will_different: "",
      goal_accomplished:"",
      categorySelectOptions:this.createSelectOptions(),
    };
    this.setState({
      goals: [...this.state.goals, item]
    });
  };
  handleRemoveRow = () => {
    this.setState({
      goals: this.state.goals.slice(0, -1),
      unSaved : true
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const goals = [...this.state.goals]
    goals.splice(idx, 1)
    this.setState({ goals, unSaved : true })
  }
    render()
    {
        localStorage.setItem('formname','Recovery Goals');

     

        $(document).ready(function () {

      	
            $('#recoverygoalsform').validate({
            rules: {
            
            },	    
            errorElement: 'span',
            errorPlacement: function (error, element) {
                error.addClass('invalid-feedback');
                element.closest('.input-group').append(error);
            },
            highlight: function (element, errorClass, validClass) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid');
            }
            });
        });

        return(
            <div className="hold-transition sidebar-mini text-sm">
                { /*     Start Kuldeep Singh     This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> we are using sidenav By Kuldeep Singh            */ }
                {/* <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved = {context.state.unSaved} onSaveRequest = {() => context.saveChanges()} /> */}
                { /*          This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> By Kuldeep Singh     End Kuldeep Singh       */ }
  		        <div className="wrapper">
                    <AppTopNav />  
                    <div className="content-wrapper">	
	                    <Patientinfoheader clientId={localStorage.getItem('clientId')} dob={encryptStorage.getItem('dob') } email={encryptStorage.getItem('email') } phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={localStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name')+" "+encryptStorage.getItem('client_middle_initial')+" "+encryptStorage.getItem('client_last_name')}/>
                        <section className="content">
                            <form action="javascript:void(0);" method="post" id="recoverygoalsform">                
                                <div className="row">
                                    <Sidenav onSaveRequest = {() => context.saveChanges()} unSaved = {context.state.unSaved} />
                                    <fieldset disabled={!can_edit('RecoveryGoals')} className="container-fluid col-md-9 col-lg-10">
                                        <div className="card card-default">      
                                            <div className="card-header">
                                                <p className="lead float-left" style={{fontWeight: "300"}}><b className="text-green">Recovery Goals</b> <small> Capture client's recovery goals</small></p>
                                                <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}><img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>                                          
                                                <button type="submit" id="saveButton" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                                                <input type="hidden" id="clicked" value="" readOnly/>
                                                <input type="text" id="client_id" name="client_id" style={{display:"none"}} defaultValue={localStorage.getItem('clientId')}  />
                                            </div>
                                            <div className="card-body">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className="col-md-12 form-group">
                                                           {can_add('RecoveryGoals') ?  <button id="btnAddRow" type="button" className="btn btn-secondary btn-sm float-right" onClick={this.handleAddRow}><i className="fa fa-plus"></i> Add Goal</button>
                                                           : "" }
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <div className="table-responsive p-0 form-group">
                                                        <table className="table table-bordered table-striped" id="tblAddRow">
                                                            <thead>
                                                                <tr> 
                                                                    <td width="15%"><label style={{display:"inline"}}>{this.state.labelText.category}</label><Tooltip name="category" text={this.state.tooltips.category} visibiity={this.state.tooltipsVisibility.category}/></td>
                                                                    <td width="35%"><label style={{display:"inline"}}>{this.state.labelText.three_broad_goals}</label><Tooltip name="three_broad_goals" text={this.state.tooltips.three_broad_goals} visibiity={this.state.tooltipsVisibility.three_broad_goals}/></td>
                                                                    <td width="35%"><label style={{display:"inline"}}>{this.state.labelText.how_life_will_different}</label><Tooltip name="how_life_will_different" text={this.state.tooltips.how_life_will_different} visibiity={this.state.tooltipsVisibility.how_life_will_different}/></td>
                                                                    <td width="10%"><label style={{display:"inline"}}>{this.state.labelText.goal_accomplished}</label><Tooltip name="goal_accomplished" text={this.state.tooltips.goal_accomplished} visibiity={this.state.tooltipsVisibility.goal_accomplished}/></td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {this.state.goals.map((item, idx) => (
                                                                <tr id="addr0" key={idx}>
                                                                  <td>
                                                                    <select name={this.state.fieldName.category} 
                                                                            disabled={this.state.goals[idx].category_disable}
                                                                            className="custom-select" onChange={this.handleChange(idx)}>
                                                                        {this.state.goals[idx].categorySelectOptions}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <TextAreaAutosize 
                                                                        type="text" 
                                                                        readOnly={this.state.goals[idx].three_broad_goals_disable}
                                                                        className="form-control" 
                                                                        name={this.state.fieldName.three_broad_goals} 
                                                                        value={this.state.goals[idx].three_broad_goals}
                                                                        onChange={this.handleChange(idx)}
                                                                        style={{height:"38px"}}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextAreaAutosize 
                                                                        type="text"
                                                                        readOnly={this.state.goals[idx].how_life_will_different_disable}
                                                                        className="form-control" 
                                                                        name={this.state.fieldName.how_life_will_different} 
                                                                        value={this.state.goals[idx].how_life_will_different}
                                                                        onChange={this.handleChange(idx)}
                                                                        style={{height:"38px"}}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input 
                                                                        type="checkbox" 
                                                                        name={this.state.fieldName.goal_accomplished} 
                                                                        checked={this.state.goals[idx].goal_accomplished}
                                                                        disabled={this.state.goals[idx].goal_accomplished}
                                                                        onChange={this.handleChange(idx)}
                                                                    />
                                                                </td>
                                                                </tr>
                                                              ))}
                                                            </tbody>
                                                        </table>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer float-right" >
                                                <div className="float-right">
                                                    <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={this.handleId}><img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>                                          
                                                    <button type="submit" id="saveButton2" value='save' className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>               
                            </form>
                        </section>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default HOC(Recoverygoals) ;