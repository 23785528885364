import React from 'react';
import { useNavigate } from 'react-router-dom';

const HOC = (WrappedComponent) => {
  const WithNavigate = (props) => {
    const navigate = useNavigate();
    return <WrappedComponent {...props} navigate={navigate} />;
  };

  return WithNavigate;
};

export default HOC;
