import React, { Component } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "../Modals/Modal";
import { TextareaAutosize } from '@material-ui/core';
import axios from "axios";

export default class OtherDisorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [1],
      otherDisorder: [
        {
          disorder_name: "",
        },
      ],
      dataListBody: {
        apikey: localStorage.getItem("__TOKEN__"),
        client_id: localStorage.getItem("clientId"),
        form_type: props.form_type,
        uploaded_by: localStorage.getItem("username"),
      },
      deleteSubstance: false,
      change: false,
      save: true,
      isSaveAndComplete: false,
      // form_type:props.form_type,
    };
  }

  addRows = (e) => {
    e.preventDefault();
    this.setState({ save: false });
    const data = {
      disorder_name: "",
    };
    this.setState({ otherDisorder: [...this.state.otherDisorder, data] });
  };
  deleteRow = (e) => {
    let rows = this.state.otherDisorder;
    rows.splice([parseInt(e.target.id)], 1);
    this.setState({ otherDisorder: rows, deleteSubstance: false });
    this.setState({ save: false });
  };

  handleChange = (e) => {
    this.props.tabEdittedOrNot(true);
    this.props.saveAndExitSummary("", "", "", "", "", "", "", "", "", this.handleSubmit, "", "", "");
    this.setState({ change: true ,save:false});
    let otherDisorder = this.state.otherDisorder;
    otherDisorder[e.target.id][e.target.name] = e.target.value;
    this.setState({ otherDisorder });
    setTimeout(() => {
      this.props.childToParent(this.state.otherDisorder);
    }, 200);
  };
  deletePopUp = (e) => {
    this.setState({ deleteSubstance: true });
  };

  // +++++++++++++++++++++--HANDLE--SUBMIT--START--+++++++++++++++++++++++++++

  handleSubmit = async (e) => {
    e.preventDefault();
    let final_data = {
      otherDisorder: this.state.otherDisorder,
      dataListBody: this.state.dataListBody,
      // form_type:this.state.form_type,
    };
    let res = await axios.post(
      global.restApiURL + "customRest/saveOtherHealthDisorder",
      JSON.stringify(final_data)
    );
    if (res.data.success == true) {
      this.loadData();
      toast.success("Data added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "success_otherDisorder",
        theme: "colored",
      });
    }
    this.props.tabEdittedOrNot(false);
  };
  // +++++++++++++++++++++--HANDLE--SUBMIT--END--+++++++++++++++++++++++++++
  // ==========================*******************============================
  // ==========================*******************============================
  // +++++++++++++++++++++--LOAD--DATA--START--+++++++++++++++++++++++++++
  loadData = async (e) => {
    this.setState({ save: true });
    if (!this.props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customRest/getOtherHealthDisorderinfo",
        JSON.stringify(this.state.dataListBody)
      );
      if (res.status == 200 && res.data.success == true) {
        this.setState({
          otherDisorder: res.data.data.otherDisorder.map((val) => {
            return {
              disorder_name: val.disorder_name,
            };
          }),
        });
        setTimeout(() => {
          this.props.childToParent(this.state.otherDisorder);
        }, 200);
      }
    } else {
      let res = await axios.post(
        global.restApiURL + "customRest/getOtherHealthDisorderinfoTemp",
        JSON.stringify(this.state.dataListBody)
      );
      if (res.status == 200 && res.data.success == true) {
        this.setState({
          otherDisorder: res.data.data.otherDisorder.map((val) => {
            return {
              disorder_name: val.disorder_name,
            };
          }),
        });
        this.setState({ isSaveAndComplete: true });
      }
    }
  };

  // +++++++++++++++++++++--LOAD--DATA--END--+++++++++++++++++++++++++++
  // ==============================****************======================
  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--START--+++++++++++++++++++++++++++
  componentDidMount() {
    this.loadData();
  }

  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--END--+++++++++++++++++++++++++++

  render() {
    const tableRows = this.state.otherDisorder.map((row, index) => (
      <tr>
        <td>
          <input
            type="text"
            className="form-control"
            id={index}
            onChange={this.handleChange}
            name="disorder_name"
            value={row.disorder_name}
            disabled={this.state.isSaveAndComplete}
          />
        </td>
        <td>
          {index == this.state.otherDisorder.length - 1 ? (
            this.state.isSaveAndComplete ? (
              <>
                <i
                  className="fa fa-plus"
                  style={{ transform: "translateX(160%) translateY(45%)" }}
                  id={index}
                  disabled={true}
                ></i>
              </>
            ) : (
              <>
                <i
                  className="fa fa-plus"
                  style={{ transform: "translateX(160%) translateY(45%)" }}
                  id={index}
                  onClick={this.addRows}
                ></i>
              </>
            )
          ) : this.state.isSaveAndComplete ? (
            <>
              <i
                className="fa fa-trash float-right"
                style={{ transform: "translateX(-210%) translateY(45%)" }}
                id={index}
                //onClick={this.state.change ? this.deletePopUp : this.deleteRow}
                disabled={this.state.isSaveAndComplete}
              ></i>
            </>
          ) : (
            <>
              <i
                className="fa fa-trash float-right"
                style={{ transform: "translateX(-210%) translateY(45%)" }}
                id={index}
                onClick={this.state.change ? this.deletePopUp : this.deleteRow}
                // disabled={this.state.isSaveAndComplete}
              ></i>
            </>
          )}
        </td>
        {this.state.deleteSubstance && (
          <Modal
            showCloseButton={false}
            className="modal-sm"
            showOverlay={true}
          >
            <Modal.Header>
              <Modal.Title>
                <h4 className="modal-title float-left text-green">
                  Delete Substance
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this?</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-success float-left"
                id={index}
                onClick={this.deleteRow}
              >
                Yes
              </button>
              <button
                className="btn btn-default"
                onClick={() => this.setState({ deleteSubstance: false })}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </tr>
    ));
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="row marginBottom1 heightScroll">
                      <div className="col-md-12">
                        {/* <div className="card shadow-none"> */}
                        {/* <div className="card-header">
                                                        <p className="lead float-left">
                                                            <b className="text-green">
                                                                Other Health Disorders
                                                            </b>
                                                        </p>
                                                    </div> */}
                        <div className="card-body">
                          <table className="table table-bordered table-stripped">
                            <thead>
                              <th>Other Health Disorder (includes Medical)</th>
                              <th></th>
                            </thead>
                            <tbody>{tableRows}</tbody>
                          </table>
                        </div>
                        {/* </div> */}
                      </div>
                      <div className="col-md-12">
                        {/* <button className='btn btn-success float-right mr-2'><i class="fas fa-save"></i> Save</button> */}
                        <button
                          id="saveButton"
                          type="submit"
                          onClick={this.handleSubmit}
                          className="btn btn-success float-right mr-3"
                          htmlType="submit"
                          disabled={this.state.save}
                          // form="assessmentsForm"
                        >
                          {" "}
                          <i className="fas fa-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
