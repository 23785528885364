import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Multiselect } from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { geocodeByAddress } from 'react-places-autocomplete';
import Select from "react-select";
import TextareaAutosize from 'react-textarea-autosize';
import LocationSearchInput from '../Components/AddressAutocomplete';
import "../global.js";
import Footer from "./Footer";
import Loader from "./Loader";
import { Button } from "./Modals/Buttons/index";
import Modal from "./Modals/Modal";
import axios from 'axios';
import { Hidden } from '@material-ui/core';



export default function UncopeScrrening(props) {

  const { closeModal, age1, form_type, getScreeningList } = props;

  const [unSaved, setunSaved] = useState(true);
  const [maritial_status, setMaritalStatus] = useState("");
  // const [insurance_type, setInsuranceType] = useState("");
  const [uncope_score, setUncopeScore] = useState(0);
  const [age, setAge] = useState(props?.age);
  const [updateModal_uncope, setUpdateModal_uncope] = useState(props.updateModal_uncope);
  const [updateModal_craft, setUpdateModal_craft] = useState(props.updateModal_craft);

  const [priorityPopulationOption, setPriorityPopulationoption] = useState("");
  const [saveComplete, setsaveComplete] = useState(false);
  const [presenting_problems, setpresenting_problems] = useState([]);
  const [race, setRace] = useState([]);
  const [otherPresentingProblem, setOtherPresentingProblem] = useState(false);
  const [secondary_referral, setsecondary_referral] = useState([]);
  // const [princepal_source_of_income, setprincepal_source_of_income] = useState("");
  const [isSaveAndComplete, setisSaveAndComplete] = useState(props.saveComplete);
  const [creationDate, setcreationDate] = useState();
  const [isChecked_same_home_number, setisChecked_same_home_number] = useState(false);
  // const [selectedProblemList,setselectedProblemList]=useState( [  ]);
  const [errorMessage, seterrorMessage] = useState("");
  const [errorMessage1, seterrorMessage1] = useState("");
  const [errorMessage2, seterrorMessage2] = useState("");

  const [uncopeScreening, setuncopeScreening] = useState({
    last_name: "",
    first_name: "",
    middle_initial: "",
    alias: "",
    dob: "",
    age: "",
    gender: "",
    ssn: "",
    medicaid: "",
    ethnicity: "",
    // address_lns1: "",
    // city_lns: "",
    // state_lns: "",
    // county_lns: "",
    // zipcode_lns: "",

    primary_phone: "",
    secondary_phone: "",
    name_primary: "",
    phone_primary: "",
    relationship_primary: "",
    // emergency_primary_email: "",
    primary_email: "",
    // email_primary : "",
    important_thing: "",

    presenting_problem_explain: "",
    marriage_period: "",
    number_of_marriages: "",

    language_preference: "",
    linguistic_status: "",
    hearing_status: "",
    military_status: "",

    primary_referral: "",
    other_referral_source: "",
    referral_reason: "",

    princepal_source_of_income: "",
    other_income_place: "",
    family_annual_income: "",

    company_name: "",
    policy_number: "",
    group_number: "",
    payment_source: "",

    special_population: "",
    other_priority_population: "",

    // presenting_problems: [],
    secondary_referral_list: [],
    insurance_type: "",
    military_status: "",
    priorityPopulationOption: "",
    // maritial_status: "",
  });
  const [addressState, setAddressState] = useState({
    address_lns: "", city_lns: "", state_lns: "", county_lns: "", zipcode_lns: "",
  })
  const [issaveAndCompleting, setissaveAndCompleting] = useState(false);
  const [validation, setValidation] = useState({
    primary_phone: "",
    secondary_phone: "",
    phone_primary: "",

  })
  const [uncopeQuestions, setUncopeQuestions] = useState([
    {
      question:
        "1. In the past year, have you ever drank or used drugs more than you meant to? ¹˒²",
      answer: "",
    },
    {
      question:
        "2. Have you ever neglected some of your usual responsibilities because of alcohol or drugs? ²",
      answer: "",
    },
    {
      question:
        "3. Have you felt you wanted or needed to cut down on your drinking or drug use in the last year? ¹˒²",
      answer: "",
    },
    {
      question:
        "4. Has anyone objected to your drinking or drug use? ³˒¹ Or has your family, friend, or anyone else ever told you they objected to alcohol or drug use?",
      answer: "",
    },
    {
      question:
        "5. Have you ever found yourself preoccupied with wanting to use alcohol or drugs? ² Or have you found yourself thinking a lot about drinking or using?",
      answer: "",
    },
    {
      question:
        "6. Have you ever used alcohol or drugs to relieve emotional discomfort, such as sadness, anger or boredom? ²˒¹",
      answer: "",
    },
  ]);
  const [craftQuestions, setCraftQuestion] = useState([
    {
      question:
        "1. Have you ever ridden in a car driven by someone (including yourself) who was high or had been using alcohol or drugs?",
      answer: "",
    },
    {
      question:
        "2. Do you ever use alcohol or drugs to relax, feel better about yourself, or fit in?",
      answer: "",
    },
    {
      question:
        "3. Do you ever use alcohol or drugs while you are by yourself or alone?",
      answer: "",
    },
    {
      question:
        "4. Do you ever forget things you did while using alcohol or drugs?",
      answer: "",
    },
    {
      question:
        "5. Do your family or friends ever tell you that you should cut down on your drinking or drug use? ",
      answer: "",
    },
    {
      question:
        "6. Have you ever gotten into trouble while you were using alcohol or drugs?",
      answer: "",
    },
  ]);
  const [toggleSSN, settoggleSSN] = useState(true);
  let dataListBody = {
    apikey: localStorage.getItem("__TOKEN__"),
    client_id: localStorage.getItem("clientId"),
    created_by: localStorage.getItem("username"),
    form_type: form_type,
  };

  const [isLoaderShow, setisLoaderShow] = useState(false)
  useEffect(() => {

    let dob = localStorage.getItem("dob");
    // let is_filled = localStorage.getItem("is_filled");
    // let formType = localStorage.getItem("form_type");
    // is_filled == 1 || is_filled == null ? setUpdateModal(true) : setUpdateModal(false);
    // formType==undefined?setUpdateModal(false):setUpdateModal(true);
    // setUpdateModal(props.updateModal);

    let age = getAge(dob);
    setcreationDate(form_type == "uncopeScreening" ? localStorage.getItem("uncope_form_type_date") : localStorage.getItem("craft_form_type_date"));

    // this.state.form_type == "uncopeScreening" ? true : this.state.form_type == "craftScreening"?true:false
    loadData();
  }, []);

  const loadData = async () => {
    if (!isSaveAndComplete) {
      let getFollowupListBody = {
        apikey: localStorage.getItem("__TOKEN__"),
        client_id: localStorage.getItem("clientId"),
        limit: 1,
        form_type: form_type,
        created_by: localStorage.getItem("username"),
      };

      let response1 = await axios.post(global.restApiURL + "customRest/getUncopeScreeningInfo", JSON.stringify(getFollowupListBody));
      let response2 = await axios.post(global.restApiURL + "customRest/getuncofecraftscore", JSON.stringify(getFollowupListBody));
      console.log(response1.data);
      // if (response1.data.length > 0) {
      setMaritalStatus(response1.data.data.Uncopescreening.maritial_status);
      let jsonFormattedString_for_secendary_referral = response1.data.data.secondary_referral_list;
      console.log(response1.data.data.secondary_referral_list);
      if (jsonFormattedString_for_secendary_referral != "") {
        let arr_for_secendary_referral = JSON.parse(jsonFormattedString_for_secendary_referral);
        setsecondary_referral(arr_for_secendary_referral);
        // setunSaved(false);
      }
      setUncopeScore(response2.data.data.uncope_score);
      let jsonFormattedString = response1.data.data.presenting_problems;
      let arr = JSON.parse(jsonFormattedString);
      setuncopeScreening(response1.data.data.Uncopescreening);
      setAddressState(response1.data.data.addressState);
      setpresenting_problems(arr);

      // setRace()
      // response1.data.data.race?.length>0 ? setRace([]):[]
      if (response1.data.data.client_races != "") {
        let tempRace = JSON.parse(response1.data.data.client_races);
        setRace(tempRace);
      }

      let jsonFormattedString_for_uncopeQuestions = response2.data.data.uncopeQuestions;
      const length = JSON.parse(jsonFormattedString_for_uncopeQuestions).length;
      if (form_type == "uncopeScreening") {
        if (length != 0) {
          let final_question = JSON.parse(
            jsonFormattedString_for_uncopeQuestions
          );
          setUncopeQuestions(final_question);
        } else {
          // setUncopeQuestions(defaultUncopeQuestion);
        }
      } else {
        if (length != 0) {
          let final_question = JSON.parse(
            jsonFormattedString_for_uncopeQuestions
          );
          setCraftQuestion(final_question);
        } else {
          // setCraftQuestion(defaultCraftQuestion);
        }
      }
      // }
    } else {
      let getFollowupListBody = { dataListBody: dataListBody };
      let response1 = await axios.post(
        global.restApiURL + "customRest/getUncopeScreeningInfoTemp",
        JSON.stringify(getFollowupListBody)
      );
      let response2 = await axios.post(
        global.restApiURL + "customRest/getUncofeCraftScoreTemp",
        JSON.stringify(getFollowupListBody)
      );
      setMaritalStatus(response1.data.data.Uncopescreening.maritial_status);
      let jsonFormattedString_for_secendary_referral =
        response1.data.data.secondary_referral_list;

      if (jsonFormattedString_for_secendary_referral != "") {
        let arr_for_secendary_referral = JSON.parse(jsonFormattedString_for_secendary_referral);
        setsecondary_referral(arr_for_secendary_referral);
        // setunSaved(false);
      }
      setUncopeScore(response2.data.data.uncope_score);
      let jsonFormattedString = response1.data.data.presenting_problems;
      let arr = JSON.parse(jsonFormattedString);
      setuncopeScreening(response1.data.data.Uncopescreening);
      setAddressState(response1.data.data.addressState);
      setpresenting_problems(arr);

      if (response1.data.data.race != "") {
        let tempRace = JSON.parse(response1.data.data.race);
        setRace(tempRace);
      }

      let jsonFormattedString_for_uncopeQuestions = response2.data.data.uncopeQuestions;
      const length = JSON.parse(jsonFormattedString_for_uncopeQuestions).length;
      if (form_type == "uncopeScreening") {
        if (length != 0) {
          let final_question = JSON.parse(jsonFormattedString_for_uncopeQuestions);
          setUncopeQuestions(final_question);
        } else {
          // setUncopeQuestions(defaultUncopeQuestion);
        }
      } else {
        if (length != 0) {
          let final_question = JSON.parse(jsonFormattedString_for_uncopeQuestions);
          setCraftQuestion(final_question);
        } else {
          // setCraftQuestion(defaultCraftQuestion);
        }
      }
      setisSaveAndComplete(true)
    }
  };

  const saveChanges = () => {
    //isko b dekhna hai
    // $('#clicked').val('save');
    // $('#intakebasicinfoform').submit();
  };

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleSubmit = async (e) => {
    const { primary_phone, secondary_phone, phone_primary } = uncopeScreening
    let formattedPrimaryPhone = primary_phone;
    let formattedsecondary_phone = secondary_phone;
    let formattedphone_primary = phone_primary;
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericSecondaryPhone = secondary_phone.replace(/\D/g, '');
    const numericphone_primary = phone_primary.replace(/\D/g, '');
    //  Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedsecondary_phone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    if (numericphone_primary.length === 10) {
      formattedphone_primary = `(${numericphone_primary.substring(0, 3)}) ${numericphone_primary.substring(3, 6)}-${numericphone_primary.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) || formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
      e.preventDefault()
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        seterrorMessage('Please enter at least 10 digits.');
      }
      if (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) {
        e.preventDefault();
        seterrorMessage1('Please enter at least 10 digits.');
      }
      if (formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
        e.preventDefault();
        seterrorMessage2('Please enter at least 10 digits.');
      }
    } else {
      setunSaved(true);
      e.preventDefault();
      // debugger
      let unscore = { uncope_score: uncope_score };
      let presenting_problem = { presenting_problems: presenting_problems };
      let secondaryreferal_list = { secondary_referral_list: secondary_referral };
      let Uncope_Question = form_type == "uncopeScreening" ? { uncopeQuestions: uncopeQuestions } : { uncopeQuestions: craftQuestions };
      let final_data = { uncopeScreening, addressState, form_type, ...presenting_problem, ...secondaryreferal_list, race: race == null ? [] : race, dataListBody };
      let res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfo", JSON.stringify(final_data));
      let questionsData = { dataListBody, form_type, unscore, ...Uncope_Question };
      let res2 = await axios.post(global.restApiURL + "customRest/saveuncofecraftscore", JSON.stringify(questionsData));

      if (res.data.success == true && res2.status == 200) {

        toast.success('Data saved successfully!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
        getScreeningList();
        // loadData();
      } else {
        toast.error('Some thing going wrong...Please try again', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        getScreeningList();
        loadData();
      }
    }
  };

  const handleMultiSelectChanges = (selectedoption, e) => {
    // if (e.name == "presenting_problems") {
    //   selectedoption.length > 0 &&
    //     selectedoption.map((option) => {
    //       if (option.value == "other") {
    //         setOtherPresentingProblem(true);
    //       }
    //       presenting_problems.push(option.value);
    //     });
    //   setpresenting_problems(selectedoption);
    // }
    // if (e.name == "secondary_referral") {
    //   selectedoption.length > 0 &&
    //     selectedoption.map((option) => {
    //       if (option.value == "other") {
    //         setsecondary_referral(true);
    //       }
    //       secondary_referral.push(option.value);
    //     });
    //   setsecondary_referral(secondary_referral);
    //   setunSaved(false);
    // }

    setunSaved(false);
    if (e.name == "race") {
      setRace(selectedoption)
    }
    if (e.name == "presenting_problems") {
      setpresenting_problems(selectedoption);
    }
    if (e.name == "secondary_referral") {
      setsecondary_referral(selectedoption);
    }
  };

  const presenting_problemsOptions = [
    { value: "abuse_victim", label: "Abuse Victim" },
    { value: "Alcohol", label: "Alcohol" },
    { value: "assault_victim", label: "Assault Victim" },
    { value: "crimnal_justice", label: "Criminal Justice" },
    { value: "daily_coping", label: "Daily Coping" },
    { value: "dep/mood_disorder", label: "Dep/Mood Disorder" },
    { value: "drug", label: "Drug" },
    { value: "eating_disorder", label: "Eating Disorder" },
    { value: "family", label: "Family" },
    { value: "interpersonal", label: "Interpersonal" },
    { value: "marital", label: "Marital" },
    { value: "medical", label: "Medical" },
    { value: "rape_victim", label: "Rape Victim" },
    { value: "runaway_behavior", label: "Runaway Behavior" },
    { value: "social", label: "Social" },
    { value: "somatic", label: "Somatic" },
    { value: "sucidial", label: "Suicidal" },
    { value: "thought_disorder", label: "Thought Disorder" },
    { value: "none", label: "None" },
    { value: "other", label: "Other" },
  ];

  const referralSource = [
    {
      value: "A_and_or_D_Treatment_Inpatient_Res",
      label: "A and/or D Treatment, Inpatient Res",
    },
    {
      value: "A_and_or_D_Treatment_Not_Inpatient",
      label: "A and/or D Treatment, Not Inpatient",
    },
    { value: "Clergy", label: "Clergy" },
    {
      value: "Court_Correctional_Agency",
      label: "Court/Correctional Agency",
    },
    { value: "dhr", label: "DHR" },
    {
      value: "Diversionary_Program_TASC",
      label: "Diversionary Program/TASC",
    },
    { value: "DUI_DWI", label: "DUI/DWI" },
    { value: "Educational_Agency", label: "Educational Agency" },
    { value: "Employer_EAP", label: "Employer/EAP" },
    { value: "Family", label: "Family" },
    {
      value: "Formal_Adjudication_Process",
      label: "Formal Adjudication Process",
    },
    { value: "Friend", label: "Friend" },
    {
      value: "General_Psychiatric_Hospital",
      label: "General/Psychiatric Hospital",
    },
    { value: "Guardian", label: "Guardian" },
    { value: "ID_310_Program", label: "ID 310 Program" },
    { value: "ID_arc", label: "ID ARC" },
    { value: "ID_Regional_Office", label: "ID Regional Office" },
    { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
    { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
    {
      value: "Nursing_Home_Extended_Care",
      label: "Nursing Home/Extended Care",
    },
    { value: "Parent", label: "Parent" },
    { value: "Partial_Day_Organization", label: "Partial Day Organization" },
    {
      value: "Personal_Care_Boarding_Home",
      label: "Personal Care/Boarding Home",
    },
    { value: "Physician", label: "Physician" },
    { value: "Police", label: "Police" },
    { value: "Prison", label: "Prison" },
    { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
    { value: "Probation_Parole", label: "Probation/Parole" },
    { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
    { value: "School_System", label: "School System" },
    { value: "Self", label: "Self" },
    { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
    { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
    { value: "Spouse", label: "Spouse" },
    {
      value: "State_County_Psych_Hospital",
      label: "State/County Psych Hospital",
    },
    { value: "State_Federal_Court", label: "State/Federal Court" },
    { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
    { value: "If_Other_Specify_below", label: "Other" },
  ];

  const insurancetype = [
    { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
    { value: "Health_Maintenance_Organization_(HMO)", label: "Health Maintenance Organization (HMO)", },
    { value: "Medicaid", label: "Medicaid" },
    { value: "Medicare", label: "Medicare" },
    { value: "Private_Insurance", label: "Private Insurance" },
    { value: "Workers_Compensation", label: "Workers Compensation" },
    { value: "Unknown", label: "Unknown" },
    { value: "None", label: "None" },
    { value: "Other", label: "Other" },
  ];

  const priorityPopulation = [
    { value: "pregnant_IV_user", label: "Pregnant IV User" },
    { value: "pregnant_user", label: "Pregnant User" },
    { value: "iv_user", label: "IV User" },
    { value: "women_w/dependent_children", label: "Women w/dependent Children", },
    { value: "hiv_positive_individuals_for_methadone_providers_only", label: "HIV Positive Individuals (For Methadone Providers Only)", },
    { value: "not_applicable", label: "Not Applicable" },
    { value: "other", label: "Other" },
  ];

  const specialPopulation = [
    { value: "IV_Drug_User", label: "IV Drug User" },
    { value: "Pregnant_Women", label: "Pregnant Women" },
    { value: "Women_w_dependent_Child", label: "Women w/dependent Child" },
    { value: "Not_Applicable", label: "Not Applicable" },
  ];

  const sourceOfPayment_Option = [
    { value: "blue_cross", label: "Blue Cross/Blue Shield" },
    { value: "dmh", label: "DMH" },
    { value: "health_ins", label: "Health Ins (Not BCBS)" },
    { value: "medicaid", label: "Medicaid" },
    { value: "medicare", label: "Medicare" },
    { value: "no_charge", label: "No Charge (free...)" },
    { value: "other_gov_payments", label: "Other Gov. Payments" },
    { value: "personal_resourses", label: "Personal Resourses (Self/Family)" },
    { value: "service_contract", label: "Service Contract (EAP,HMO etc)" },
    { value: "workers_compensation", label: "Workers Compensation" },
  ];

  const linguisticOption = [
    { value: "cognitive_disability", label: "Cognitive Disability" },
    { value: "english_proficiency", label: "English Proficiency" },
    { value: "limited_english_proficiency", label: "Limited English Proficiency" },
    { value: "low_literacy_level", label: "Low Literacy Level" },
    { value: "not_literate", label: "Not Literate" },
    { value: "other_disability", label: "Other Disability" },
  ];
  const hearingOptions = [
    { value: "hearing", label: "Hearing" },
    { value: "hard_of_hearing", label: "Hard of Hearing" },
    { value: "deaf", label: "Deaf" },
  ];

  const raceOptions = [
    // { value: "alaskan_native", label: "Alaskan Native" },
    // { value: "american_indian", label: "American Indian" },
    // { value: "asian", label: "Asian" },
    // { value: "black_african_american", label: "Black/African American" },
    // { value: "caucasian_white", label: "Caucasian/White" },
    // { value: "multi_Racial", label: "Multi-Racial" },
    // { value: "native_hawaiian_other_pac_island", label: "Native Hawaiian/Other Pac Island" },
    // { value: "other_race", label: "Other Race" },
    { value: "Asian", label: "Asian" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "American Indian or Alaska Native", label: "American Indian or Alaska Native" },
    { value: "Native Hawaiian or Pacific Islander", label: "Native Hawaiian or Pacific Islander" },
    { value: "Other Race", label: "Other Race" },
  ];

  const handleCheckbox = (e, index) => {
    let count = 0;
    setunSaved(false);
    if (e.target.value == "yes") uncopeQuestions[index].answer = "yes";
    else uncopeQuestions[index].answer = "no";
    setUncopeQuestions(uncopeQuestions);
    uncopeQuestions.map((question) => {
      if (question.answer == "yes") {
        count = count + 1;
      }
    });
    setUncopeScore(count);
  };

  const handleCheckboxcraft = (e, index) => {
    setunSaved(false);
    let count = 0;
    if (e.target.value == "yes") craftQuestions[index].answer = "yes";
    else craftQuestions[index].answer = "no";
    setCraftQuestion(craftQuestions);
    craftQuestions.map((question) => {
      if (question.answer == "yes") {
        count = count + 1;
      }
    });
    setUncopeScore(count);
  };

  const handleOnChange = (e) => {
    setunSaved(false);
    settoggleSSN(true);
    if (e.target.name === "same_home_number") {
      setisChecked_same_home_number(!isChecked_same_home_number);
      if (isChecked_same_home_number) {
        setuncopeScreening({ ...uncopeScreening, ["secondary_phone"]: "", }); //uncopeScreening.primary_phone
      } else {
        setuncopeScreening({ ...uncopeScreening, ["secondary_phone"]: uncopeScreening.primary_phone, });
      }
    } else if (e.target.name === "maritial_status") {
      setMaritalStatus(e.target.value);
      setuncopeScreening({ ...uncopeScreening, ["maritial_status"]: e.target.value, })
    } else if (e.target.name === "princepal_source_of_income" && e.target.value === "") {
      setuncopeScreening({ ...uncopeScreening, ["other_income_place"]: "", ["princepal_source_of_income"]: e.target.value })
    } else if (e.target.name === "address_lns") {
      setAddressState({ ...addressState, ["address_lns"]: e.target.value })
    } else if (e.target.name === "city_lns") {
      setAddressState({ ...addressState, ["city_lns"]: e.target.value })
    } else if (e.target.name === "state_lns") {
      setAddressState({ ...addressState, ["state_lns"]: e.target.value })
    } else if (e.target.name === "county_lns") {
      setAddressState({ ...addressState, ["county_lns"]: e.target.value })
    } else if (e.target.name === "zipcode_lns") {
      setAddressState({ ...addressState, ["zipcode_lns"]: e.target.value })
    } else if (e.target.name === "primary_phone") {
      if (isNaN(e.target.value)) {
        setValidation({ ["primary_phone"]: "* Only numbers are allowed" })
      } else {
        setuncopeScreening({ ...uncopeScreening, [e.target.name]: e.target.value });
        setValidation({ ["primary_phone"]: "" })
      }
    } else if (e.target.name === "secondary_phone") {
      if (isNaN(e.target.value)) {
        setValidation({ ["secondary_phone"]: "* Only numbers are allowed" })

      } else {
        setuncopeScreening({ ...uncopeScreening, [e.target.name]: e.target.value });
        setValidation({ ["secondary_phone"]: "" })
      }
    } else if (e.target.name === "phone_primary") {
      if (isNaN(e.target.value)) {
        setValidation({ ["phone_primary"]: "* Only numbers are allowed" })

      } else {
        setuncopeScreening({ ...uncopeScreening, [e.target.name]: e.target.value });
        setValidation({ ["phone_primary"]: "" })
      }
    } else {
      setuncopeScreening({ ...uncopeScreening, [e.target.name]: e.target.value });
    }

    // secondary_phone,phone_primary
    // value={addressState.zipcode_lns,county_lns,state_lns,address_lns1}setAddressState
  };


  const handlesaveAndComplete = async (e) => {
    try {
      setissaveAndCompleting(true)
      setisLoaderShow(true)
      let unscore = { uncope_score: uncope_score };
      let presenting_problem = { presenting_problems: presenting_problems };
      let secondaryreferal_list = { secondary_referral_list: secondary_referral };
      // race
      let Uncope_Question = form_type == "uncopeScreening" ? { uncopeQuestions: uncopeQuestions } : { uncopeQuestions: craftQuestions };
      let final_data = { uncopeScreening, form_type, addressState, ...presenting_problem, ...secondaryreferal_list, race: race, dataListBody };
      let res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfo", JSON.stringify(final_data));
      let questionsData = { dataListBody, form_type, unscore, ...Uncope_Question };
      let res2 = await axios.post(global.restApiURL + "customRest/saveuncofecraftscore", JSON.stringify(questionsData));
      if (res.data.success == true && res2.status == 200) {
        let dataListBody = {
          dataListBody: {
            apikey: localStorage.getItem("__TOKEN__"),
            client_id: localStorage.getItem("clientId"),
            form_type: form_type,
            created_by: localStorage.getItem("username"),
            is_completed: "1",
          }
        };
        let save_and_complete_res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfoTemp", JSON.stringify(dataListBody));
        let save_and_complete_score_res = await axios.post(global.restApiURL + "customRest/saveUncofeCraftScoreTemp", JSON.stringify(dataListBody));
        // saveUncofeCraftScoreTemp
        if (save_and_complete_res.status == 200 && save_and_complete_res.data.success == true && save_and_complete_score_res.status == 200) {
          setisLoaderShow(true)
          setisSaveAndComplete(true)
          setissaveAndCompleting(false)
          // loadData();
          window.location.reload();
        }
      }
    } catch {
      setisLoaderShow(false)
      alert("Something going wrong... Please try after some time")
    }
    // setsaveComplete(false);
    // props.closeModal();
  };

  const handlesaveAndPreview = async (e) => {
    const { primary_phone, secondary_phone, phone_primary } = uncopeScreening
    let formattedPrimaryPhone = primary_phone;
    let formattedsecondary_phone = secondary_phone;
    let formattedphone_primary = phone_primary;
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericSecondaryPhone = secondary_phone.replace(/\D/g, '');
    const numericphone_primary = phone_primary.replace(/\D/g, '');
    //  Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedsecondary_phone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    if (numericphone_primary.length === 10) {
      formattedphone_primary = `(${numericphone_primary.substring(0, 3)}) ${numericphone_primary.substring(3, 6)}-${numericphone_primary.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) || formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
      e.preventDefault();
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        seterrorMessage('Please enter at least 10 digits.');
      }
      if (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) {
        e.preventDefault();
        seterrorMessage1('Please enter at least 10 digits.');
      }
      if (formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
        e.preventDefault();
        seterrorMessage2('Please enter at least 10 digits.');
      }
    } else {
      try {
        setissaveAndCompleting(true)
        setisLoaderShow(true)
        let unscore = { uncope_score: uncope_score };
        let presenting_problem = { presenting_problems: presenting_problems };
        let secondaryreferal_list = { secondary_referral_list: secondary_referral };
        // race
        let Uncope_Question = form_type == "uncopeScreening" ? { uncopeQuestions: uncopeQuestions } : { uncopeQuestions: craftQuestions };
        let final_data = { uncopeScreening, form_type, addressState, ...presenting_problem, ...secondaryreferal_list, race: race, dataListBody };
        let res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfo", JSON.stringify(final_data));
        let questionsData = { dataListBody, form_type, unscore, ...Uncope_Question };
        let res2 = await axios.post(global.restApiURL + "customRest/saveuncofecraftscore", JSON.stringify(questionsData));
        if (res.data.success == true && res2.status == 200) {
          let save_and_complete_res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfoTemp", JSON.stringify(dataListBody = {
            dataListBody: {
              apikey: localStorage.getItem("__TOKEN__"),
              client_id: localStorage.getItem("clientId"),
              form_type: form_type,
              created_by: localStorage.getItem("username"),
              is_draft: "1",
            }
          }));
          let save_and_complete_score_res = await axios.post(global.restApiURL + "customRest/saveUncofeCraftScoreTemp", JSON.stringify(dataListBody = {
            dataListBody: {
              apikey: localStorage.getItem("__TOKEN__"),
              client_id: localStorage.getItem("clientId"),
              form_type: form_type,
              created_by: localStorage.getItem("username"),
              is_draft: "1",
            }
          }));
          // saveUncofeCraftScoreTemp
          if (save_and_complete_res.status == 200 && save_and_complete_res.data.success == true && save_and_complete_score_res.status == 200) {
            setisLoaderShow(true)
            // setisSaveAndComplete(true)
            setissaveAndCompleting(false)
            // loadData();
            window.location.reload();
          }
        }
      } catch {
        setisLoaderShow(false)
        alert("Something going wrong... Please try after some time")
      }
    }
    // setsaveComplete(false);
    // props.closeModal();
  };


  const AutoSaveAndPreview = async (e) => {
    try {
      setissaveAndCompleting(true)
      setisLoaderShow(true)
      let unscore = { uncope_score: uncope_score };
      let presenting_problem = { presenting_problems: presenting_problems };
      let secondaryreferal_list = { secondary_referral_list: secondary_referral };
      // race
      let Uncope_Question = form_type == "uncopeScreening" ? { uncopeQuestions: uncopeQuestions } : { uncopeQuestions: craftQuestions };
      let final_data = { uncopeScreening, form_type, addressState, ...presenting_problem, ...secondaryreferal_list, race: race, dataListBody };
      let res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfo", JSON.stringify(final_data));
      let questionsData = { dataListBody, form_type, unscore, ...Uncope_Question };
      let res2 = await axios.post(global.restApiURL + "customRest/saveuncofecraftscore", JSON.stringify(questionsData));
      if (res.data.success == true && res2.status == 200) {
        let save_and_complete_res = await axios.post(global.restApiURL + "customRest/saveUncopeScreeningInfoTemp", JSON.stringify(dataListBody = {
          dataListBody: {
            apikey: localStorage.getItem("__TOKEN__"),
            client_id: localStorage.getItem("clientId"),
            form_type: form_type,
            created_by: localStorage.getItem("username"),
            is_draft: "1",
          }
        }));
        let save_and_complete_score_res = await axios.post(global.restApiURL + "customRest/saveUncofeCraftScoreTemp", JSON.stringify(dataListBody = {
          dataListBody: {
            apikey: localStorage.getItem("__TOKEN__"),
            client_id: localStorage.getItem("clientId"),
            form_type: form_type,
            created_by: localStorage.getItem("username"),
            is_draft: "1",
          }
        }));
        // saveUncofeCraftScoreTemp
        if (save_and_complete_res.status == 200 && save_and_complete_res.data.success == true && save_and_complete_score_res.status == 200) {
          setisLoaderShow(true)
          // setisSaveAndComplete(true)
          setissaveAndCompleting(false)
          // loadData();
          window.location.reload();
        }
      }
    } catch {
      setisLoaderShow(false)
      alert("Something going wrong... Please try after some time")
    }
    // setsaveComplete(false);
    // props.closeModal();
  };
  const beforeMaskedValueChangeSSN = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !uncopeScreening.ssn.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }
  const toggleSSN2 = () => {
    setuncopeScreening({ ...uncopeScreening, ["ssn"]: !uncopeScreening.ssn })
  }
  // const handleComplete = async (e) => {
  //   e.preventDefault();
  //   setsaveComplete(true);
  //   setunSaved(true);
  // };

  const handleComplete = async (e) => {
    const { primary_phone, secondary_phone, phone_primary } = uncopeScreening
    let formattedPrimaryPhone = primary_phone;
    let formattedsecondary_phone = secondary_phone;
    let formattedphone_primary = phone_primary;
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericSecondaryPhone = secondary_phone.replace(/\D/g, '');
    const numericphone_primary = phone_primary.replace(/\D/g, '');
    //  Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericSecondaryPhone.length === 10) {
      formattedsecondary_phone = `(${numericSecondaryPhone.substring(0, 3)}) ${numericSecondaryPhone.substring(3, 6)}-${numericSecondaryPhone.substring(6)}`;
    }
    if (numericphone_primary.length === 10) {
      formattedphone_primary = `(${numericphone_primary.substring(0, 3)}) ${numericphone_primary.substring(3, 6)}-${numericphone_primary.substring(6)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) || formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
      e.preventDefault();
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        seterrorMessage('Please enter at least 10 digits.');
      }
      if (formattedsecondary_phone.length < 14 && formattedsecondary_phone.length > 0) {
        e.preventDefault();
        seterrorMessage1('Please enter at least 10 digits.');
      }
      if (formattedphone_primary.length < 14 && formattedphone_primary.length > 0) {
        e.preventDefault();
        seterrorMessage2('Please enter at least 10 digits.');
      }
    } else {
      e.preventDefault();
      setsaveComplete(true);
      setunSaved(true);
    }
  };
  // Address for Address API
  const handleChange_gpi = address_new => {
    let copy_addressState = Object.assign({}, addressState);
    copy_addressState["address_lns"] = address_new;
    setAddressState(copy_addressState)
  };

  const handleSelect = address => {
    handleChange_gpi(address)
    geocodeByAddress(address)
      .then((results) => {
        var stateCopy = Object.assign({}, addressState);
        for (var i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] === "locality") {
            stateCopy['city_lns'] = results[0].address_components[i].long_name
            stateCopy['address_lns'] = results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '');
            // setuncopeScreening({...uncopeScreening,["city_lns"]:results[0].address_components[i].long_name})
            // setuncopeScreening({...uncopeScreening,["address_lns1"]:results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '')})
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            stateCopy['state_lns'] = results[0].address_components[i].long_name;
            stateCopy['address_lns'] = results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '');
            // setuncopeScreening({...uncopeScreening,["state_lns"]:results[0].address_components[i].long_name})
            // setuncopeScreening({...uncopeScreening,["address_lns1"]:results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '')})
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
            stateCopy['county_lns'] = results[0].address_components[i].long_name;
            stateCopy['address_lns'] = results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '');
            // setuncopeScreening({...uncopeScreening,["county_lns"]:results[0].address_components[i].long_name})
            // setuncopeScreening({...uncopeScreening,["address_lns1"]:results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '')})
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            stateCopy['zipcode_lns'] = results[0].address_components[i].long_name;
            stateCopy['address_lns'] = results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '');
            // setuncopeScreening({...uncopeScreening,["zipcode_lns"]:results[0].address_components[i].long_name})
            // setuncopeScreening({...uncopeScreening,["address_lns1"]:results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '')})
          }
        }
        setunSaved(false)
        setAddressState(stateCopy)
      })
      .catch(error => console.error('Error', error));
  };


  const beforeMaskedValueChange1 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !uncopeScreening.phone_primary.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  const beforeMaskedValueChange2 = (newState, oldState, userInput) => {

    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !uncopeScreening.primary_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  const beforeMaskedValueChange3 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !uncopeScreening.secondary_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }


  const handlePhoneChange = (event) => {
    setunSaved(false);
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "phone_primary") {
        if (test !== "") {
          seterrorMessage2("");
        }
        setuncopeScreening({ ...uncopeScreening, ["phone_primary"]: test })
      }
      if (event.target.name === "primary_phone") {
        if (test !== "") {
          seterrorMessage("");
        }
        setuncopeScreening({ ...uncopeScreening, ["primary_phone"]: test })
      }
      if (event.target.name === "secondary_phone") {
        if (test !== "") {
          seterrorMessage1("");
        }
        setuncopeScreening({ ...uncopeScreening, ["secondary_phone"]: test })
      }
    }
  }


  const AutoSaveHandler = (action) => {
    AutoSaveAndPreview(action);
  }

  useEffect(() => {
    window.uncopeScrrening = { AutoSaveAndPreview: AutoSaveHandler };
  }, []);

  return (
    <div className="hold-transition sidebar-mini text-sm">
      <div className="wrapper">
        <div className="content-wrapper">
          <section className="content">
            <div className="row">
              <div>
                <Modal
                  showOverlay={true}
                  onClose={props.closeModal}
                  showCloseButton={false}
                  className="w-75"
                >
                  <Modal.Header>
                    <Modal.Title>
                      <p className="lead float-left">
                        <b className="text-green">
                          {/* {age >= 18 ? "UNCOPE Screening" : "CRAFFT Screening"} */}
                          {form_type == "uncopeScreening"
                            ? "UNCOPE Screening"
                            : form_type == "craftScreening"
                              ? "CRAFFT Screening"
                              : ""}
                        </b>
                      </p>
                      {/* <button type="submit" data-save="save" value='save' className="btn btn-success float-right btnmargin" onClick={handleId}> <i className="fas fa-save"></i> Save</button> */}
                      <input type="hidden" id="clicked" value="" readOnly />

                    </Modal.Title>
                  </Modal.Header>
                  <form>
                    <Modal.Body>
                      <div className="card shadow-none">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <strong style={{ fontSize: "1rem" }}>
                                    Personal Details
                                  </strong>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Last Name</label><span className="required">*</span>
                                      <input
                                        type="text"
                                        name="last_name"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.last_name}
                                        className="form-control"
                                        required={true}
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>First Name</label>
                                      <input
                                        type="text"
                                        name="first_name"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.first_name}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>MI</label>
                                      <input
                                        type="text"
                                        name="middle_initial"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.middle_initial}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Alias 1</label>
                                      <input
                                        type="text"
                                        name="alias"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.alias}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Date of Birth</label>
                                      <input
                                        type="date"
                                        name="dob"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.dob}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Age</label>
                                      <input
                                        type="text"
                                        name="age"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.age}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Sex</label>
                                      <select
                                        className="form-control"
                                        name="gender"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.gender}
                                        disabled={isSaveAndComplete}
                                      >
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Trans MTF"> Trans MTF</option>
                                        <option value="Trans FTM">Trans FTM</option>
                                        <option value="Doesn’t identify as M/F/T">Doesn’t identify as M/F/T</option>
                                      </select>
                                    </div>
                                  </div>
                                  {/* <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>SSN</label>
                                      <input
                                        type="text"
                                        name="ssn"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.ssn}
                                        className="form-control"
                                      />
                                    </div>
                                  </div> */}
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>SSN</label>
                                      {(toggleSSN && uncopeScreening.ssn !== null && uncopeScreening.ssn !== undefined && uncopeScreening.ssn !== '' && uncopeScreening.ssn.replace(/-/g, "").length >= 9) ? (
                                        <div>
                                          <input type="text" disabled={isSaveAndComplete} id="ssn_view" placeholder="SSN" name="ssn_text" className="form-control" value={`***-**-` + uncopeScreening.ssn.substr(uncopeScreening.ssn.length - 4)} onFocus={() => settoggleSSN(!toggleSSN)} />
                                          <input type="hidden" id="ssn" name="ssn" className="form-control" value={uncopeScreening.ssn} />
                                        </div>) : (
                                        <InputMask type='text' disabled={isSaveAndComplete} placeholder="SSN" id="ssn" name="ssn" className="form-control" mask="999-99-9999" maskChar={null} value={uncopeScreening.ssn} onChange={(e) => handleOnChange(e)} minLength={11} beforeMaskedValueChange={beforeMaskedValueChangeSSN} maxLength={11} onBlur={() => settoggleSSN(!toggleSSN)} onMouseLeave={() => settoggleSSN(!toggleSSN)} />)}

                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Medicaid #</label>
                                      <input
                                        type="text"
                                        name="medicaid"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.medicaid}
                                        className="form-control"
                                        disabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Race</label>
                                      <Select
                                        style={{ borderColor: "#b94a48" }}
                                        name="race"
                                        isMulti
                                        closeMenuOnSelect={false}
                                        value={race}
                                        onChange={(selectedoption, e) => { handleMultiSelectChanges(selectedoption, e) }}
                                        options={raceOptions}
                                        placeholder="Select"
                                        isDisabled={isSaveAndComplete}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-3 col-md-4">
                                    <div className="form-group">
                                      <label>Ethnicity</label>
                                      <select
                                        className="form-control"
                                        name="ethnicity"
                                        onChange={(e) => handleOnChange(e)}
                                        value={uncopeScreening.ethnicity}
                                        disabled={isSaveAndComplete}
                                      >
                                        <option value="">Select</option>
                                        <option value="cuban">Cuban</option>
                                        <option value="hispanic_specific_Origin_not_specified">Hispanic-Specific Origin not specified</option>
                                        <option value="mexican">Mexican</option>
                                        <option value="not_of_hispanic_orgin">Not of Hispanic Origin</option>
                                        <option value="other_specific_hispanic">Other Specific Hispanic</option>
                                        <option value="puerto_rican">Puerto Rican</option>
                                        <option value="unknown">Unknown</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                              <label>Address</label>

                                              {!isSaveAndComplete && <>
                                                <LocationSearchInput
                                                  value={addressState.address_lns}
                                                  onChange={handleChange_gpi}
                                                  onSelect={handleSelect}
                                                  id="address_lns"
                                                  name="address_lns"
                                                  defaultValue={addressState.address_lns}
                                                  isDisabled={isSaveAndComplete}
                                                />
                                              </>}

                                              {isSaveAndComplete && <>
                                                <input type="text" name="address_lns" className="form-control" value={addressState.address_lns} disabled={true} />
                                              </>}

                                            </div>


                                          </div>
                                          <div className="col-lg-8 col-md-12">
                                            <div className="form-group">
                                              <label>City</label>
                                              <input
                                                type="text"
                                                name="city_lns"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                value={addressState.city_lns}
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>

                                          <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                              <label>State</label>
                                              <input
                                                type="text"
                                                name="state_lns"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                // value={
                                                //   uncopeScreening.state_lns
                                                // }
                                                value={addressState.state_lns}
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-8 col-md-12">
                                            <div className="form-group">
                                              <label>County</label>
                                              <input
                                                type="text"
                                                name="county_lns"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                // value={
                                                //   uncopeScreening.county_lns
                                                // }
                                                value={addressState.county_lns}
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                              <label>Zip Code</label>
                                              <input
                                                type="text"
                                                name="zipcode_lns"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                // value={
                                                //   uncopeScreening.zipcode_lns
                                                // }
                                                value={addressState.zipcode_lns}
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-lg-4 col-md-12">
                                            <div className="form-group">
                                              <label>Home Phone Number</label>
                                              {/* <input
                                                type="text"
                                                name="primary_phone"
                                                onChange={(e) => handleOnChange(e)}
                                                value={uncopeScreening.primary_phone}
                                                className="form-control" disabled={isSaveAndComplete} />
                                              <span style={{ color: "red" }}>{validation.primary_phone}</span> */}
                                              <InputMask id="primary_phone" disabled={isSaveAndComplete} name="primary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={uncopeScreening.primary_phone} beforeMaskedValueChange={beforeMaskedValueChange2} onChange={handlePhoneChange} />
                                              {errorMessage && (
                                                <span style={{ color: 'red', fontSize: "75%" }}>{uncopeScreening.primary_phone === "" ? "" : errorMessage}</span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-8 col-md-12">
                                            <div className="form-group">
                                              <label>Cell Phone Number</label>
                                              <input
                                                type="checkbox"
                                                name="same_home_number"
                                                className="mr-1 ml-3"
                                                checked={isChecked_same_home_number}
                                                onChange={(e) => handleOnChange(e)}
                                                disabled={isSaveAndComplete}
                                              />
                                              <small> (Same as Home Number) </small>
                                              {/* <input
                                                type="text"
                                                name="secondary_phone"
                                                className="form-control"
                                                onChange={(e) => handleOnChange(e)}
                                                value={!isChecked_same_home_number ? uncopeScreening.secondary_phone : uncopeScreening.primary_phone}
                                                disabled={isSaveAndComplete}
                                              />
                                              <span style={{ color: "red" }}>{validation.secondary_phone}</span> */}

                                              <InputMask id="secondary_phone" disabled={isSaveAndComplete} name="secondary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={uncopeScreening.secondary_phone} beforeMaskedValueChange={beforeMaskedValueChange3} onChange={handlePhoneChange} />
                                              {errorMessage1 && (
                                                <span style={{ color: 'red', fontSize: "75%" }}>{uncopeScreening.secondary_phone === "" ? "" : errorMessage1}</span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                              <label>
                                                Emergency Contact Name
                                              </label>
                                              <input
                                                type="text"
                                                name="name_primary"
                                                onChange={(e) => handleOnChange(e)}
                                                value={uncopeScreening.name_primary}
                                                className="form-control"
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>

                                          <div className="col-lg-6 col-md-12">

                                            <div className="form-group">
                                              <label>Emergency Contact Number</label>
                                              <InputMask id="phone_primary" name="phone_primary" disabled={isSaveAndComplete} className="form-control" mask="(999) 999-9999" maskChar={null} value={uncopeScreening.phone_primary} beforeMaskedValueChange={beforeMaskedValueChange1} onChange={handlePhoneChange} />
                                              {errorMessage2 && (
                                                <span style={{ color: 'red', fontSize: "75%" }}>{uncopeScreening.phone_primary === "" ? "" : errorMessage2}</span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                              <label>Relationship</label>
                                              <input
                                                type="text"
                                                name="relationship_primary"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                value={
                                                  uncopeScreening.relationship_primary
                                                }
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                              <label>Emergency Email</label>
                                              <input
                                                type="mail"
                                                // name="emergency_primary_email"
                                                // name="email_primary"
                                                name="primary_email"
                                                className="form-control"
                                                onChange={(e) =>
                                                  handleOnChange(e)
                                                }
                                                // value={ uncopeScreening.emergency_primary_email}
                                                // value={ uncopeScreening.email_primary}
                                                value={uncopeScreening.primary_email}
                                                disabled={isSaveAndComplete}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </div>
                              <hr />
                              <div className="row marginBottom1">
                                <div className="col-lg-3 col-md-4">
                                  What is the most important thing you want that
                                  made you contact us?
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  <TextareaAutosize
                                    style={{ overflow: 'hidden' }}
                                    name="important_thing"
                                    className="form-control"
                                    onChange={(e) => handleOnChange(e)}
                                    value={uncopeScreening.important_thing}
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3 col-md-4 mb-2">
                                  Presenting Problems:
                                </div>
                                <div className="col-lg-3 col-md-8 mb-2">
                                  <Select
                                    style={{ borderColor: "#b94a48" }}
                                    name="presenting_problems"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    value={presenting_problems}
                                    onChange={(selectedoption, e) => { handleMultiSelectChanges(selectedoption, e) }}
                                    options={presenting_problemsOptions}
                                    placeholder="Select"
                                    isDisabled={isSaveAndComplete}

                                  />
                                </div>
                                {otherPresentingProblem && (
                                  <div className="col-lg-6 col-md-12">
                                    <TextareaAutosize
                                      rows={1}
                                      name="presenting_problem_explain"
                                      style={{ overflow: 'hidden' }}
                                      onChange={(e) => handleOnChange(e)}
                                      value={
                                        uncopeScreening.presenting_problem_explain
                                      }
                                      className="form-control"
                                      placeholder="If other, specify"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                )}
                              </div>
                              <hr />

                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>Marital Status</label>
                                    <select className="form-control" disabled={isSaveAndComplete} name="maritial_status" onChange={(e) => handleOnChange(e)} value={maritial_status}>
                                      <option value="">Select</option>
                                      <option value="Common Law">Common Law </option>
                                      <option value="Single">Single</option>
                                      <option value="Living Together">Living Together</option>
                                      <option value="Married">Married</option>
                                      <option value="Separated">Separated</option>
                                      <option value="Divorced">Divorced</option>
                                      <option value="Widowed">Widowed</option>
                                    </select>
                                  </div>
                                </div>
                                {maritial_status ==
                                  "married" && (
                                    <>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="form-group">
                                          <label>If married how long?</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="marriage_period"
                                            onChange={(e) => handleOnChange(e)}
                                            value={
                                              uncopeScreening.marriage_period
                                            }
                                            disabled={isSaveAndComplete}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-3 col-md-4">
                                        <div className="form-group">
                                          <label>Total Number of Marriages</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="number_of_marriages"
                                            onChange={(e) => handleOnChange(e)}
                                            value={
                                              uncopeScreening.number_of_marriages
                                            }
                                            disabled={isSaveAndComplete}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>
                                      Language Preference&nbsp;&nbsp;&nbsp;
                                    </label>
                                    <select className="form-control" name="language_preference" onChange={(e) => handleOnChange(e)} value={uncopeScreening.language_preference} disabled={isSaveAndComplete} >
                                      <option value="">Select</option>
                                      <option value="english">English</option>
                                      <option value="american_sign_language">American Sign Language</option>
                                      <option value="arabic">Arabic</option>
                                      <option value="chinese">Chinese</option>
                                      <option value="french_and_french_creole">French And French Creole(Including Cajun)</option>
                                      <option value="german">German</option>
                                      <option value="hindi">Hindi</option>
                                      <option value="italian">Italian</option>
                                      <option value="japanese">Japanese</option>
                                      <option value="korean">Korean</option>
                                      <option value="laotian">Laotian</option>
                                      <option value="other_african_languages">Other African Languages</option>
                                      <option value="other_asian_languages">Other Asian Languages</option>
                                      <option value="other_europian_languages">Other Europian Languages</option>
                                      <option value="other_indic_languages">Other Indic Languages</option>
                                      <option value="persian">Persian</option>
                                      <option value="portuguese">Portuguese</option>
                                      <option value="russian">Russian</option>
                                      <option value="spanish_and_spanish_creole">Spanish And Spanish Creole</option>
                                      <option value="tagalog">Tagalog</option>
                                      <option value="vietnamese">Vietnamese</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>Linguistic Status</label>
                                    <select
                                      className="form-control"
                                      name="linguistic_status"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.linguistic_status}
                                      disabled={isSaveAndComplete}
                                    >
                                      <option value="">Select</option>

                                      {linguisticOption.map((linguistic) => (
                                        <option value={linguistic.value}>
                                          {linguistic.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>Hearing Status</label>
                                    <select className="form-control" name="hearing_status" onChange={(e) => handleOnChange(e)} value={uncopeScreening.hearing_status} disabled={isSaveAndComplete}  >
                                      <option value="">Select</option>
                                      {hearingOptions.map((hearing) => (
                                        <option value={hearing.value}>
                                          {hearing.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {/* {age >= 18 && <> */}
                                <div className="col-lg-3 col-md-4">
                                  <div className="form-group">
                                    <label>Military Status</label>
                                    <select
                                      className="form-control"
                                      name="military_status"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.military_status}
                                      disabled={isSaveAndComplete}
                                    >
                                      <option value="">Select</option>
                                      <option value="na">NA</option>
                                      <option value="active">Active</option>
                                      <option value="non_active_honorable_dis">Non-Active Honorable Dis</option>
                                      <option value="non_active_dishonorable_dis">Non-Active Dishonorable Dis</option>
                                      <option value="military_dependent">Military Dependent</option>
                                    </select>
                                  </div>
                                </div>
                                {/* </>} */}
                              </div>
                              <hr />
                              <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>
                                      Which is the primary referral source?
                                    </label>
                                    {/* <select
                                      className="form-control"
                                      name="primary_referral"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.primary_referral}
                                      // value="Court/Correctional Agency"
                                    
                                    >
                                      <option value="">Select</option>
                                      {referralSource.map((referal) => (
                                        <option value={referal.value}>
                                          {referal.label}
                                        </option>
                                      ))}
                                    </select> */}
                                    <select
                                      className="form-control"
                                      name="primary_referral"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.primary_referral}
                                      disabled={isSaveAndComplete}
                                    >
                                      <option value="">Select</option>
                                      {referralSource.map((referal) => (
                                        <option value={referal.value}>
                                          {referal.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                {uncopeScreening.primary_referral ==
                                  "If_Other_Specify_below" && (
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label>If other, specify</label>
                                        <TextareaAutosize
                                          className="form-control"
                                          style={{ overflow: 'hidden' }}
                                          name="other_referral_source"
                                          onChange={(e) => handleOnChange(e)}
                                          value={
                                            uncopeScreening.other_referral_source
                                          }
                                          disabled={isSaveAndComplete}
                                        />
                                      </div>
                                    </div>
                                  )}
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Secondary referral source</label>
                                    <Select
                                      name="secondary_referral"
                                      isMulti
                                      closeMenuOnSelect={false}
                                      onChange={(selectedoption, e) => { handleMultiSelectChanges(selectedoption, e) }}
                                      options={referralSource}
                                      placeholder="Select"
                                      value={secondary_referral}
                                      isDisabled={isSaveAndComplete}
                                    />
                                  </div>
                                </div>


                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <label>Reason for referral</label>
                                  <TextareaAutosize
                                    rows={2}
                                    style={{ overflow: 'hidden' }}
                                    // minRows={(uncopeScreening.referral_reason.length/109) + uncopeScreening.referral_reason.split(/\r\n|\r|\n/).length}
                                    name="referral_reason"
                                    className="form-control"
                                    onChange={(e) => handleOnChange(e)}
                                    value={uncopeScreening.referral_reason}
                                    disabled={isSaveAndComplete}
                                  />
                                </div>
                              </div>
                              <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-12">
                                  <strong style={{ fontSize: "1rem" }}>
                                    Financial
                                  </strong>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    {form_type == "uncopeScreening" ? (
                                      <label>
                                        I receive my principal source of income
                                        from
                                      </label>
                                    ) : (
                                      <label>
                                        I or my parents principal source of
                                        income is
                                      </label>
                                    )}
                                    <select className="form-control" name="princepal_source_of_income" onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.princepal_source_of_income}
                                      disabled={isSaveAndComplete}
                                    >
                                      <option value="">Select</option>
                                      <option value="wages_salary">Wages/Salary</option>
                                      <option value="public_assistance"> Public Assistance</option>
                                      <option value="retirement_pension">Retirement/Pension</option>
                                      <option value="disablity">Disablity</option>
                                      <option value="none">None</option>
                                      <option value="other">Other</option>
                                      {form_type == "craftScreening" ? (<option value="unknown">Unknown</option>) : ""}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>
                                      What would you estimate your household
                                      annual income to be?
                                    </label>
                                    <input
                                      type="text"
                                      name="family_annual_income"
                                      className="form-control"
                                      onChange={(e) => handleOnChange(e)}
                                      value={
                                        uncopeScreening.family_annual_income
                                      }
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row'>
                                {uncopeScreening.princepal_source_of_income ==
                                  "other" && (
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label>If other, specify</label>
                                        <TextareaAutosize
                                          rows={1}
                                          style={{ overflow: "hidden", textAlign: "justify" }}
                                          name="other_income_place"
                                          className="form-control"
                                          onChange={(e) => handleOnChange(e)}
                                          value={
                                            uncopeScreening.other_income_place
                                          }
                                          disabled={isSaveAndComplete}
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                              <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-12">
                                  <strong style={{ fontSize: "1rem" }}>
                                    Health Insurance and Source of Payment
                                  </strong>
                                </div>
                              </div>
                              <hr />
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Insurance Type</label>
                                    <select name="insurance_type" className="form-control" onChange={(e) => handleOnChange(e)} value={uncopeScreening.insurance_type} disabled={isSaveAndComplete} >
                                      <option value="">Select</option>
                                      {insurancetype.map((insurance) => (
                                        <option value={insurance.value}>
                                          {insurance.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Specify the name of company</label>
                                    <input
                                      type="text"
                                      name="company_name"
                                      className="form-control"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.company_name}
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Policy Number</label>
                                    <input
                                      type="text"
                                      name="policy_number"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.policy_number}
                                      className="form-control"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Group Number</label>
                                    <input
                                      type="text"
                                      name="group_number"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.group_number}
                                      className="form-control"
                                      disabled={isSaveAndComplete}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>Source of Payment</label>
                                    <select
                                      className="form-control"
                                      name="payment_source"
                                      onChange={(e) => handleOnChange(e)}
                                      value={uncopeScreening.payment_source}
                                      disabled={isSaveAndComplete}
                                    >
                                      <option value="">Select</option>
                                      {/* {sourceOfPayment_Option.map((sourceOfPayment_Option)=>{
                                        
                                      })} */}
                                      {sourceOfPayment_Option.map(
                                        (insurance) => (
                                          <option value={insurance.value}>
                                            {insurance.label}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    {form_type == "uncopeScreening" ? (
                                      <>
                                        <label>Priority Population</label>
                                        <select
                                          name="priority_population"
                                          className="form-control"
                                          onChange={(e) => handleOnChange(e)}
                                          value={uncopeScreening.priority_population}
                                          disabled={isSaveAndComplete}
                                        >
                                          <option>Select</option>
                                          {priorityPopulation.map(
                                            (priority) => (
                                              <option value={priority.value}>
                                                {priority.label}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        <label>Special Population</label>
                                        <select
                                          name="special_population"
                                          className="form-control"
                                          onChange={(e) => handleOnChange(e)}
                                          value={
                                            uncopeScreening.special_population
                                          }
                                          disabled={isSaveAndComplete}
                                        >
                                          <option>Select</option>
                                          {specialPopulation.map((priority) => (
                                            <option value={priority.value}>
                                              {priority.label}
                                            </option>
                                          ))}
                                        </select>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {form_type == "uncopeScreening" ? (
                                <div className="row">
                                  <div className="col-6">
                                    {uncopeScreening.priority_population ==
                                      "other" && (

                                        <div className="form-group">
                                          <label>If other, please explain</label>
                                          <TextareaAutosize
                                            className="form-control"
                                            rows={2}
                                            name="other_priority_population"
                                            onChange={(e) => handleOnChange(e)}
                                            value={
                                              uncopeScreening.other_priority_population
                                            }
                                            disabled={isSaveAndComplete}
                                          />
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>) : ""
                              }

                              <div className="row" style={{ marginTop: "2%" }}>
                                <div className="col-md-12">
                                  {/* {form_type=="uncopeScreening"?"UNCOPE Screening":""} */}
                                  {form_type == "uncopeScreening" ? (
                                    <strong style={{ fontSize: "1rem" }}>
                                      UNCOPE - Adult
                                    </strong>
                                  ) : (
                                    <strong style={{ fontSize: "1rem" }}>
                                      CRAFFT - Adolescent
                                    </strong>
                                  )}
                                </div>
                              </div>
                              <hr />
                              <div className="row marginBottom1">
                                <div className="col-md-12">
                                  <table className="table table-bordered table-striped table-center">
                                    <thead>
                                      <th></th>
                                      <th>Yes</th>
                                      <th>No</th>
                                    </thead>
                                    <tbody>
                                      {form_type == "uncopeScreening"
                                        ? uncopeQuestions.map(
                                          (question, index) => (
                                            <tr>
                                              <td>{question.question}</td>
                                              <td>
                                                <div className="form-check">
                                                  {question.answer == "yes" ? <>

                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckbox(e, index)
                                                      }
                                                      name={"checkbox" + index}
                                                      value="yes"
                                                      // checked={
                                                      //   question.answer == "yes"
                                                      //     ? "checked"
                                                      //     : ""
                                                      // }
                                                      checked="true"
                                                      disabled={isSaveAndComplete}
                                                    />
                                                  </> : <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckbox(e, index)
                                                      }
                                                      name={"checkbox" + index}
                                                      value="yes"
                                                      disabled={isSaveAndComplete}
                                                    />
                                                  </>}

                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-check">
                                                  {question.answer == "no" ? <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckbox(e, index)
                                                      }
                                                      value="no"
                                                      name={"checkbox" + index}
                                                      disabled={isSaveAndComplete}
                                                      checked="true"
                                                    />
                                                  </> : <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckbox(e, index)
                                                      }
                                                      value="no"
                                                      name={"checkbox" + index}
                                                      disabled={isSaveAndComplete}
                                                    /></>}

                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )
                                        : craftQuestions.map(
                                          (question, index) => (
                                            <tr>
                                              <td>{question.question}</td>
                                              <td>
                                                <div className="form-check">
                                                  {question.answer == "yes" ? <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"

                                                      onChange={(e) =>
                                                        handleCheckboxcraft(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      name={"checkbox" + index}
                                                      value="yes"
                                                      disabled={isSaveAndComplete}
                                                      checked="true"
                                                    />
                                                  </> : <>

                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckboxcraft(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      name={"checkbox" + index}
                                                      value="yes"
                                                      disabled={isSaveAndComplete}
                                                    />
                                                  </>}

                                                </div>
                                              </td>
                                              <td>
                                                <div className="form-check">
                                                  {question.answer == "no" ? <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckboxcraft(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      value="no"
                                                      name={"checkbox" + index}
                                                      disabled={isSaveAndComplete}
                                                      checked="true"

                                                    />
                                                  </> : <>
                                                    <input
                                                      className="form-check-input"
                                                      type="radio"
                                                      onChange={(e) =>
                                                        handleCheckboxcraft(
                                                          e,
                                                          index
                                                        )
                                                      }
                                                      value="no"
                                                      name={"checkbox" + index}
                                                      disabled={isSaveAndComplete}

                                                    />
                                                  </>}
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      <tr>
                                        <td>
                                          <strong className="float-right">
                                            {form_type == "uncopeScreening"
                                              ? "UNCOPE Score"
                                              : "CRAFFT Score"}
                                          </strong>
                                        </td>
                                        <td colSpan={2}>{uncope_score}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row">
                                <div
                                  className="col-md-12"
                                  style={{
                                    border: "2px solid lightgray",
                                    background: "rgb(242, 242, 242)",
                                    fontSize: "12px",
                                  }}
                                >
                                  {form_type == "uncopeScreening" ? (
                                    <label>
                                      <b>Sources:</b>
                                      <br />
                                      1. Brown, R. L., Leonard, T., Saunders, L.
                                      A., & Papasouliotis, O. (1977). A two-item
                                      screening test for alcohol and other drug
                                      problems. Journal of FamilyPractice, 44,
                                      (2), 151-160.
                                      <br />
                                      2. Hoffman, N. G. & Harrison, P. A.
                                      (1995). SUDDS-IV: Substance Use Disorders
                                      Diagnostic Schedule, Smithfield, RI:
                                      Evince Assessments.
                                      <br />
                                      3. Hoffman, N. G. ((1995). TAAD: Triage
                                      Assessment for Addictive Disorders,
                                      Smithfield, RI: Evince Clinical
                                      Assessments.
                                    </label>
                                  ) : (
                                    <label>
                                      <b>Source:</b> Knight JR; Shrier LA;
                                      Bravender TD; Farrell M; Vander Bilt J;
                                      Shaffer HJ (1999) A new brief screen for
                                      adolescent substance use. Archives of
                                      Pediatrics and Adolescent Medicine Jun;
                                      153(6). 591-6.
                                    </label>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="justify-content-between">
                            <Button
                              className="btn btn-default float-left"
                              id="closeButton"
                              onClick={props.closeModal}
                              type="button"
                              form="myForm"
                              key="submit"
                              htmlType="submit"
                            >
                              Close
                            </Button>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary float-right"
                            onClick={(e) => handleComplete(e)}
                            disabled={isSaveAndComplete || issaveAndCompleting}
                          >
                            {" "}
                            <i className="fas fa-save"></i>Save & Complete
                          </button>
                          <button
                            disabled={unSaved}
                            id="saveButton"
                            type="submit"
                            onClick={handleSubmit}
                            className="btn btn-success float-right btnmargin"
                            htmlType="submit"
                            form="assessmentsForm"
                          >
                            {" "}
                            <i className="fas fa-save"></i> Save
                          </button>
                          <button
                            className="btn btn-primary float-right"
                            onClick={(e) => handlesaveAndPreview(e)}
                            disabled={isSaveAndComplete || issaveAndCompleting}
                          >
                            {" "}
                            <i className="fas fa-save"></i>Save & Preview
                          </button>
                          <button
                            className="btn btn-primary float-right"
                            id="saveButton1"
                            onClick={(e) => AutoSaveAndPreview(e)}
                            style={{ display: 'none' }}
                          >
                            {" "}
                            <i className="fas fa-save"></i>Save & Preview
                          </button>
                        </div>
                      </div>
                    </Modal.Footer>
                  </form>
                </Modal>



                {updateModal_uncope && props.form_type == "uncopeScreening" && !localStorage.getItem("is_one_year_completed") && (
                  <Modal
                    showCloseButton={false}
                    className="modal-sm"
                    showOverlay={true}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <h4 className="modal-title float-left text-green">
                          {form_type == "uncopeScreening" ? "UNCOPE Screening" : "CRAFFT Screening"}
                        </h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <p>
                        This form was last completed on <b style={{ padding: "0px 2px 0px 2px", color: "#89BC3B" }}>{creationDate}.</b> It is due
                        annually. Do you want to  update an existing one?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>

                      <button
                        className="btn btn-default"
                        onClick={() => setUpdateModal_uncope(false)}
                      >
                        Update existing
                      </button>
                    </Modal.Footer>
                  </Modal>
                )}

                {updateModal_craft && props.form_type == "craftScreening" && !localStorage.getItem("is_one_year_completed") && (
                  <Modal
                    showCloseButton={false}
                    className="modal-sm"
                    showOverlay={true}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <h4 className="modal-title float-left text-green">
                          {form_type == "uncopeScreening"
                            ? "UNCOPE Screening"
                            : "CRAFFT Screening"}
                        </h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <p>
                        This form was last completed on <b style={{ padding: "0px 2px 0px 2px", color: "#89BC3B" }}>{creationDate}.</b> It is due
                        annually. Do you want to  update an existing one?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>

                      <button
                        className="btn btn-default"
                        onClick={() => setUpdateModal_craft(false)}
                      >
                        Update existing
                      </button>
                    </Modal.Footer>
                  </Modal>
                )}
                {/* update modal end */}





                {saveComplete && (
                  <Modal
                    showCloseButton={false}
                    className="modal-sm"
                    showOverlay={true}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <h4 className="modal-title float-left text-green">
                          Save & Complete
                        </h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        By clicking Save & Complete, this form will be submitted
                        and you will not be able to make any changes. Are you
                        sure you want to proceed?
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <button className="btn btn-success float-left" disabled={issaveAndCompleting} onClick={(e) => handlesaveAndComplete(e)}> Yes  </button>
                      <button className="btn btn-default" disabled={issaveAndCompleting} onClick={() => setsaveComplete(false)}>  No  </button>
                    </Modal.Footer>
                  </Modal>
                )}
              </div>
            </div>
          </section>
        </div>
        {isLoaderShow ? <Loader /> : ""}
      </div>
      <Footer />
    </div>
  );
}
