
import axios from 'axios';
import React, { Component } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "./toastify";


var context;


class Logout extends Component {

  /*componentDidMount()
    {
      if(context.state.interviewers.length === 0)
        {
             let getInterviewersListBody = {
                 apikey: localStorage.getItem('__TOKEN__'),
             };

             axios.post(global.restApiURL + 'customRest/logout_user' , JSON.stringify(getInterviewersListBody))
             .then(res => {

                 if(res.data.success)
                 {
                  //nothing to do
                 }
            });
        }
    }*/


  constructor() {
    super();
    context = this;
    //console.log('Hello')
    // this.logoutFromWS02 = this.logoutFromWS02.bind(this);

  }

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const state = query.get('state');
    const sp = query.get('sp');

    if (sp == undefined || sp == null) {
      //localStorage.setItem('last_page_url', this.props.history.location.pathname.substring(1));
      this.logoutFromWS02();
    } else if (state == undefined || state == null) {
      window.location.href = global.domain;
    } else {
      // console.log('clearing session from React')
      localStorage.setItem('__TOKEN__', '');
      localStorage.removeItem('success_msg');
      localStorage.removeItem('error_msg');
      localStorage.setItem('loggedinusername', '');
      localStorage.setItem('clientId', '');
      localStorage.setItem('viewMode', '');
      localStorage.setItem('peer_support_specialist', '')
      localStorage.setItem('intake_date', '')
      localStorage.setItem('dob', '')
      localStorage.setItem('month_1_followup_date', '')
      localStorage.setItem('email', '')
      localStorage.setItem('phone', '')
      localStorage.setItem('last_updated_by', '')
      localStorage.setItem('formname', '')
      localStorage.setItem('responceId', '')
      localStorage.setItem('client_first_name', '')
      localStorage.setItem('client_middle_initial', '')
      localStorage.setItem('client_last_name', '')
      localStorage.setItem('client_basicinfosave_url', '')
      localStorage.setItem('emergency_contact_save_url', '')
      localStorage.setItem('hangout_save_url', '')
      localStorage.setItem('other_agency_save_url', '')
      localStorage.clear();
      window.location.href = global.domain;
    }
    // localStorage.clear();
    // window.location.href = global.domain;
  }


  logoutFromWS02() {
    
    let WS02Body = {
      apikey: localStorage.getItem('__TOKEN__'),
    };
    console.log('requesting from pimcore')
    axios.post(global.restApiURL + 'customRest/WS02Logout', JSON.stringify(WS02Body))
      .then(res => {
        console.log('response ', res)
        if (res.data.success && res.status == 200) {
          // const response = res.data.data.elements;
          //console.log("Attendies data : " , res.data.data) ;
          // context.parseFollowupHistory(res.data.data);

          window.location.href = res.data.url;

          // this.overallResponseCalculator();    

          //console.log("interviewers : " , this.state.peerSupportOptions )
        } else if ("error_code" in res) {
          Alert('error', 'Error occured while Logging out from WS02. Try again!');
          window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
        }
        else {
          Alert('error', 'Error occured while Logging out from WS02. Try again!');
          window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';

        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        localStorage.clear();
        if (err.response) {
          // Request made and server responded
          // alert(err.response.data.msg);
          window.location.href="/";
          if (err.response.status === 401 || err.response.status === 403) {
            //window.location.href = global.domain + global.traversArray['out'];
          }
        } else if (err.request) {
          // The request was made but no response was received
          // alert(err.request);
          window.location.href="/";
        } else {
          // Something happened in setting up the request that triggered an Error
          // alert(err.message);
          window.location.href="/";
        }
        //window.location.href = global.domain+global.traversArray['out']+'?sp=CHIPSS&state=logout';

        //console.error(err); 
      })
  }

  render() {
    return (
      <div className="hold-transition login-page">
        {/*<div className="login-box" >
            <div className="login-logo">
               <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
                <br/> 
                <br/>
                <img src={window.location.origin + '/images/alert.png'} alt="Logged out" />
                <h4>You are logged out.</h4>
            </div>
          </div>*/}
      </div>
    )
  }
}

export function AppLogout(props) {
  const navigate = useNavigate();
  const location = useLocation()
  return (<Logout {...props} navigate = {navigate} location = {location}></Logout>
  )
}

export default Logout