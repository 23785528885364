// import { EncryptStorage } from 'encrypt-storage';

// export const encryptStorage = new EncryptStorage('secret-key-value', {
//   storageType: 'localStorage',
// });



import { EncryptStorage } from 'encrypt-storage';

class CustomEncryptStorage extends EncryptStorage {
  getItem(key)
 {
    var value = super.getItem(key)
;
    return value === null ? "" : value;
  }
}

export const encryptStorage = new CustomEncryptStorage('secret-key-value', {
  storageType: 'localStorage',
});

console.log(encryptStorage, "encryptStorage");