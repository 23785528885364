import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import { formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import { validationParser } from '../ReusableComponents/validationParser2';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { AppTopNav } from './Topnav';
//Tooltip imports
import $ from 'jquery';
import moment from 'moment';
import DataTable, { defaultThemes } from 'react-data-table-component';
import InputMask from 'react-input-mask';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import { encryptStorage } from './LocalStorageInterceptor';
import Modal from './Modals/Modal/index';
import { can_add } from '../ReusableComponents/urlGenerator';
import axios from 'axios';
import HOC from '../HOC.js';
import { showSuccessToast } from './toastService.js';

var validationObj = {};
var context;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderStyle: 'solid',
      borderWidth: '10px',
      borderColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};


class Cases extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.datamapper = this.datamapper.bind(this);
    var now = new Date();
    context.state = {
      errorMessage: '',
      startDateintakedate: new Date,
      month1followupdate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      sixmonthfollow: new Date(now.getFullYear(), now.getMonth() + 6, now.getDate()),
      dob: '',

      probationParoleCaseOptions: [],
      agencyOptions: [],
      leaveAMessageOptions: [],

      casedata: this.getModal("casedata"),
      required: this.getModal("casedata"),
      labelText: this.getModal("casedata"),
      toolTips: this.getModal("casedata"),
      tooltipsVisibility: this.getModal("casedata"),
      fieldName: this.getModal("casedata"),
      fieldType: this.getModal("casedata"),

      addCaseData: this.getModal("addCaseData"),
      viewCaseData: this.getModal("casedata"),

      caseHistoryData: [],
      caseHistoryColumns: this.getModal("caseHistoryColumns"),

      showModal: false,
      showCaseDetailsModal: false,

      state: "",
      stateOptions: [],

      county: '',
      countyOptions: []
    };
  }

  onModalOpen(event) {
    let formType = "";
    formType = event.target.lang;
    if (formType === 'none') {
      context.setState({
        showModal: true,
        formAccessMode: formType
      })
    }
    else {
      context.setState({showModal: true,})
    }
  }

  onModalClose() {
    context.setState({
      showModal: false
    })
  }

  onCaseDetailsModalOpen() {
    context.setState({
      showCaseDetailsModal: true
    })
  }

  onCaseDetailModalClose() {
    context.setState({showCaseDetailsModal: false})
  }

  getModal(type) {
    let dataModel = '';
    switch (type) {
      case "casedata":
        dataModel = {
          agency: '',
          case_or_id_num: '',
          leave_msg: '',
          officer_name: '',
          phone: '',
          probation_parole_cases: '',
          judge_name: '',
          next_court_date: '',
          attorney: '',
        };
      break;
      case "resetdata":
        dataModel = {
          agency: '',
          case_or_id_num: '',
          leave_msg: '',
          officer_name: '',
          phone: '',
          probation_parole_cases: '',
          judge_name: '',
          next_court_date: '',
          attorney: '',
        };
      break;
      case "addCaseData":
        dataModel = {
          agency: '',
          case_or_id_num: '',
          leave_msg: '',
          officer_name: '',
          phone: '',
          probation_parole_cases: '',
          judge_name: '',
          next_court_date: '',
          attorney: '',
        };
      break;
      case "caseHistoryColumns":
        dataModel = [
          {
            name: 'Case Number',
            // selector: (row) => row.case_or_id_num,
            selector: 'case_or_id_num',
            sortable: true,
          },
          {
            name: 'Type',
            // selector: (row) => row.probation_parole_cases,
            selector: 'probation_parole_cases',
            sortable: true,
          },
          {
            name: 'Agency',
            // selector: (row) => row.agency,
            selector: 'agency',
            sortable: true,
          },
          {
            name: 'Judge Name',
            // selector: (row) => row.judge_name,
            selector: 'judge_name',
            sortable: true,
          },
          {
            name: 'Attorney Name',
            selector: 'attorney',// selector: (row) => row.attorney,
            sortable: true,
          },
          {
            name: 'Officer Name',
            selector: 'officer_name',//selector: (row) => row.officer_name,
            sortable: true,
          },
          {
            name: 'Next Court Date', // New column: Next Court Date
            selector: 'next_court_date',//selector: (row) => row.next_court_date,
            sortable: true,
          },
          {
            name: 'Phone',
            selector: 'phone',//selector: (row) => row.phone,
            sortable: false,
          },
          {
            name: 'Leave Message',
            selector: 'leave_msg',//selector: (row) => row.leave_msg,
            sortable: false,
          },
          {
            name: 'State',
            selector: 'state',
            sortable: false,
          },
          {
            name: 'County',
            selector: 'county',
            sortable: false,
          },
          {
            name: 'Action',
            sortable: false,
            cell: (row) => (
              <div>
                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleCaseDetails}></i></Link>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ];
      break;
      default:
        dataModel = {};
      break;
    }
    return dataModel;
  }

  handleCaseDetails(event) {
    let historyID = event.target.id;
    axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
      .then(res => {
        const response = res.data.data.elements;
        context.setState({
          viewCaseData: formObjDataMapper2(response, context.getModal("casedata"))
        })
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
    )
    context.onCaseDetailsModalOpen();
  }

  handleChange = date => {
    this.setState({startDateintakedate: date});
  };

  handlemonth1followupdate = date => {
    this.setState({month1followupdate: date});
  };

  handleChangesixmonthfollow = date => {
    this.setState({sixmonthfollow: date});
  };

  handleChangedob = date => {
    this.setState({dob: date});
  };

  componentWillMount() {
    axios.get(global.restApiURL + 'webservice/rest/class/id/5?apikey=' + localStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;
        validationObj = validationParser(responce.data.layoutDefinitions.children[0].children);
        this.setState({
          required: validationObj.mandatory,
          labelText: validationObj.title,
          toolTips: validationObj.tooltip
        });
        this.createSelectOptions();
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
    )
  }


  componentDidMount() {
    this.caseList()
  }

caseList = ()=>{
  var prevExist = false;
  localStorage.setItem('responceId', '');
  if (localStorage.getItem('clientId') !== '') {
    let getCaseListBody = {
      apikey: localStorage.getItem('__TOKEN__'),
      client_id: localStorage.getItem('clientId'),
    };
    axios.post(global.restApiURL + 'customRest/getCaseList', JSON.stringify(getCaseListBody))
      .then(res => {
        if (res.data.success && res.status == 200) {
          setTimeout(function () {
            context.setState({
              caseHistoryData: context.parseCaseHistory(res.data.data),
              casedata: context.getModal('resetdata')
            });
          }, 50)
        }
        else if ("error_code" in res) {
          alert(res.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(res.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
    )
  }
}
  parseCaseHistory(historyDataArray) {
    let parsedData = [];
    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
      var op = {};
      item.map((val) => {op[val.name] = val.value;})
      return op;
    }, this);
    return parsedData;
  }

  addNewCase(event) {
    $("#saveButton").attr("disabled", true);
    if (context.state.casedata.phone.length < 14 && context.state.casedata.phone.length > 0) {
      event.preventDefault();
      context.setState({ errorMessage: 'Please enter at least 10 digits.' });
      return;
    }
    var jsonData = '';
    if (context.state.caseHistoryData.length === undefined || context.state.caseHistoryData.length === 0) {
      jsonData = { 'className': 'ProbationParoleCasesDetails', 'key': (localStorage.getItem('clientId') + "-1"), 'published': true };
    } else {
      jsonData = { 'className': 'ProbationParoleCasesDetails', 'key': (localStorage.getItem('clientId') + "-" + (context.state.caseHistoryData.length + 1)), 'published': true };
    }
    jsonData.elements = [];
    let jsonData_validation = [];
    var formData = $("#casesinfoform").serializeArray();
    $.each(formData, function () {
      if (this.value !== '') {
        jsonData_validation.push(this.name);
      }
      jsonData.elements.push({ name: this.name, value: this.value });
    });
    if (jsonData_validation.length === 0) {
      localStorage.setItem('error_msg', 'Empty Information is not saved');
      window.location.href = "";
    } else {
      jsonData.elements.push({ name: 'client_id', value: localStorage.getItem('clientId') });
      var json = JSON.stringify(jsonData);
      axios.post(global.save_url, json)
        .then((res)=> {
          var responce = res.data;
          localStorage.setItem('success_msg', "Probation/Parole/Cases Details saved successfully");
          showSuccessToast(localStorage.getItem("success_msg"));
          context.setState({ casedata: context.getModal("resetdata") });
          console.log('reseting', context.state.casedata);
          context.setState({showModal: false});
          context.caseList();
        })
        .catch(function (err) {
          console.log(err.message);
        }
      );
    }
    console.log(jsonData);
  }

  handlePhoneChange = (event) => {
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "phone") {
        if ( context.state.casedata.phone !== "" ) {
          this.setState({
            errorMessage: ''
          });
        }
        this.setState(
          prevState => ({...prevState, casedata: {...prevState.casedata, phone: test}})
          );
      }
    }
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !context.state.casedata.phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }


  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        probationParoleCaseOptions: selectOptionsGenerator(validationObj.options.probation_parole_cases, context.state.casedata.probation_parole_cases),
        leaveAMessageOptions: selectOptionsGenerator(validationObj.options.leave_msg, context.state.casedata.leave_msg),
        stateOptions: selectOptionsGenerator(validationObj.options.state, context.state.casedata.state),
      });
    }
  }

  datamapper(arr) {
    let obj = {
      agency: '',
      case_or_id_num: '',
      leave_msg: '',
      officer_name: '',
      phone: '',
      probation_parole_cases: ''
    }
    $.each(arr, function (index, value) {
      if (value.name === 'agency') {
        obj.agency = value.value;
      }
      if (value.name === 'case_or_id_num') {
        obj.case_or_id_num = value.value;
      }
      if (value.name === 'leave_msg') {
        obj.leave_msg = value.value;
      }
      if (value.name === 'officer_name') {
        obj.officer_name = value.value;
      }
      if (value.name === 'phone') {
        obj.phone = value.value;
      }
      if (value.name === 'probation_parole_cases') {
        obj.probation_parole_cases = value.value;
      }
    });
    this.setState({casedata: obj});
    this.createSelectOptions();
  }

  handleId = (e) => {
    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  handleUpdateState = async (event) => {
    if (event.target.name == "state") {
      let detaListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        state: event.target.value,
      }
      let county_res = await axios.post(global.restApiURL + "customRest/getCountyDropdwon", JSON.stringify(detaListBody))
      if (county_res.data.success) {
        let tempOptions = JSON.parse(county_res.data.data).results.slice(0).sort(function (a, b) {
          var x = a.countyName.toLowerCase();
          var y = b.countyName.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });
        this.setState({ countyOptions: tempOptions });
      }
    }
  }

  render() {

    localStorage.setItem('formname', 'Probation/Parole/Cases Details')
    $(document).ready(function () {
      $('#casesinfoform').validate({
        rules: {
          peer_support_specialist: {
            required: true,
          },
          first_name: {
            required: true,
          },
          interviewer: {
            required: true,
          },
          primary_email: {
            required: true,
          },
          primary_phone: {
            required: true,
          }
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.input-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <AppTopNav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={localStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
              email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
              peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
              next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')}
              initiated_by={encryptStorage.getItem('last_updated_by')} formname={localStorage.getItem('formname')}
              client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card card-default">
                    <div className="card-header">
                      <p className="lead float-left col-md-12 col-lg-9"><b className="text-green">Probation/Parole/Cases Details</b> <small>Are you on probation or parole, or do you have any active court cases?</small></p>
                      <button type="submit" className="btn btn-primary float-right" value="proceed"
                        // onClick={() => window.location.href = global.traversArray['cases']}
                        onClick={() => this.props.navigate(`${global.traversArray['cases']}`)}
                      ><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                      {can_add('ProbationParoleCasesDetails') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.onModalOpen} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Case</button> : ''}
                      <input type="hidden" id="clicked" value="" readOnly />
                      <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={localStorage.getItem('clientId')} />
                    </div>
                    <div className="card-body">
                      <DataTable
                        columns={context.state.caseHistoryColumns}
                        data={context.state.caseHistoryData}
                        customStyles={global.customTableStyles}
                        striped={true}
                        pagination={context.state.caseHistoryData ? true : false}
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                      />
                    </div>
                    <div className="card-footer float-right">
                      <div className="float-right">
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => this.props.navigate(`${global.traversArray['cases']}`)}>
                          <img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                        {can_add('ProbationParoleCasesDetails') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.onModalOpen} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Case</button> : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              showOverlay={true}
              show={context.state.showModal}
              onClose={this.onModalClose}
              disableOverlayClick={true}
              showCloseButton={false}
              size="sm"
              className="modal-md modal-dialog"
            >
              <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                <Modal.Title>
                  <div className="row" >
                    <div className="col-md-6">
                      <h4 className="modal-title float-left text-green">Add Cases</h4>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="modal-body">
                  <form action="javascript:void(0);" method="post" id="casesinfoform">

                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.probation_parole_cases}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select className="custom-select" id="probation_parole_cases" name="probation_parole_cases">
                          {context.state.probationParoleCaseOptions}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.agency}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">

                        <input type="text" placeholder={context.state.labelText.agency} className="form-control" id="agency" name="agency" required={context.state.required.agency} defaultValue={context.state.casedata.agency} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.case_or_id_num}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input type="text" className="form-control" placeholder={context.state.labelText.case_or_id_num} id="case_or_id_num" name="case_or_id_num" required={context.state.required.case_or_id_num} defaultValue={context.state.casedata.case_or_id_num} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.judge_name}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder={context.state.labelText.judge_name}
                          className="form-control"
                          id="judge_name"
                          name="judge_name"
                          required={context.state.required.judge_name}
                          defaultValue={context.state.casedata.judge_name}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.attorney}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="text"
                          placeholder={context.state.labelText.attorney}
                          className="form-control"
                          id="attorney"
                          name="attorney"
                          required={context.state.required.attorney}
                          defaultValue={context.state.casedata.attorney}
                        />
                      </div>
                    </div>
                    {/* New form group for court_date */}
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.officer_name}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input type="text" placeholder={context.state.labelText.officer_name} className="form-control" id="officer_name" name="officer_name" required={context.state.required.officer_name} defaultValue={context.state.casedata.officer_name} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.next_court_date}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <input
                          type="date"
                          // placeholder={context.state.labelText.next_court_date}
                          className="form-control"
                          id="next_court_date"
                          name="next_court_date"
                          required={context.state.required.next_court_date}
                          min={moment().format("MM-DD-YYYY")} // Set the minimum date to the current date
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.phone}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <InputMask id="phone" name="phone" className="form-control" mask="(999) 999-9999" placeholder={context.state.labelText.phone} value={context.state.casedata.phone} maskChar={null} beforeMaskedValueChange={this.beforeMaskedValueChange} onChange={this.handlePhoneChange} />
                        {context.state.errorMessage && (
                          <span style={{ color: 'red', fontSize: "75%" }}>{context.state.casedata.phone === "" ? "" : context.state.errorMessage}</span>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6">
                        <label>{context.state.labelText.leave_msg}</label>
                      </div>
                      <div className="form-group col-lg-6 col-md-6">
                        <select className="custom-select" id="leave_msg" name="leave_msg" disabled={false}>
                          {context.state.leaveAMessageOptions}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <span>{context.state.labelText.state}</span>
                      </div>
                      <div className="col-md-6 form-group">
                        <select className="custom-select" disabled={false} id="state" name="state" onChange={this.handleUpdateState}>
                          {context.state.stateOptions}
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 form-group">
                        <span>{context.state.labelText.county}</span>
                      </div>
                      <div className="col-md-6 form-group">
                        <select className="custom-select" disabled={false} id="county" name="county">
                          <option value=''>Select</option>
                          {this.state.countyOptions?.map((val, key) => {
                            return (<option value={val.countyName}>{val.countyName}</option>)
                          })}
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", backgroundColor: "none", borderTop: "1px solid #ccc" }}>
                <div className="row">
                  <div className="col-md-12">

                    <div className="justify-content-between">
                      <button className="btn btn-default float-left" id="closeButton" onClick={this.onModalClose} type="button">Close</button>
                      <button className="btn btn-success" id="saveButton" type="submit" onClick={this.addNewCase}  ><i className="fas fa-save"></i>&nbsp;Save</button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              showOverlay={true}
              show={this.state.showCaseDetailsModal}
              onClose={this.onCaseDetailModalClose}
              disableOverlayClick={true}
              showCloseButton={false}
              size="sm"
              className="modal-md modal-dialog"
            >
              <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                <Modal.Title>
                  <div className="row" >
                    <div className="col-md-6">
                      <h4 className="modal-title float-left text-green">Cases Detail</h4>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <label><b>Case Number or ID Number</b></label><br />
                      <label>{context.state.viewCaseData.case_or_id_num}</label>
                    </div>
                    <div class="col-md-6 form-group">
                      <label><b>Type</b></label><br />
                      <label>{context.state.viewCaseData.probation_parole_cases}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <label><b>Agency</b></label>
                      <br />
                      <label>{context.state.viewCaseData.agency}</label>
                    </div>
                    <div className="col-md-6 form-group">
                      <label><b>Judge Name</b></label><br />
                      <label>{context.state.viewCaseData.judge_name}</label>
                    </div>
                    <div className="col-md-6 form-group">
                      <label><b>Attorney Name</b></label><br />
                      <label>{context.state.viewCaseData.attorney}</label>
                    </div>
                    <div class="col-md-6 form-group">
                      <label><b>Officer Name</b></label><br />
                      <label>{context.state.viewCaseData.officer_name}</label>
                    </div>
                    <div className="col-md-6 form-group">
                      <label><b>Next Court Date</b></label><br />
                      <label>{
                                context.state.viewCaseData.next_court_date == "0000-00-00" || context.state.viewCaseData.next_court_date == null || context.state.viewCaseData.next_court_date == undefined || 
                                context.state.viewCaseData.next_court_date == "" || context.state.viewCaseData.next_court_date == "01/01/19970" ? "" : moment(context.state.viewCaseData.next_court_date).format("MM-DD-YYYY")
                              }
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <label><b>Phone</b></label><br />
                      <label>{context.state.viewCaseData.phone}</label>
                    </div>
                    <div class="col-md-6 form-group">
                      <label><b>Leave a message?</b></label><br />
                      <label>{context.state.viewCaseData.leave_msg}</label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <label><b>State</b></label><br />
                      <label>{context.state.viewCaseData.state}</label>
                    </div>
                    <div class="col-md-6 form-group">
                      <label><b>County</b></label><br />
                      <label>{context.state.viewCaseData.county}</label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", backgroundColor: "none", borderTop: "1px solid #ccc" }}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="justify-content-between" >
                      <button className="btn btn-default float-left" onClick={this.onCaseDetailModalClose} type="button">Close</button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}
export default HOC(Cases);
