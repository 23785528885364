import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import CoverTableHeaderContainer from "./CoverTableHeader";
import Filter from "./CoverFilter";
import { addDays } from "date-fns";

import CoverTableContainer from "./CoverTableContainer";
import { sampleData } from "./ClientsStackBar";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";

import { DateRangePicker } from 'rsuite';
import "../daterangecss/style.css";
import 'rsuite/dist/rsuite.min.css';

import moment from "moment";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const MainContainer = styled.section`
box-sizing:border-box;
  width: 98%;
  margin: 50px 16px 0 16px;


`;

// header design


const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;

`;

const Title = styled.span`
  flex: 0 0 15%;
  font-weight: 600;
  @media (max-width: 1024px) {
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width:100%;

  / justify-content: space-between; /
  flex: 0 0 70%;
  @media (max-width: 1370px) {
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  / flex: 0 0 15%; /
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 230px;
  
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  overflow:hidden;
`;

const width = "220px";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    fontSize: "0.875rem",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

// header design ends

function CoverSourcesTable({ title, filter, filterOptions }) {
  // const [date, setDate] = useState("");
  const [age, setage] = useState("2021");
  const classes = useStyles();
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [client, setClient] = useState([]);
  const [getSampleData1, setgetSampleData1] = useState([]);
  const [getRaceData, setRaceData] = useState([]);
  const [getEthnicity, setgetEthnicity] = useState([]);

  const [isGender, setGender] = useState(false);
  const [isRace, setRace] = useState(false);
  const [isEthnicity, setEthnicity] = useState(false);

  const [openDateModal, setOpenDateModal] = useState(false);
  const wrapperRef = React.useRef(null);
  // useOutsideAlerter(wrapperRef, openDateModal);



  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const OrgId = localStorage.getItem("organization_id");
  // const OrgId = 5184;

  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');    


  const [isYearDisable,setIsYearDisable]=useState(false);
  const [isDateRangeDisable,setIsDateRangeDisable]=useState(false);

  const styles = { width: 100, height: "100%", display: 'block', marginBottom: 10, height: 50, borderRadius: "1px" };


  const arr = [];

  const options = ["2022", "2021", "2020", "2019", "2018", "2017"];

  // const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  const optionData = sampleData[0].data;

  const yearhandleChange = (event) => {

    const {
      target: { value },
    } = event;
    setYear(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );

   


  };

  const monthhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clienthandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClient(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    fetchsearchClients(year, month, client, state);
  }, [year, month, client, state, startDate, endDate,isYearDisable]);



  const fetchsearchClients = (year, month, client, state) => {

   

    //  for year  //
    if (year == undefined || year == "") {
      year = "";
    } else {
      year = year.toString();
    }



    //  for Client  //
    if (client == undefined || client == "") {
      client = "all";
    } else {
      client = client.toString();
    }

    // for month //
    if (month == undefined || month == "") {
      month = 0;
    } else {
      if (month.length > 0) {
        month.forEach(function (item, i) {
          if (item == "January") {
            arr[i] = 1;
          }
          if (item == "Feburary") {
            arr[i] = 2;
          }
          if (item == "March") {
            arr[i] = 3;
          }
          if (item == "April") {
            arr[i] = 4;
          }
          if (item == "May") {
            arr[i] = 5;
          }
          if (item == "June") {
            arr[i] = 6;
          }
          if (item == "July") {
            arr[i] = 7;
          }
          if (item == "August") {
            arr[i] = 8;
          }
          if (item == "September") {
            arr[i] = 9;
          }
          if (item == "October") {
            arr[i] = 10;
          }
          if (item == "November") {
            arr[i] = 11;
          }
          if (item == "December") {
            arr[i] = 12;
          }
        });
      }

      //month = arr.toString();
      month = 0;
    }

    (Object.values(year).length<=0?(setIsDateRangeDisable(false)):(setIsDateRangeDisable(true)));



    if (client.indexOf("all") != -1) {


      if(year=="" && startDate=="" && endDate=="")
      {
        axios
        .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
      
        .then((response) => {
          setgetSampleData1(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
        // for clients_by_race
        axios
        .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        .then((response) => {

          setRaceData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });

      // for ethnicity

      axios
        // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
        .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)

        // .get(API_URL + `clients_by_ethnicity?year=${year}&startDate=${startDate}&endDate=${endDate}`)
        .then((response) => {
          setgetEthnicity(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        if(year != "")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
        
          .then((response) => {
            setgetSampleData1(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        // for race
        axios
          // .get(API_URL + `clients_by_race?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
  
          // .get(API_URL + `clients_by_race?year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .then((response) => {
  
            setRaceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
  
        // for ethnicity
  
        axios
          // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
  
        
          .then((response) => {
            setgetEthnicity(response.data.data);
            // setgetEthnicity([]);
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
         
          .then((response) => {
            setgetSampleData1(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        // for race
        axios
          // .get(API_URL + `clients_by_race?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
  
          // .get(API_URL + `clients_by_race?year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .then((response) => {
  
            setRaceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
  
        // for ethnicity
  
        axios
          // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
  
          .then((response) => {
            setgetEthnicity(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        }
      }
      // for gender
    
     

    }
// ======================================GENDER START========================================
    if (client.includes('Gender') == true) {
    

        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
            setgetSampleData1(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_gender?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
         
         
          }
        }

    }
// ======================================GENDER END========================================
    // ============================================RACE  START==================================
    if (client.includes('Race') == true) {
     

        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
            setRaceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_race?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
         
         
          }
        }


    }
    // ============================================RACE  END==================================
      // ============================================ETHNICITY  START==================================
    if (client.includes('Ethnicity') == true) {
    

        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
            setgetEthnicity(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `clients_by_ethnicity?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
         
         
          }
        }

      
    }
  // ============================================ETHNICITY  END==================================

  };

  const headers = [
    { label: "Source", key: "Source" },
    { label: "Name", key: "Name" },
    { label: "January", key: "Jan" },
    { label: "Feburary", key: "Feb" },
    { label: "March", key: "March" },
    { label: "April", key: "April" },
    { label: "May", key: "May" },
    { label: "June", key: "June" },
    { label: "July", key: "July" },
    { label: "August", key: "Aug" },
    { label: "September", key: "Sep" },
    { label: "October", key: "Oct" },
    { label: "November", key: "Nov" },
    { label: "December", key: "Dec" },
    { label: "Total", key: "Total" },
  ];

  let combinedArray = [];

  combinedArray.push(...getSampleData1, ...getRaceData,...getEthnicity);
  // combinedArray.push(...getSampleData1);

  // console.log("this is from coverSourceTable.js : " + getSampleData1);

  const downloadArr = [];


  for (let index = 0; index < combinedArray.length; index++) {
    if (combinedArray[index]["data"].length > 0) {
      for (let i = 0; i < combinedArray[index]["data"].length; i++) {
        console.log(typeof(combinedArray[index].data[i].data[4].val))
        downloadArr.push({
          Source: combinedArray[index]["source"],
          Name: combinedArray[index]["data"][i].name,
          Jan:  combinedArray[index].data[i].data[0].val == null  ? 0 : combinedArray[index].data[i].data[0].val,

          Feb:  combinedArray[index].data[i].data[1].val == null  ? 0 : combinedArray[index].data[i].data[1].val,

          March:  combinedArray[index].data[i].data[2].val == null  ? 0 : combinedArray[index].data[i].data[2].val,
         

          April:  combinedArray[index].data[i].data[3].val == null ? 0 : combinedArray[index].data[i].data[3].val,

          May:  combinedArray[index].data[i].data[4].val == null ? 0 : combinedArray[index].data[i].data[4].val,

          June:  combinedArray[index].data[i].data[5].val == null ? 0 : combinedArray[index].data[i].data[5].val,

          July:  combinedArray[index].data[i].data[6].val == null ? 0 : combinedArray[index].data[i].data[6].val,

          Aug:  combinedArray[index].data[i].data[7].val == null  ? 0 : combinedArray[index].data[i].data[7].val,

          Sep:  combinedArray[index].data[i].data[8].val == null ? 0 : combinedArray[index].data[i].data[8].val,

          Oct:  combinedArray[index].data[i].data[9].val == null ? 0 : combinedArray[index].data[i].data[9].val,

          Nov:  combinedArray[index].data[i].data[10].val == null  ? 0 : combinedArray[index].data[i].data[10].val,

          Dec:  combinedArray[index].data[i].data[11].val == null ? 0 : combinedArray[index].data[i].data[11].val,

          Total: combinedArray[index]["data"][i].total==null?0: combinedArray[index]["data"][i].total,

         
        });
      }
    }
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <Title>{title}</Title>
        <FilterContainer>
          {/****************************************** for Multi year ********************  */}
          <div
            style={{ height: "50px" }}
            selected={age}
            value={age}
            placeholder="Show Data for Year 2022"
            width="220px"
          >
            <FormControl
             disabled={isYearDisable}
              sx={{
                m: 1,
                width: 130,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
               
              >
                {year.length !== 0 ? "" : "Show Data for Year 2022"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={year}
                onChange={yearhandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {options?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={year.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* {/ year multiselect end /} */}


          {/****************************************** for Multi year end ********************  */}


          {/****************************************** for Client ********************  */}

          <div style={{ height: "50px" }}>
            <FormControl
              sx={{
                m: 1,
                width: 130,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {client.length !== 0 ? "" : "All Clients "}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={client}
                onChange={clienthandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {filterOptions?.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={client.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/****************************************** for Client ends  ********************  */}



          <DateRangePicker
          disabled={isDateRangeDisable}
            size="lg" placeholder="2022-01-01 ~ 2022-12-31" style={styles}
            onChange={(e) => {
              return ((e) ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")), setendDate(moment(e[1]).format("YYYY-MM-DD")),setIsYearDisable(true)) : (setstartDate(''), setendDate(''),setIsYearDisable(false)));           
             }}


          />

          {/* {/ </FilterDate>  /} */}

        </FilterContainer>
        <Button>
          {" "}
          <CSVLink
            filename="cover_client_report.csv"
            data={downloadArr}
            headers={headers}
            target="_blank"
            style={{
              color: "#ffffff",

              width: "120px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Download CSV{" "}
          </CSVLink>
        </Button>
      </HeaderContainer>

      {/* <CoverTableHeaderContainer
        title={title}
        filter={filter}
        options={filterOptions}
        
      />  */}
      {/* {isGender === true ? (
        <>
          {getRaceData.length > 0 ? <>
            <CoverTableContainer genderData={getSampleData1} />
          </> : ""}

        </>
      ) : (
        ""
      )} */}

      {/* {isRace === true ? (
        <>
          {getRaceData.length > 0 ? <>
            <CoverTableContainer genderData={getRaceData} />
          </> : ""}
        </>
      ) : (
        ""
      )} */}

      {/* {isEthnicity === true ? (
       <>
       {getRaceData.length>0?<>
        <CoverTableContainer genderData={getEthnicity} />
       </>:""}
      </>
      ) : (
        ""
      )} */}

      {client.includes('Gender') === true || client.length === 0 ? (
        <>
          <CoverTableContainer genderData={getSampleData1} />
        </>
      ) : (
        ""
      )}


      {client.includes('Race') === true || client.length === 0 ? (
        <>
            <CoverTableContainer genderData={getRaceData} />
        </>
      ) : (
        ""
      )}


      {client.includes('Ethnicity') === true || client.length === 0 ? (
        <>
          <CoverTableContainer genderData={getEthnicity} />
        </>
      ) : (
        ""
      )}
    </MainContainer>
  );
}

export default CoverSourcesTable;