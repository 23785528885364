import { GETSUBSTANCESTATE, SETSUBSTANCEUSE } from "../Constant/Constant";


const initialState=[{
    class_of_substance: '',
    specific_substance: '',
    problem_type: '',
    route_of_administration: '',
    other_route_of_administration: '',
    age_of_1st_use: '',
    date_last_used: '',
    how_long_used: '',
    frequency_of_use: '',
    age_of_regular_use: '',
    periods_of_abstinence: '',
    amount_of_regular_use: '',
    rank_order: "",
    specific_substance_options: [],
}];

const SubstanceReducer=(state=initialState,action)=>{
    switch(action.type){
        case GETSUBSTANCESTATE : return state;
            break;
        case SETSUBSTANCEUSE: return [...state,action.data];
            break;
        default : return []
    }
}

export default SubstanceReducer