
import React from 'react';
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { updateGlobalState } from '../global.js';



const useUpdateGlobalStateOnNavigation = () => {
    const location = useLocation();

    React.useEffect(() => {
        updateGlobalState();
    }, [location]);
};


const ProtectedRoutes = () => {
    useUpdateGlobalStateOnNavigation()
    const navigate = useNavigate();
    const token = localStorage.getItem('__TOKEN__');
    const apiKey = localStorage.getItem('apikey');
    const isAuthenticated = token; 
    const client_id_check = localStorage.getItem('clientId') !== '' && localStorage.getItem('clientId') === null && localStorage.getItem('clientId') !== undefined && isAuthenticated;

  
  React.useEffect(() => {
        if (client_id_check) {
            setTimeout(() => {
                navigate("/myclients");
            }, 0);
        }
    }, []);
    return (
        isAuthenticated ? <Outlet /> : <Navigate to="/" />
    );
};

export default ProtectedRoutes;

