import $ from 'jquery';
import React, { Component } from 'react';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import { formObjDataMapper } from '../ReusableComponents/formObjDataMapper';
import { validationParser } from '../ReusableComponents/validationParser2';

//Tooltip imports
import moment from 'moment';
import { LabelField } from '../ReusableComponents/LabelnInput';
import { showSuccessToast } from './toastService';

import axios from 'axios';
var validationObj = {};

var context;

class PHQ9 extends Component {
    constructor() {
        super();

        context = this;

        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.overallResponseCalculator = this.overallResponseCalculator.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.state = {

            phq9Data: this.getModal(),
            mandatoryInfo: this.getModal(),
            labelText: this.getModal(),
            tooltips: this.getModal(),
            tooltipsVisibility: this.getModal(),
            fieldName: this.getModal(),
            radioOptions: this.getModal(),
            phq9HistoryData: 0,
            checked_off_any_problems_options: [],
            save_called: false,
            overAllResponseCount: {
                counter1: 0,
                counter2: 0,
                counter3: 0,
                counter4: 0,
                totalScore: 0,
                result: '',
            }
        };

    }

    componentWillMount() {
        //for validation
        axios.get(global.restApiURL + 'webservice/rest/class/id/12?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success && res.status == 200) {
                    validationObj = validationParser(response.data.layoutDefinitions.children[0].children);
                    console.log("validation", validationObj);

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,

                        // radioOptions : validationObj.options
                    });

                    this.createSelectOptions();

                    //   console.log("state : " , this.state.radioOptions.is_litl_interest[0].value);

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });

    }

    componentDidMount() {
        this.props.onRef(this)
        var prevExist = false;
        sessionStorage.setItem('ObectID', '');

        if (sessionStorage.getItem('clientId') !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&objectClass=PHQ9&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
                .then(res => {

                    const response = res.data; // get the data array instead of object

                    if (response.success) {
                        console.log("object fetcher : ", response);

                        if (response.data.length !== 0) {
                            prevExist = true;
                            console.log("prevExist : ", prevExist);

                            this.setState({
                                phq9HistoryData: response.total
                            })


                        } else {
                            prevExist = false;
                        }
                    }
                    else if ("error_code" in response) {
                        alert(response.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(response.msg);
                    }

                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })

            if (this.props.obj_id && this.props.obj_id > 0) {
                axios.get(global.restApiURL + 'webservice/rest/object/id/' + this.props.obj_id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))
                    .then(res => {

                        sessionStorage.setItem('ObectID', this.props.obj_id);
                        sessionStorage.setItem('Obectkey', res.data.data.key);
                        const response = res.data.data.elements;
                        console.log("Obj data : ", response);

                        this.setState({
                            phq9Data: formObjDataMapper(response, this.getModal())
                        })


                        setTimeout(function () { //Start the timer
                            this.overallResponseCalculator();
                        }.bind(this), 500);
                        setTimeout(() => {
                            this.createSelectOptions();
                        }, 500);
                    })
                    .catch(err => { // log request error and prevent access to undefined state
                        this.setState({ loading: false, error: true });
                        console.error(err);
                    })
            }
        }
    }
    //Post data to save


    save = (event) => {
        /*let saveType =  event.target.value ;
        console.log( "save : " , saveType );*/
        console.log('save 2 called')

        let saveType = 'save';

        var jsonData = '';
        if (sessionStorage.getItem('ObectID') && sessionStorage.getItem('Obectkey')) {
            jsonData = { 'className': 'PHQ9', 'key': sessionStorage.getItem('Obectkey'), 'id': sessionStorage.getItem('ObectID'), 'published': true };
        } else {
            jsonData = { 'className': 'PHQ9', 'key': sessionStorage.getItem('clientId') + '-' + this.state.phq9HistoryData, 'published': true };
        }

        jsonData.elements = [];
        var formData = $("#phq9form").serializeArray();

        console.log("formdata : ", formData);

        let keys = Object.keys(context.getModal());

        keys.map((key) => {
            jsonData.elements.push({ name: context.state.fieldName[key], value: context.state.phq9Data[key] });
        })
        jsonData.elements.push({ name: 'client_id', value: sessionStorage.getItem('clientId') });
        jsonData.elements.push({ name: 'interviewer', value: sessionStorage.getItem('loggedinUserID') });
        jsonData.elements.push({ name: 'total_0_score', value: context.state.overAllResponseCount.counter1 });
        jsonData.elements.push({ name: 'total_1_score', value: context.state.overAllResponseCount.counter2 });
        jsonData.elements.push({ name: 'total_2_score', value: context.state.overAllResponseCount.counter3 });
        jsonData.elements.push({ name: 'total_3_score', value: context.state.overAllResponseCount.counter4 });
        jsonData.elements.push({ name: 'score_status', value: context.state.overAllResponseCount.result });
        jsonData.elements.push({ name: 'score', value: context.state.overAllResponseCount.totalScore });
        jsonData.elements.push({ name: 'updated_time', value: moment().format('MM/DD/YYYY HH:mm:ss') });
        var json = JSON.stringify(jsonData);

        console.log("Data to send : ", json);

        axios.post(global.save_url, json)
            .then(function (res) {
                var response = res.data;
                console.log("save response", response.success);
                if (response.success && res.status == 200) {
                    sessionStorage.setItem('success_msg', "PHQ-9 saved successfully.");
                    if ($('#clicked').val() === 'proceed') {
                        window.location.href = global.domain + global.traversArray['phq9'];
                    }
                    else {
                        showSuccessToast(sessionStorage.getItem("success_msg"))
                    }
                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }
                sessionStorage.removeItem('Obectkey');
                sessionStorage.removeItem('ObectID');
                console.log("Msg : ", sessionStorage.getItem('success_msg'));
            })
            .catch(function (err) {
                console.log(err.message);
            });

    }


    handleRadioChange(event) {

        // console.log("event name : " , event.target.name , event.target.value) ;

        let tempOb = this.state.phq9Data;

        // console.log("before : " , this.state.phq9Data);

        tempOb[event.target.name] = event.target.value;

        this.setState((prevState) => ({
            ...prevState,
            phq9Data: tempOb
        })
        );

        // console.log("after  : " , this.state.phq9Data);

        this.overallResponseCalculator();

        // console.log("ql data : " , this.state.overAllResponseCount)

    }

    getModal() {
        var phq9DataModal = {
            is_litl_interest: '',
            is_feeling_down: '',
            trouble_falling_asleep: '',
            feeling_tired: '',
            poor_appetite_ovreating: '',
            trouble_concentrate_things: '',
            feeling_bad_about_self: '',
            move_speak_slow: '',
            thought_better_dead: '',
            not_difficult: '',
            somewhat_difficult: '',
            very_difficult: '',
            extremely_difficult: '',
            checked_off_any_problems: '',
        };

        return phq9DataModal;
    }

    handleId = (e) => {

        console.log("handle id")

        $('#clicked').val(e.target.value);
        $('#clicked').val(e.currentTarget.value);
    }

    condition_result(score) {
        console.log(typeof (score));
        switch (true) {
            case (score < 5):
                return "Minimal depression";
                break;

            case (score < 10):
                return "Mild depression";
                break;

            case (score < 15):
                return "Moderate depression";
                break;

            case (score < 20):
                return "Moderately severe depression";
                break;

            case (score > 20):
                return "Severe depression";
                break;

            default: return "";
        }
    }
    overallResponseCalculator() {
        let keyArray = Object.keys(this.getModal());
        let counter1 = 0;
        let counter2 = 0;
        let counter3 = 0;
        let counter4 = 0;
        let totalScore = 0;


        keyArray.map((key) => {

            // console.log("counter : " , key , Number(this.state.phq9Data[key]))

            if (this.state.phq9Data[key] !== "") {
                if (this.state.phq9Data[key] === "0")
                    counter1++;
                if (this.state.phq9Data[key] === "1")
                    counter2++;
                if (this.state.phq9Data[key] === "2")
                    counter3++;
                if (this.state.phq9Data[key] === "3")
                    counter4++;
            }

            totalScore = (counter1 * 0) + (counter2 * 1) + (counter3 * 2) + (counter4 * 3);

        })

        this.setState(prevState => ({
            ...prevState,
            overAllResponseCount: {
                counter1: counter1,
                counter2: counter2,
                counter3: counter3,
                counter4: counter4,
                totalScore: totalScore,
                result: context.condition_result(totalScore)
            }
        }))

    }

    handleCheckboxChange(event) {
        let tempOb = this.state.phq9Data;

        console.log("chk box : ", event.target.value);

        if (event.target.value === "") {
            tempOb[event.target.name] = true;
            this.setState(prevState => ({
                ...prevState,
                phq9Data: tempOb
            }));
        }
        else {
            tempOb[event.target.name] = !tempOb[event.target.name];
            this.setState(prevState => ({
                ...prevState,
                phq9Data: tempOb
            }));
        }

        console.log("chk box : ", this.state.phq9Data);
    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({
                checked_off_any_problems_options: selectOptionsGenerator(validationObj.options.checked_off_any_problems, this.state.phq9Data.checked_off_any_problems)
            })
        }
    }



    render() {
        sessionStorage.setItem('formname', 'PHQ-9', this.state.save_called);

        return (

            <form action="javascript:void(0);" method="post" id="phq9form"  >


                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive p-0 form-group">
                            <table className="table table-bordered table-striped radioTableCSS table-center">
                                <thead>
                                    <tr>
                                        <th style={{ width: '45%' }}></th>
                                        <th style={{ width: '12%' }}>Not at all (0)</th>
                                        <th style={{ width: '14%' }}>Several days (1)</th>
                                        <th style={{ width: '14%' }}>More than<br />half the days (2)</th>
                                        <th style={{ width: '14%' }}>Nearly<br></br> every day<br /> (3)</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.is_litl_interest} mandatory={this.state.mandatoryInfo.is_litl_interest} name="is_litl_interest" tooltip={this.state.tooltips.is_litl_interest} id="is_litl_interest" />
                                        </td>
                                        {/* {this.state.radioOptions.is_litl_interest} */}
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_litl_interest} value="0" checked={this.state.phq9Data.is_litl_interest === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.is_litl_interest} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_litl_interest} value="1" checked={this.state.phq9Data.is_litl_interest === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_litl_interest} value="2" checked={this.state.phq9Data.is_litl_interest === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_litl_interest} value="3" checked={this.state.phq9Data.is_litl_interest === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.is_feeling_down} mandatory={this.state.mandatoryInfo.is_feeling_down} name="is_feeling_down" tooltip={this.state.tooltips.is_feeling_down} id="is_feeling_down" /></td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_feeling_down} value="0" checked={this.state.phq9Data.is_feeling_down === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.is_feeling_down} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_feeling_down} value="1" checked={this.state.phq9Data.is_feeling_down === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_feeling_down} value="2" checked={this.state.phq9Data.is_feeling_down === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.is_feeling_down} value="3" checked={this.state.phq9Data.is_feeling_down === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>

                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.trouble_falling_asleep} mandatory={this.state.mandatoryInfo.trouble_falling_asleep} name="trouble_falling_asleep" tooltip={this.state.tooltips.trouble_falling_asleep} id="trouble_falling_asleep" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_falling_asleep} value="0" checked={this.state.phq9Data.trouble_falling_asleep === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.trouble_falling_asleep} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_falling_asleep} value="1" checked={this.state.phq9Data.trouble_falling_asleep === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_falling_asleep} value="2" checked={this.state.phq9Data.trouble_falling_asleep === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_falling_asleep} value="3" checked={this.state.phq9Data.trouble_falling_asleep === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.feeling_tired} mandatory={this.state.mandatoryInfo.feeling_tired} name="feeling_tired" tooltip={this.state.tooltips.feeling_tired} id="feeling_tired" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_tired} value="0" checked={this.state.phq9Data.feeling_tired === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.feeling_tired} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_tired} value="1" checked={this.state.phq9Data.feeling_tired === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_tired} value="2" checked={this.state.phq9Data.feeling_tired === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_tired} value="3" checked={this.state.phq9Data.feeling_tired === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.poor_appetite_ovreating} mandatory={this.state.mandatoryInfo.poor_appetite_ovreating} name="poor_appetite_ovreating" tooltip={this.state.tooltips.poor_appetite_ovreating} id="poor_appetite_ovreating" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.poor_appetite_ovreating} value="0" checked={this.state.phq9Data.poor_appetite_ovreating === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.poor_appetite_ovreating} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.poor_appetite_ovreating} value="1" checked={this.state.phq9Data.poor_appetite_ovreating === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.poor_appetite_ovreating} value="2" checked={this.state.phq9Data.poor_appetite_ovreating === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.poor_appetite_ovreating} value="3" checked={this.state.phq9Data.poor_appetite_ovreating === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.trouble_concentrate_things} mandatory={this.state.mandatoryInfo.trouble_concentrate_things} name="trouble_concentrate_things" tooltip={this.state.tooltips.trouble_concentrate_things} id="trouble_concentrate_things" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_concentrate_things} value="0" checked={this.state.phq9Data.trouble_concentrate_things === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.trouble_concentrate_things} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_concentrate_things} value="1" checked={this.state.phq9Data.trouble_concentrate_things === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_concentrate_things} value="2" checked={this.state.phq9Data.trouble_concentrate_things === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.trouble_concentrate_things} value="3" checked={this.state.phq9Data.trouble_concentrate_things === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.feeling_bad_about_self} mandatory={this.state.mandatoryInfo.feeling_bad_about_self} name="feeling_bad_about_self" tooltip={this.state.tooltips.feeling_bad_about_self} id="feeling_bad_about_self" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_bad_about_self} value="0" checked={this.state.phq9Data.feeling_bad_about_self === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.feeling_bad_about_self} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_bad_about_self} value="1" checked={this.state.phq9Data.feeling_bad_about_self === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_bad_about_self} value="2" checked={this.state.phq9Data.feeling_bad_about_self === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.feeling_bad_about_self} value="3" checked={this.state.phq9Data.feeling_bad_about_self === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>

                                            <LabelField title={this.state.labelText.move_speak_slow} mandatory={this.state.mandatoryInfo.move_speak_slow} name="move_speak_slow" tooltip={this.state.tooltips.move_speak_slow} id="move_speak_slow" /></td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.move_speak_slow} value="0" checked={this.state.phq9Data.move_speak_slow === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.move_speak_slow} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.move_speak_slow} value="1" checked={this.state.phq9Data.move_speak_slow === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.move_speak_slow} value="2" checked={this.state.phq9Data.move_speak_slow === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.move_speak_slow} value="3" checked={this.state.phq9Data.move_speak_slow === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td>
                                            <LabelField title={this.state.labelText.thought_better_dead} mandatory={this.state.mandatoryInfo.thought_better_dead} name="thought_better_dead" tooltip={this.state.tooltips.thought_better_dead} id="thought_better_dead" />
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.thought_better_dead} value="0" checked={this.state.phq9Data.thought_better_dead === "0"} onChange={this.handleRadioChange} required={this.state.mandatoryInfo.thought_better_dead} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.thought_better_dead} value="1" checked={this.state.phq9Data.thought_better_dead === "1"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.thought_better_dead} value="2" checked={this.state.phq9Data.thought_better_dead === "2"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name={this.state.fieldName.thought_better_dead} value="3" checked={this.state.phq9Data.thought_better_dead === "3"} onChange={this.handleRadioChange} />
                                            </div>
                                        </td>

                                    </tr>

                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th>{this.state.overAllResponseCount.counter1 * 0}</th>
                                        <th>{this.state.overAllResponseCount.counter2 * 1}</th>
                                        <th>{this.state.overAllResponseCount.counter3 * 2}</th>
                                        <th>{this.state.overAllResponseCount.counter4 * 3}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className=" col-lg-12 col-md-12 form-group">
                        <div className="bg-green" style={{ textAlign: 'center', borderRight: '1px solid #fff', paddingRight: '30px', paddingBottom: '10px' }}>
                            <p className="lead" style={{ marginBottom: '0px', paddingTop: "10px" }}><b>Final Score</b> = <span style={{ fontSize: '1.6rem', fontWeight: '400' }}>{this.state.overAllResponseCount.totalScore}</span><b>{this.state.overAllResponseCount.result !== '' ? ` - ` + this.state.overAllResponseCount.result : ''}</b>  </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">

                        <div className="form-group">
                            <LabelField title={this.state.labelText.checked_off_any_problems} mandatory={this.state.mandatoryInfo.checked_off_any_problems} name="checked_off_any_problems" tooltip={this.state.tooltips.checked_off_any_problems} id="checked_off_any_problems" />
                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="form-group">
                            <select id="checked_off_any_problems" name="checked_off_any_problems" className="form-control select" aria-hidden="true" onChange={this.handleRadioChange} required={this.state.mandatoryInfo.checked_off_any_problems}>
                                {this.state.checked_off_any_problems_options}
                            </select>

                        </div>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-12 col-md-12" style={{ marginTop: "30px", border: "1px dashed #cec5c5", padding: "20px 20px 0px 20px", backgroundColor: "#f2f2f2" }}>
                        <p className="text-gray"><strong>PHQ9 Copyright  © Pfizer Inc.</strong> All rights reserved. Reproduced with permission. PRIME-MD <span class="rsize">®</span> is a trademark of Pfizer Inc.<br />A2662B 10-04-2005 </p>

                    </div>
                </div>
            </form>
        );
    }

}

export default PHQ9;